import React, { useState, useEffect, useReducer } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import CONFIG from '../../config/config.js';
import { /*PDFDownloadLink,*/ StyleSheet } from '@react-pdf/renderer';
import { Button, Grid, Box, /*Typography,*/ Paper } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';

import * as moment from 'moment';

// import Pdf from "../ConvertToWorkOrder/Pdf";
import AcceptProposalIcon from '../../assets/icons/primary/salesorder-complete.svg';
// import PdfDownloadIcon from "../../assets/icons/secondary/pdf-download.svg";

import Header from './Header';
import SubHeader from './SubHeader';
import AcceptedProposal from './AcceptedProposal';
import DetailsTable from './DetailsTable';
import Notes from './Notes';
import Charges from './Charges';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  width: '210mm',
}));

// let filename;

export const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
  },
});

const chargesReducer = (state, { type, payload }) => {
  switch (type) {
    case 'SET_ALL':
      return {
        ...state,
        ...payload,
      };
    case 'SET_RETAIL_PRICE':
      return {
        ...state,
        retailPrice: payload,
      };
    case 'SET_ACCESSORIES':
      return {
        ...state,
        accessories: payload,
      };
    case 'SET_INSTALLATION':
      return {
        ...state,
        installation: payload,
      };
    case 'SET_ADDITIONAL_CHARGES':
      return {
        ...state,
        additionalCharges: payload,
      };
    case 'SET_DISCOUNT':
      return {
        ...state,
        discount: payload,
      };
    case 'SET_SERVICES':
      return {
        ...state,
        services: payload,
      };
    case 'SET_TAX':
      return {
        ...state,
        tax: payload,
      };
    case 'SET_TOTAL':
      return {
        ...state,
        total: payload,
      };

    default:
      return state;
  }
};

const WCPPage = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const styles = StyleSheet.create({
    section_row: {
      flexDirection: 'row',
      paddingTop: 10,
      paddingLeft: 25,
      paddingBottom: 25,
      paddingRight: 0,
      backgroundColor: '#00B6BC',
      width: '100%',
      height: '10%',
    },
    section_rowd: {
      flexDirection: 'row',
      paddingTop: 10,
      paddingLeft: 25,
      paddingBottom: 15,
      paddingRight: 0,
      backgroundColor: '#00B6BC',
      width: '100%',
      height: '8%',
    },
    box: {
      backgroundColor: '#EDECEB',
      marginTop: -5,
      width: '100%',
    },
    heading: {
      paddingLeft: 10,
      paddingTop: 20,
      paddingBottom: 20,
      fontSize: '22px',
      fontWeight: 'bold',
      color: '#007383',
      fontFamily: 'Roboto',
      lineHeight: '1em',
    },
    p: {
      width: '100%',
      marginTop: -5,
      paddingLeft: 10,
      paddingRight: 20,
      fontFamily: 'Roboto',
      fontSize: '14px',
      lineHeight: '1.8',
      color: '#2D3030',
      textAlign: 'left',
    },
    section_row_1st: {
      flexDirection: 'row',
      paddingTop: 5,
    },
    section_row_Display_Id: {
      flexDirection: 'row',
      paddingTop: 5,
      paddingBottom: 10,
    },
    section_col_key: {
      paddingTop: 10,
      marginLeft: 30,
      flexDirection: 'col',
    },
    section_col_val: {
      paddingTop: 10,
      flexDirection: 'col',
    },
    headingText: {
      fontSize: 11,
      fontWeight: 'bolder',
      color: '#797979',
      paddingTop: 1,
    },
    headingTextCopy: {
      fontSize: 11,
      fontWeight: 'bolder',
      color: '#A8A8A8',
      paddingTop: 1,
      textTransform: 'capitalize',
    },
    contentCenter: {
      fontSize: 11,
      fontWeight: 'bolder',
      color: '#A8A8A8',
      paddingTop: 8,
      textAlign: 'center',
    },
    rowText: {
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      fontSize: '13px',
      color: '#A5A5A5',
    },
    rowTextCopy: {
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      fontSize: '13px',
      color: '#333',
      textTransform: 'capitalize',
    },
    horizontalborder: {
      width: '100%',
      height: '5px',
      display: 'flex',
      backgroundColor: '#686A65',
    },
    proposal_section: {
      padding: 10,
    },
    horizontalblackborder: {
      width: '100%',
      height: '20px',
      flexDirection: 'row',
      backgroundColor: '#2D3030',
    },
    section_col: {
      flexDirection: 'col',
    },
    rowTextforproposal: {
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      fontSize: '16px',
      color: '#747474',
      textAlign: 'right',
    },
    rowTotalforproposal: {
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      fontSize: '15px',
      color: '#747474',
    },
    rowTotalforproposalfordesktop: {
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      fontSize: '12px',
      color: '#747474',
    },
    subAccessoriesAndServices: {
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      fontSize: '14px',
      color: '#747474',
    },
    rowTotalforproposalfordesktop: {
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      fontSize: '12px',
      color: '#747474',
    },
    image: {
      width: 80,
      height: 75,
      paddingTop: 4,
    },
    boldheadingText: {
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      fontSize: '16px',
      color: '#5F5F5F',
    },
    windowimage: {
      textAlign: 'right',
      paddingTop: 8,
      marginLeft: 100,
      width: 200,
      height: 80,
      float: 'right',
    },
    headerverticallinefortable: {
      borderLeft: '1px solid #E5E5E5',
      height: 'auto',
      marginTop: 6,
      marginLeft: 10,
      marginRight: 10,
    },
    text: {
      paddingTop: 10,
      fontFamily: 'Roboto',
      fontWeight: '400',
      fontSize: '22px',
      color: '#FFFFFF',
      textAlign: 'left',
    },
    subtext: {
      fontFamily: 'Roboto',
      fontSize: '14px',
      color: '#FFFFFF',
      textAlign: 'justify',
      textTransform: 'uppercase',
    },
    headerverticalline: {
      borderLeft: '1.1px solid #9AE2E3',
      height: 'auto',
      marginTop: 8,
      marginLeft: 10,
      marginRight: 10,
    },
    main_view: {
      marginLeft: '100px',
      marginRight: '100px',
    },
    first_view: {
      display: 'flex',
      flexDirection: 'column',
    },
    first_para: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '45px',
      flexDirection: 'column',
    },
    first_text: {
      fontSize: '30px',
    },
    first_textmob: {
      fontSize: '20px',
      fontWeight: 600,
    },
    para_normalmob: {
      fontSize: '16px',
      marginTop: '5px',
    },
    para_boldmob: {
      fontWeight: 600,
      fontSize: '16px',
      marginTop: '5px',
    },
    para_normal: {
      fontSize: '18px',
      marginTop: '5px',
    },
    para_bold: {
      fontWeight: 600,
      fontSize: '18px',
      marginTop: '5px',
    },
    img: {
      width: '35px',
      height: '30px',
      position: 'absolute',
    },
    img2: {
      width: '55px',
      height: '40px',
      position: 'absolute',
    },
    img2mob: {
      width: '45px',
      height: '30px',
      position: 'absolute',
    },
    second_para: {
      marginTop: '50px',
      width: '93%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '45px',
      flexDirection: 'column',
      paddingLeft: '30px',
      paddingRight: '30px',
    },
    second_paramob: {
      marginTop: '50px',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '45px',
      flexDirection: 'column',
    },
    height: {
      marginTop: '20px',
    },
  });

  const [charges, chargesDispatch] = useReducer(chargesReducer, {
    retailPrice: '',
    accessories: '',
    installation: '',
    additionalCharges: '',
    discount: {
      percentage: '',
      total: '',
    },
    services: '',
    tax: '',
    total: '',
  });

  const [customerDetails, setCustomerDetails] = useState({});
  const [proposalDetails, setProposalDetails] = useState([]);
  const [proposalWindows, setProposalWindows] = useState([]);
  const [notes, setNotes] = useState({ customer: [], proposal: [], windowSet: [], windows: [], proposal_details: [] });
  const [entryDate, setEntryDate] = useState();
  const [proposalForCustomer, setProposalForCustomer] = useState({});
  const [proposalAcceptedBy, setProposalAcceptedBy] = useState('');
  const [acceptanceCompleted, setAcceptanceCompleted] = useState(false);

  useEffect(() => {
    getDetailsByProposalId();
    setProposalAcceptedBy(location.state ? 'ACCEPTED_BY_SALES_REP' : 'ACCEPTED_BY_CUSTOMER');
  }, []);

  const getDetailsByProposalId = async () => {
    let id = params.id;

    const response = await fetch(`${CONFIG.API_URL}/cm/GetCustomerDetailByProposalID/${id}`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();
    if (!response.ok || data.error) {
      console.error(data.error_message);
      return;
    }
    let customerDetails = [];
    if (data.customer_details.is_billing) {
      customerDetails.push(data.customer_details);
    }

    setEntryDate(moment(data.proposals.entry_date).format('L'));
    setCustomerDetails(data.customer_details);
    setProposalDetails(data.proposals);
    setProposalWindows(data.proposal_details.filter((details) => details.covering_status === 'applied'));

    const referenceIds = {
      customer: data.customer_details.customer_id,
      proposal: data.proposals.proposal_id,
      windowSet: data.proposals.window_set_id,
      windows: data.proposal_details.map((detail) => detail.window_id),
      proposal_details: data.proposal_details.map((detail) => detail.proposal_detail_id),
    };

    const accessoriesDetails = data.proposal_level_accessory_service_charges.filter(({ charge_type }) => charge_type === 'X');
    const serviceDetails = data.proposal_level_accessory_service_charges.filter(({ charge_type }) => charge_type === 'S');
    const addtionalChargeValue = data.proposal_level_accessory_service_charges.filter(({ charge_type }) => charge_type === 'AD');

    const payload = {
      show_discount: data.show_disc,
      retailPrice: +data.proposals.retail_total,
      accessories: accessoriesDetails,
      installation: +data.proposals.install_total + +data.proposals.additional_install_service_charge,
      additionalCharges: addtionalChargeValue,
      discount: {
        percentage: +data.proposals.discount,
        total: +data.proposals.discount_dollar,
      },
      services: serviceDetails,
      tax: +data.proposals.sales_tax,
      total: +data.proposals.final_total,
    };
    chargesDispatch({
      type: 'SET_ALL',
      payload,
    });

    setProposalForCustomer({
      customerDetails: customerDetails.length ? customerDetails[0] : data.customer_details[0],
      proposalDetails: data.proposals,
      proposalWindows: data.proposal_details.filter((details) => details.covering_status === 'applied'),
      charges: charges,
    });

    await getAllNotes(referenceIds);
  };

  const getAllNotes = async (referenceIds) => {
    const { customer, proposal, windowSet, windows, proposal_details } = referenceIds;
    const allReferenceIds = { customer, proposal, windowSet, windows, proposal_details };

    const notes = await Promise.all(
      Object.entries(allReferenceIds).map(async ([key, value]) => {
        const values = Array.isArray(value) ? value : [value];

        const notesArray = await Promise.all(
          values.map(async (referenceId) => {
            const options = {
              method: 'GET',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
            };
            const response = await fetch(`${CONFIG.API_URL}/cm/Notes/${referenceId}`, options);
            const { Notes } = await response.json();

            return Notes.filter((note) => note.visible);
          })
        );

        return [key, notesArray.flat()];
      })
    );

    const notesMap = notes.reduce(
      (acc, [key, value]) => {
        acc[key] = [...acc[key], ...value];
        return acc;
      },
      { customer: [], proposal: [], windowSet: [], windows: [], proposal_details: [] }
    );

    setNotes(notesMap);
  };

  const updateStatus = async () => {
    let id = params.id;
    const response = await fetch(`${CONFIG.API_URL}/pm/UpdateProposalAcceptanceStatus`, {
      method: 'PUT',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        proposal_id: id,
        status: proposalAcceptedBy,
      }),
    });
    const data = await response.json();
    if (!data.error) {
      if (proposalAcceptedBy === 'ACCEPTED_BY_SALES_REP' && data.message === 'Proposal acceptance status is updated successfully!') {
        await setTimeout(1_000);
        navigate(-1);
      } else {
        setAcceptanceCompleted(true);
      }
    }
  };

  const getCustomerName = () => (customerDetails.first_name === 'NA' ? customerDetails.last_name : `${customerDetails.first_name} ${customerDetails.wife === 'NA' ? '' : `& ${customerDetails.wife}`} ${customerDetails.last_name}`);

  return proposalForCustomer ? (
    <div className='preview_proposal_main_div'>
      <Grid container direction='column'>
        <Grid sm={2} />
        <Grid sm={8}>
          <Item className='previewProposalPdf' variant='outlined'>
            <Header style={styles}>{getCustomerName()}</Header>
            {!acceptanceCompleted ? (
              <Box style={styles.customer_section}>
                <SubHeader date={entryDate} customer={customerDetails} proposal={proposalDetails} customStyles={styles} />
                <Box style={styles.horizontalborder} />
                <Box style={styles.horizontalblackborder} />
                <DetailsTable windows={proposalWindows} customStyles={styles} />
                <Notes notes={notes} />
                {charges ? <Charges charges={charges} customStyles={styles} /> : null}
                <hr className='greyout' />
                <Grid container direction='row' spacing={0} style={styles.section_row_1st}>
                  <Grid md={6} />
                  <Grid md={5}>
                    <Button className='button_span_notes' onClick={updateStatus}>
                      <div className='globalIconMainBox button_top_space'>
                        <img src={AcceptProposalIcon} className='mail_primary_icons_dimensions' alt='Accept Proposal Icon' />
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: '12px',
                            fontFamily: 'Roboto !important',
                          }}
                        >
                          <span className='mail_small_size'>Click here to</span>
                          <span className='mail_Big_size'>ACCEPT PROPOSAL</span>
                        </div>
                      </div>
                    </Button>
                  </Grid>
                  <Grid md={1} />
                </Grid>
              </Box>
            ) : (
              <AcceptedProposal sales_rep={proposalDetails.sales_rep} />
            )}
          </Item>
        </Grid>
      </Grid>
    </div>
  ) : (
    <div className=''>
      <Grid container direction='column'>
        <Box display='flex' justifyContent='center' alignItems='center' minHeight='75vh'>
          Loading Proposal...
        </Box>
      </Grid>
    </div>
  );
};

export default WCPPage;
