import { createContext, useReducer, useState } from 'react';
import { Category } from '@mui/icons-material';

export const ProductContext = createContext(null);
export const ProductDispatchContext = createContext(null);

export function WindowProvider({ children }) {
  const [state, dispatch] = useReducer(productReducer, initialProduct);
  const [options, setOptions] = useState({});



  return (
    <ProductContext.Provider value={{ product: state, options }}>
      <ProductDispatchContext.Provider value={dispatch}>{children}</ProductDispatchContext.Provider>
    </ProductContext.Provider>
  );
}

export default WindowProvider;

const productReducer = (state, { type, payload }) => {
  switch (type) {
    case 'RESET':
      return initialProduct;
    case 'SET_ALL':
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

const initialProduct = {
  category: null,
  brand: null,
  label: null,
  product: null,
  type: null,
  material: null,
  opacity: null,
  liftSystem: null,
  direction: null,
};
