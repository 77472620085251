/** @format */

import React, { useState, useEffect, useContext } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Snackbar from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material';
import CONFIG from '../../config/config.js';
import Paper from '@mui/material/Paper';
import CancelIcon from '../../assets/icons/secondary/2nd-cancel.svg';
import ApplyCoveringIcon from '../../assets/icons/primary/window.svg';
import ApplyCoveringHovIcon from '../../assets/icons/primary/WithHovIcon/window-hov.svg';
import { UserContext } from '../Shared/Layout';
import ShowProposalName from './ShowProposalName';
import MuiAlert from '@mui/material/Alert';
import ErrorIcon from '@mui/icons-material/Error';
import { SnackbarContext } from '../../App';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CoveringDrawer } from './CoveringsDrawer';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { CoveringDrawer2 } from './CoveringDrawer2';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import NotesDrawer from './NotesDrawer';
import Checkbox from '@mui/material/Checkbox';
import NoteMiscIcon from '../../assets/icons/misc/misc-note.svg';
import ProposalIcon from '../../assets/icons/primary/proposal.svg';
import FilterIcon from '../../assets/icons/secondary/2nd-filter.svg';
import ProposalsStepper from './ProposalsStepper';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../assets/icons/drawer-alert/drw-alert.svg';
import DrawerConinueIcon from '../../assets/icons/drawer-alert/drw-continue.svg';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import { WindowContext, WindowDispatchContext } from '../Shared/WindowProvider';
import WindowDetails from '../Shared/WindowDetails';
import fracty from 'fracty';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(2),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
      border: '1px solid #E0E0E0',
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
      border: '1px solid #E0E0E0',
    },
  },
}));

export const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
  },
});

const outerTheme = createTheme({
  palette: {
    primary: {
      main: '#333333',
    },
  },
});

const theme1 = createTheme({
  palette: {
    border: {
      main: '1px solid #000',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#FAC41E',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          // backgroundColor: "green", // date picker textfield style
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#008593 !important',
          },
          '&.Mui-disabled': {
            backgroundColor: 'white', // disable date
            color: '#E9E9E9',
          },
        },
        dayWithMargin: {
          backgroundColor: 'white', // not selected date
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          // backgroundColor: "blue" //calender icon & month change arrow icon style
        },
      },
    },
  },
});

const useStyles = makeStyles({
  paper: {
    width: '90%',
    background: 'rgba(69, 157, 166, 70%) !important',
  },
  backDrop: {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  paddingRight: theme.spacing(0.5),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

function containsEmptyValues(arr) {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] === ' ' || arr[i] === '' || arr[i] === undefined) {
      return true;
    }
  }
  return false;
}

export default function ProposalWindowCovering({ window }) {
  const classes = useStyles();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const contextvalue = useContext(SnackbarContext);
  const coveringdetail = useContext(UserContext);
  const { windowDetails } = useContext(WindowContext);
  const windowDispatch = useContext(WindowDispatchContext);

  if (location.state === null) {
    navigate('/pagenotfound');
  }

  const [brand, setBrand] = useState('');
  const [label, setLabel] = useState('');
  const [type, setType] = useState('');
  const [product, setProduct] = useState('');
  const [opacity, setOpacity] = useState('');
  const [fabric, setFabric] = useState('');
  const [lift, setLift] = useState('');
  const [direction, setDirection] = useState('');
  const [headrail, setHeadrail] = useState('');

  const [features, setFeatures] = useState('');
  const [track, setTrack] = useState('');
  const [design, setDesigner] = useState('0');
  const [install, setInstall] = useState('Yes');
  const [clearfilters, setClearFilters] = useState(false);
  const [option, setOption] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [chooseFilters, setChooseFilters] = useState([]);
  const [chooseProduct, setChooseProduct] = useState([]);
  const [chooseStyle, setChooseStyle] = useState([]);
  const [wcc, setWCC] = useState('');
  const [brands, setBrands] = useState([]);
  const [brandLabel, setBrandLabel] = useState([]);
  const [brandLabelProduct, setbrandLabelProduct] = useState([]);
  const [brandLabelProductType, setbrandLabelProductType] = useState([]);
  const [windowData, setWindowData] = useState([]);
  const [brandLabelProductTypeOpacity, setbrandLabelProductTypeOpacity] = useState([]);
  const [brandLabelProductTypeOpacityFabric, setbrandLabelProductTypeOpacityFabric] = useState([]);
  const [brandLabelProductTypeOpacityFabricLift, setbrandLabelProductTypeOpacityFabricLift] = useState([]);
  const [brandLabelProductTypeOpacityFabricLiftDirection, setbrandLabelProductTypeOpacityFabricLiftDirection] = useState([]);
  const [brandLabelProductTypeOpacityFabricLiftDirectionHeadrail, setbrandLabelProductTypeOpacityFabricLiftDirectionHeadrail] = useState([]);
  const [brandLabelProductTypeOpacityFabricLiftDirectionHeadrailValance, setbrandLabelProductTypeOpacityFabricLiftDirectionHeadrailValance] = useState([]);
  const [brandLabelProductTypeOpacityFabricLiftDirectionHeadrailValanceFeatures, setbrandLabelProductTypeOpacityFabricLiftDirectionHeadrailValanceFeatures] = useState([]);
  const [brandLabelProductTypeOpacityFabricLiftDirectionHeadrailValanceFeaturesTrack, setbrandLabelProductTypeOpacityFabricLiftDirectionHeadrailValanceFeaturesTrack] = useState([]);
  const [sucessmsg, setSuccessMsg] = useState(false);
  const [errormsg, setErrorMsg] = useState(false);
  const [AllKeywords, setAllKeywords] = useState([]);
  const [value, setValue] = useState([]);
  const [showInTable, setShowInTable] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [selectedSKU, setSelectedSKU] = React.useState('');
  const [proposalName, setProposalName] = useState();
  const [brandnames, setBrandNames] = useState([]);
  const [categorynames, setCategoryNames] = useState([]);
  const [AllreadyCoveringAppliedWindows, setAllreadyCoveringAppliedWindows] = useState([]);
  const [CoveringnotAppliedtoWindows, setCoveringnotAppliedtoWindows] = useState([]);

  const [OptionName, setOptionName] = useState([]);
  const [LiftSystem, setLiftSystem] = useState([]);
  const [LiftSytemDetails, setLiftSytemDetails] = useState([]);
  const [selectedLiftSytemDetails, setSelectedLiftSytemDetails] = useState([]);
  const [SelectedLiftSytemSKU, setSelectedLiftSytemSKU] = useState();
  const [SelectedOptionDetails, setSelectedOptionDetails] = useState([]);
  const [SelectedOptionSKU, setSelectedOptionSKU] = useState();
  const [SelectedFabricSKU, setSelectedFabricSKU] = useState();
  const [skuCombination, setSkuCombination] = useState([]);
  const [ProductDescription, setProductDescriptions] = useState([]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [sendmsg, setSendmsg] = useState();
  const [showProductDescription, setShowProductDescription] = useState(false);
  const [showSKUCombination, setShowSKUCombination] = useState(false);
  const [OptionalFinalizationFields, setOptionalFinalizationFields] = useState({});
  const [confirmSalesOrderStatus, setConfirmSalesOrderStatus] = useState(false);
  const [confirmationforSO, setConfirmationforSO] = useState(false);
  const [markAsConfirmedSalesOrderStatus, setMarkAsConfirmSalesOrderStatus] = useState(false);
  const [selectedOptionJSON, setSelectedOptionJSON] = useState({});
  const [openOptions, setOpenOptions] = useState(false);
  const [CoveringSearchResults, setCoveringSearchResults] = useState([]);
  const [CoveringDetails, setCoveringDetails] = useState([]);
  const [searchArr, setSearchArr] = useState([]);
  const [labelnames, setLabelNames] = useState([]);
  const [isEnabled, setIsEnabled] = useState(true);
  const [keywordsbyFilter, setKeywordsbyFilter] = useState([]);
  const [keywordsbySearch, setKeywordsbySearch] = useState([]);
  const [CoveringCategory, setCoveringCategory] = useState();
  const [materialColor, setMaterialColor] = useState();
  const [valanceColor, setValanceColor] = useState();
  const [hingeColor, setHingeColor] = useState();
  const [headrailColor, setHeadrailColor] = useState();
  const [cordColor, setCordColor] = useState();
  const [pClip, setPClip] = useState();
  const [paintColor, setPaintColor] = useState();
  const [tiltConfiguration, setTiltConfiguration] = useState();
  const [cordLocation, setCordLocation] = useState();
  const [cordType, setCordType] = useState();
  const [panelConfiguration, setPanelConfiguration] = useState();
  const [checkedItems, setCheckedItems] = useState();
  const [showmismatchalert, setShowMismatchAlert] = useState();
  const [optionSKUs, setOptionSkus] = useState([]);
  const [ReqOptionDetails, setReqOptionDetails] = useState([]);
  const [NotReqOptionDetails, setNotReqOptionDetails] = useState([]);
  const [saveStatus, setSaveStatus] = useState(false);
  const [AllOptionSelectedStatus, setAllOptionSelectedStatus] = useState(false);
  const [allOptionNPSSelectedStatus, setAllOptionNPSSelectedStatus] = useState(false);
  const [outgoingwindow_width, setOutgoingSizeW] = useState();
  const [outgoingwindow_height, setOutgoingSizeH] = useState();
  const [size, setSize] = useState();
  const [psf, setPSF] = useState();
  const [proposalAdditionalSKU, setProposalAdditionalSKU] = useState();
  const [isImageHovered, setIsImageHovered] = useState(false);
  const [isImageHovered2, setIsImageHovered2] = useState(false);
  const [SelectedWindow, setSelectedWindow] = useState([]);
  const [SKUDetails, setSKUDetails] = useState([]);
  const [customerID, setCustomerID] = useState();
  const [addressID, setAddressID] = useState();
  const [windowName, setWindowName] = useState();
  const [mount, setMount] = useState('');
  const [outgoingmount, setOutgoingMount] = useState('');
  const [mountsurface, setMountsurface] = useState('');
  const [outgoingmountsurface, setOutgoingMountsurface] = useState('');
  const [ladder, setLadder] = useState('');
  const [outgoingladder, setOutgoingLadder] = useState('');
  const [roomLabel, setRoomLabel] = useState();
  const [sku, setSku] = useState('');
  const [coveringStatus, setCoveringStatus] = useState('');
  const [coveringType, setCoveringType] = useState('');
  const [coveringSelected, setCoveringSelected] = useState('');
  const [existingCovering, setExistingCovering] = useState('');
  const [measurementType, setMeasurementType] = useState('');
  const [buildType, setBuildType] = useState('');
  const [outbrand, setOutBrand] = useState('');
  const [outproduct, setOutProduct] = useState('');
  const [outlabel, setOutLabel] = useState('');
  const [outtype, setOutType] = useState('');
  const [outopacity, setOutOpacity] = useState();
  const [outlift, setOutLift] = useState('');

  const [outfabric, setOutFabric] = useState();

  const [outwcc, setOutWCC] = useState('');
  const [SelectedFabricSKUName, setSelectedFabricSKUName] = useState();
  const [outOption, setOutOption] = useState('');
  const [AddNote, setAddNote] = useState('');
  const [leftshade_size, setLeftshade_size] = useState('');
  const [rightshade_size, setRightshade_size] = useState('');
  const [centershade_size, setCentershade_size] = useState('');
  const [cutout, setCutout] = useState('None');
  const [multiple, setMultiple] = useState('Single');
  const [rolldirection, setRollDirection] = useState('Standard');
  const [Panel, setPanel] = useState('2');
  const [Windowsill, setWindowsill] = useState('Yes');
  const [DeadMold, setDeadMold] = useState('Yes');
  const [Buildout, setBuildout] = useState('No');
  const [FillerStrip, setFillerStrip] = useState('No');
  const [SplitTilt, setSplitTilt] = useState('No');
  const [SplitRail, setSplitRail] = useState('No');
  const [AddNoteforBuildOut, setAddNoteforBuildOut] = useState('');
  const [AddNoteforFillerStrip, setAddNoteforFillerStrip] = useState('');
  const [AddNoteforSplitTilt, setAddNoteforSplitTilt] = useState('');
  const [AddNoteforSplitRail, setAddNoteforSplitRail] = useState('');
  const [TPost, setTPost] = useState('None');
  const [livaluechangeflag, setLIValueChanged] = useState(false);
  const [materialDescription, setMaterialDescription] = useState('');

  // error handling states
  const [ApplyCoveringsErrorAlert, setApplyCoveringsErrorAlert] = useState(false);
  const [ApplyCoveringsErrorMsg, setApplyCoveringsErrorMsg] = useState('');

  const [ProposalByProposalIdErrorAlert, setProposalByProposalIdErrorAlert] = useState(false);
  const [ProposalByProposalIdErrorMsg, setProposalByProposalIdErrorMsg] = useState('');

  //  hover states

  const [isHovered, setIsHovered] = useState(false);

  const handleHoverFun = () => setIsHovered(true);

  const handleMouseLeaveFun = () => setIsHovered(false);

  const fetchdata = (
    drawerstatus,
    wcc,
    brand,
    label,
    product,
    type,
    opacity,
    fabric,
    lift,
    direction,
    option,
    showtable,
    newProductDescription,
    ProductDescription,
    newSKUCombinations,
    newselectedFabricSKU,
    newselectedLiftSytemSKU,
    SelectedOptionSKU,
    newshowInTable,
    clearfilters,
    showPD,
    showSKU,
    windowData,
    measurementType,
    outgoingwindow_width,
    outgoingwindow_height,
    existingCovering,
    mountsurface,
    buildType,
    ladder,
    OptionalFinalizationFields,
    selectedOptionJSON,
    checkedItems,
    saveStatus,
    size,
    psf,
    proposalAdditionalSKU,
    AllOptionSelectedStatus,
    AllOptionNPSSelectedStatus
  ) => {
    if (wcc && brand && label && product && type && opacity && fabric) {
      setShowTable(true);
    }

    setWCC(wcc);
    setBrand(brand);
    setLabel(label);
    setProduct(product);
    setType(type);
    setOpacity(opacity);
    setFabric(fabric);
    setLift(lift);
    setDirection(direction);
    setCheckedItems(checkedItems);
    setOption([...option]);
    setProductDescriptions([ProductDescription]);
    setSkuCombination(newSKUCombinations.filter(unique));
    setSelectedFabricSKU(newselectedFabricSKU);
    setShowInTable(newshowInTable);
    setSelectedLiftSytemSKU(newselectedLiftSytemSKU);
    setSelectedOptionSKU(SelectedOptionSKU);
    setSelectedOptionJSON(selectedOptionJSON);
    setOptionalFinalizationFields(OptionalFinalizationFields);
    setMaterialColor(materialColor);
    setValanceColor(valanceColor);
    setHingeColor(hingeColor);
    setHeadrailColor(headrailColor);
    setCordColor(cordColor);
    setPClip(pClip);
    setPaintColor(paintColor);
    setTiltConfiguration(tiltConfiguration);
    setCordLocation(cordLocation);
    setCordType(cordType);
    setPanelConfiguration(panelConfiguration);
    setClearFilters(clearfilters);

    setShowProductDescription(showPD);
    setShowSKUCombination(showSKU);
    setAllOptionSelectedStatus(AllOptionSelectedStatus);
    setAllOptionNPSSelectedStatus(AllOptionNPSSelectedStatus);
    setIsDrawerOpen(!drawerstatus);
  };

  useEffect(() => {
    const scrollToTop = () => {
      if (typeof window !== 'undefined') {
        window.scrollTo(0, 0);
      } else {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
      }
    };
    scrollToTop();
    getVerified();

    if (location.state) {
      getProposalNotes();
    }
    if (coveringdetails !== undefined && coveringdetails.length > 0) {
      const covering_category = coveringdetails.filter((element) => element.covering_category);
      const Brand = coveringdetails.filter((element) => element.brand);

      setCategoryNames(covering_category.filter(unique));
      setBrandNames(Brand.filter(unique));
    }
    setProposalName(location.state.proposalName);
    if (location.state) {
      const proposalMeasurementTypeSpecificFactor = location.state.selectedwindows;
      setWindowDepthCount(proposalMeasurementTypeSpecificFactor.reduce((count, WD) => (WD.measurement_type_specific_factor.WindowDepth === 'Other' ? count + 1 : count), 0));

      setObstructionsCount(proposalMeasurementTypeSpecificFactor.reduce((count, WD) => (WD.measurement_type_specific_factor.Obstructions === 'Yes' ? count + 1 : count), 0));

      proposalMeasurementTypeSpecificFactor.map((WD) => {
        setObstructionsValue((prevValue) => {
          if (WD.measurement_type_specific_factor.Obstructions === 'Yes') {
            return WD.measurement_type_specific_factor.Obstructions;
          } else {
            return prevValue;
          }
        });

        setWindowDepthValue((prevValue) => {
          if (WD.measurement_type_specific_factor.WindowDepth === 'Other') {
            return WD.measurement_type_specific_factor.WindowDepth;
          } else {
            return prevValue;
          }
        });
      });

      const VerticalConfigurationInProposal = location.state.selectedwindows;
      let arr_measurement_type = [];
      VerticalConfigurationInProposal.map((ele) => {
        arr_measurement_type.push(ele.measurement_type);
        let proposal_additional_sku = ele.proposal_additional_sku;

        if (ele.measurement_type === 'Shutters') {
          proposal_additional_sku.map((val) => {
            const shuttersArray = arr_measurement_type.filter((item) => item === 'Shutters');
            const hasMoreThanOneShutters = shuttersArray.length > 1;

            if (hasMoreThanOneShutters) {
              setVerticalConfigurationCount(false);
            } else {
              setVerticalConfigurationCount(true);

              if (arr_measurement_type.length > 1) {
                setVerticalConfigurationCount(false);
              }
            }
            if (val.sku_name === '2 Sections') {
              setVerticalConfigurationValue(true);
            }
          });
        } else {
          proposal_additional_sku.map((val) => {
            if (val.sku_name === '2 Sections') {
              setVerticalConfigurationValue(true);

              if (arr_measurement_type.length > 1) {
                setVerticalConfigurationCount(false);
              } else {
                setVerticalConfigurationCount(true);
              }
            } else if (val.sku_name === '3 Sections') {
              setVerticalConfigurationValue(true);
              if (arr_measurement_type.length > 1) {
                setVerticalConfigurationCount(false);
              } else {
                setVerticalConfigurationCount(true);
              }
            } else if (val.sku_name === '4 Sections') {
              setVerticalConfigurationValue(true);
              if (arr_measurement_type.length > 1) {
                setVerticalConfigurationCount(false);
              } else {
                setVerticalConfigurationCount(true);
              }
            } else if (val.sku_name === '5 Sections') {
              setVerticalConfigurationValue(true);
              if (arr_measurement_type.length > 1) {
                setVerticalConfigurationCount(false);
              } else {
                setVerticalConfigurationCount(true);
              }
            } else if (val.sku_name === '6 Sections') {
              setVerticalConfigurationValue(true);
              if (arr_measurement_type.length > 1) {
                setVerticalConfigurationCount(false);
              } else {
                setVerticalConfigurationCount(true);
              }
            }
          });
        }
        if (VerticalConfigurationInProposal.length > 1) {
          setWindowConfigurationCount(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (OptionalFinalizationFields) {
      setOptionalFinalizationFields(OptionalFinalizationFields);
      function containsEmptyValues(arr) {
        if (!arr.length) {
          return true;
        }
        for (var i = 0; i < arr.length; i++) {
          if (arr[i] === ' ' || arr[i] === '' || arr[i] === undefined) {
            return true;
          }
        }
        return false;
      }
      if (!containsEmptyValues(Object.values(OptionalFinalizationFields))) {
        setConfirmSalesOrderStatus(true);
      } else {
        setConfirmSalesOrderStatus(false);
        setMarkAsConfirmSalesOrderStatus(false);
      }
    } else {
      setConfirmSalesOrderStatus(false);
      setMarkAsConfirmSalesOrderStatus(false);
    }
  }, [OptionalFinalizationFields]);

  const filteredCoveringsDetails = {
    wcc: wcc,
    brand: brand,
    label: label,
    product: product,
    type: type,
    opacity: opacity,
    fabric: fabric,
    lift: lift,
    direction: direction,
    option: option,
    ProductDescription: ProductDescription,
    SKUCombinations: skuCombination,
    showInTable: showInTable,
    SelectedFabricSKU: SelectedFabricSKU,
    SelectedOptionSKU: SelectedOptionSKU,
    SelectedLiftSytemSKU: SelectedLiftSytemSKU,
    sendmsg: sendmsg,
    windowData: [windowData],
    OptionalFinalizationFields: OptionalFinalizationFields,
    selectedOptionJSON: selectedOptionJSON,
    checkedItems: checkedItems,
    clearfilters: clearfilters,
    saveStatus: saveStatus,
    existingCovering: existingCovering,
    buildType: buildType,
    measurementType: measurementType,
    mountsurface: mountsurface,
    ladder: ladder,
    outgoingwindow_width: outgoingwindow_width,
    outgoingwindow_height: outgoingwindow_height,
    size: size,
    psf: psf,
    proposalAdditionalSKU: proposalAdditionalSKU,
    requestFrom: 'ProposalApplyCovering',
    SelectedWindow: SelectedWindow,
  };

  const vertical = 'top';
  const horizontal = 'center';

  let windowsendforapplycovering = [];
  let windowname = [];
  let appliedcoveringswindowname = [];
  const coveringdetails = location.state.coveringdetails ? location.state.coveringdetails : coveringdetail;

  const windowsetnull = location.state.selectedwindows;
  const totalrowdata = location.state.totalselectedwindows;

  const FirstName = location.state.customerdata.first_name;
  const LastName = location.state.customerdata.last_name;
  const CustomerID = location.state.customerdata.customer_id;
  const ProposalID = location.state.proposalID;
  const WindowSetID = location.state.customerdata.window_id;
  const WindowID = location.state.selectedwindows[0].window_id;
  const ProposalName = location.state.proposalName;
  const WindowName = location.state.selectedwindows[0].window_name;

  useEffect(() => {
    const { proposal_measurement_type, proposal_additional_sku, proposal_measurement_type_specific_factor, ...details } = filterDetails(location.state.selectedwindows);

    const windowDepthSku = proposal_additional_sku.filter((skuDetails) => skuDetails.type === 'Window Depth')[0];
    const existingCoveringSku = proposal_additional_sku.filter((skuDetails) => skuDetails.type === 'Existing Covering')[0];
    const ladderSku = proposal_additional_sku.filter((skuDetails) => skuDetails.type === 'Ladder')[0];
    const mountSurfaceSku = proposal_additional_sku.filter((skuDetails) => skuDetails.type === 'Mount Surface')[0];
    const windowConfigurationSku = proposal_additional_sku.filter((skuDetails) => skuDetails.type === 'Window Configuration')[0];
    const obstructionsSku = proposal_additional_sku.filter((skuDetails) => skuDetails.type === 'Obstructions')[0];
    const buildTypeSku = proposal_additional_sku.filter((skuDetails) => skuDetails.type === 'Build Type')[0];
    const verticalConfigurationSku = proposal_additional_sku.filter((skuDetails) => skuDetails.type === 'Vertical Configuration')[0];

    windowDispatch({
      action: 'SET_ALL',
      payload: {
        ids: location.state.selectedwindows.map((window) => window.window_id),
        measurementType: proposal_measurement_type,
        width: details.proposal_window_width,
        height: details.proposal_window_height,
        size: details.proposal_window_width !== 'Multiple' || details.proposal_window_height !== 'Multiple' ? `${fracty(details.proposal_window_width)}" X ${fracty(details.proposal_window_height)}"` : 'Multiple',
        windowDepth: { sku: windowDepthSku?.additional_charge_sku, service_name: windowDepthSku?.sku_name },
        windowDepthMeasurement: windowDepthSku?.sku_name === 'Multiple' ? 'Multple' : parseFloat(windowDepthSku?.additional_detail),
        windowConfiguration: { sku: windowConfigurationSku?.additional_charge_sku, service_name: windowConfigurationSku?.sku_name },
        sections: windowConfigurationSku ? JSON.parse(windowConfigurationSku.additional_detail) : null,
        existingCoverings: { sku: existingCoveringSku.additional_charge_sku, service_name: existingCoveringSku.sku_name },
        ladder: { sku: ladderSku.additional_charge_sku, service_name: ladderSku.sku_name },
        mountSurface: { sku: mountSurfaceSku.additional_charge_sku, service_name: mountSurfaceSku.sku_name },
        obstructions: { sku: obstructionsSku?.additional_charge_sku, service_name: obstructionsSku?.sku_name },
        obstructionsDetails: obstructionsSku?.additional_detail ? JSON.parse(obstructionsSku?.additional_detail).join(';') : null,
        buildType: { sku: buildTypeSku?.additional_charge_sku, service_name: buildTypeSku?.sku_name },
        windowSill: proposal_measurement_type_specific_factor?.Windowsill ? { sku: proposal_measurement_type_specific_factor.Windowsill === 'No' ? 0 : 1, service_name: proposal_measurement_type_specific_factor.Windowsill } : { sku: '', service_name: '' },
        verticalConfigurations: { sku: verticalConfigurationSku?.additional_charge_sku, service_name: verticalConfigurationSku?.sku_name },
        verticalSections: verticalConfigurationSku ? JSON.parse(verticalConfigurationSku.additional_detail) : null,
      },
    });
  }, [location.state.selectedwindows]);

  const filterDetails = (windows) => {
    if (windows.length === 1) {
      return windows[0];
    }

    const [base, ...others] = windows;
    const { proposal_additional_sku: baseProposalAdditionalSku, proposal_measurement_type_specific_factor: baseProposalMeasurementTypeSpecificFactor } = base;
    const othersProposalAdditionalSkus = others.flatMap(({ proposal_additional_sku }) => proposal_additional_sku);
    const othersProposalMeasurementTypeSpecificFactor = others.flatMap(({ proposal_measurement_type_specific_factor }) => proposal_measurement_type_specific_factor);

    const filterByType = (skuType) => baseProposalAdditionalSku.filter((skuDetails) => skuDetails.type === skuType);

    const generateSkuObject = (skuType, [baseSku]) => {
      return othersProposalAdditionalSkus
        .filter((skuDetails) => skuDetails.type === skuType)
        .every((skuDetails) => {
          return skuDetails.sku_name === baseSku?.sku_name;
        })
        ? baseSku
        : {
            additional_charge_sku: 'Multiple',
            additional_detail: null,
            sku_name: 'Multiple',
            label: '',
            type: skuType,
          };
    };

    const existingCoveringSku = generateSkuObject('Existing Covering', filterByType('Existing Covering'));
    const ladderSku = generateSkuObject('Ladder', filterByType('Ladder'));
    const mountSurfaceSku = generateSkuObject('Mount Surface', filterByType('Mount Surface'));
    const windowDepthSku = generateSkuObject('Window Depth', filterByType('Window Depth'));
    const obstructionsSku = generateSkuObject('Obstructions', filterByType('Obstructions'));
    const buildTypeSku = generateSkuObject('Build Type', filterByType('Build Type'));

    const windowConfigurationSku = generateSkuObject('Window Configuration', filterByType('Window Configuration')) || {};

    const proposal_measurement_type_specific_factor = {
      ...baseProposalMeasurementTypeSpecificFactor,
      ...othersProposalMeasurementTypeSpecificFactor.reduce((acc, curr) => {
        Object.entries(curr).forEach(([key, value]) => {
          if (acc[key] !== baseProposalMeasurementTypeSpecificFactor[key]) {
            acc[key] = 'Multiple';
          }
        });

        return acc;
      }, {}),
    };

    return {
      proposal_detail_id: 'Multiple',
      proposal_window_width: 'Multiple',
      proposal_window_height: 'Multiple',
      proposal_measurement_type: others.every(({ proposal_measurement_type }) => proposal_measurement_type === base.proposal_measurement_type) ? base.proposal_measurement_type : 'Multiple',
      proposal_measurement_type_specific_factor,
      proposal_additional_sku: [existingCoveringSku, ladderSku, mountSurfaceSku, windowConfigurationSku, windowDepthSku, obstructionsSku, buildTypeSku],
    };
  };

  let proposaldetails = {
    customerdata: location.state.customerdata,
    proposal_id: location.state.proposalID,
    proposal_name: location.state.proposalName,
  };

  windowsendforapplycovering = location.state.path && location.state.path === 'EPSW' ? windowsetnull.filter((window) => !window.covering_selected) : windowsetnull.filter((window) => window.window_selected);

  let searchedArr = [];
  let checkSubset = (parentArray, subsetArray) => {
    return subsetArray.every((el) => {
      return parentArray.includes(el);
    });
  };

  useEffect(() => {
    if (coveringdetail !== undefined) {
      setCoveringDetails(coveringdetail);
    }

    setCoveringCategory(wcc);
  }, [brand, wcc]);

  useEffect(() => {
    if (coveringdetail !== undefined) {
      setCoveringDetails(coveringdetail);
    }
  }, [coveringdetail]);

  useEffect(() => {
    if (value.length > 0) {
      setKeywordsbySearch(value);
    }
    if (value.length === 0) {
      setWCC('');
      setBrand('');
      setLabel('');
      setProduct('');
      setType('');
      setOpacity('');
      setFabric('');
      setShowSKUCombination(false);
      setProductDescriptions([]);
      setShowProductDescription(false);
      setIsEnabled(true);
      setKeywordsbySearch([]);
    }
  }, [value]);

  useEffect(() => {
    var filteredKeywords = [];
    if (keywordsbyFilter.length > 0 && keywordsbySearch.length > 0) {
      let filteredVal = keywordsbyFilter.filter((word) => keywordsbySearch.includes(word));
      if (filteredVal.length > 0) {
        setKeywordsbyFilter([]);
        setKeywordsbySearch([]);
        filteredKeywords = filteredVal;
      } else {
        filteredKeywords = keywordsbySearch;
      }
    } else if (keywordsbyFilter.length === 0 && keywordsbySearch.length > 0) {
      filteredKeywords = keywordsbySearch;
    } else if (keywordsbyFilter.length > 0 && keywordsbySearch.length === 0) {
      filteredKeywords = keywordsbyFilter;
    }

    if (filteredKeywords.length > 0) {
      handleSearch(filteredKeywords);
    }
  }, [keywordsbySearch, keywordsbyFilter]);

  const handleSearch = (searchedItems) => {
    if (searchedItems.length === 0) {
      setProductDescriptions([]);
      setSkuCombination([]);
    }

    setSearchArr(searchedItems);
    setCoveringDetails(coveringdetail);
    searchedArr = coveringdetail;

    let materialfinalarr = [];
    let materialarr = [];
    let listsystemarr = [];
    let liftsystems = [];
    let directions = [];
    let optionsarr = [];
    searchedArr.forEach((element, index) => {
      if (element.sku_type === 'M') {
        materialarr.push(element);
      } else if (element.sku_type === 'L') {
        listsystemarr.push(element);
        liftsystems.push(element.value_1);
        directions.push(element.value_2);
      } else if (element.sku_type === 'P') {
        optionsarr.push(element);
      }

      let status = checkSubset(searchedArr[index].keywords, searchedItems);

      if (status === true && searchedArr[index].sku_type === 'M') {
        materialfinalarr.push(searchedArr[index]);
      }
    });

    materialfinalarr = materialfinalarr.filter(unique);
    setCoveringSearchResults(materialfinalarr);
    setShowInTable(materialfinalarr);

    let mskus = [];
    if (materialfinalarr.length === 1) {
      setProductDescriptions(materialfinalarr[0].description);
      setSkuCombination([materialfinalarr[0].sku]);
      mskus = [materialfinalarr[0].sku];

      if (materialfinalarr.length === 1 && lift && direction) {
        let liftSystemPD = [lift, direction];
        setProductDescriptions([materialfinalarr[0].description, ...liftSystemPD]);
        setSkuCombination([materialfinalarr[0].sku, SelectedLiftSytemSKU]);
        setShowProductDescription(true);
      }
      if (materialfinalarr.length === 1 && option.length) {
        let optionPD = [...option];

        setProductDescriptions([materialfinalarr[0].description, ...optionPD]);
        setSkuCombination([materialfinalarr[0].sku, ...SelectedOptionSKU]);
        setShowProductDescription(true);
      }
      if (materialfinalarr.length === 1 && lift && direction && option.length > 0) {
        let liftSystemPD = [lift, direction];
        let optionPD = [...option];

        setProductDescriptions([materialfinalarr[0].description, ...liftSystemPD, ...optionPD]);
        setSkuCombination([materialfinalarr[0].sku, SelectedLiftSytemSKU, ...SelectedOptionSKU]);
        setShowProductDescription(true);
      }
    }
    liftsystems = liftsystems.filter(unique);

    setLiftSystem(liftsystems);
    directions = directions.filter(unique);

    setbrandLabelProductTypeOpacityFabricLiftDirection(directions);
    if (materialfinalarr.length === 1) {
      setShowSKUCombination(true);

      setSelectedFabricSKU(materialfinalarr[0].sku);
      setShowProductDescription(true);

      if (materialfinalarr[0].sku) {
        setIsEnabled(false);
      } else {
        setIsEnabled(true);
      }
    }
  };

  const reset = () => {
    setClearFilters(true);
    setValue([]);
    setSearchArr([]);
    setCoveringSearchResults([]);
    setWCC('');
    setShowMismatchAlert(false);
    setBrand('');
    setProduct('');
    setLabel('');
    setType('');
    setOpacity('');
    setFabric('');
    setLift('');
    setDirection('');
    setOption([]);
    setHeadrail('');
    setFeatures('');
    setTrack('');
    setDesigner(0);
    setSelectedSKU('');
    setProductDescriptions([]);
    setSkuCombination([]);
    setConfirmSalesOrderStatus(false);
    setMarkAsConfirmSalesOrderStatus(false);
    setSelectedFabricSKU('');
    setSelectedLiftSytemSKU('');
    setSelectedOptionSKU([]);
    setOptionSkus([]);
    setCheckedItems();
    setSelectedOptionJSON({});
    setReqOptionDetails([]);
    setNotReqOptionDetails([]);
    setOptionalFinalizationFields({});
    setSelectedOptionJSON({});
    setMaterialColor('');
    setHeadrailColor('');
    setCordLocation('');
    setValanceColor('');
    setHingeColor('');
    setHeadrailColor('');
    setCordColor('');
    setPClip('');
    setPaintColor('');
    setTiltConfiguration('');
    setPanelConfiguration('');
    setCordLocation('');
    setIsEnabled(true);
    setBrands([]);
    setBrandLabel([]);
    setbrandLabelProduct([]);
    setbrandLabelProductType([]);
    setbrandLabelProductTypeOpacity([]);
    setbrandLabelProductTypeOpacityFabric([]);
    setbrandLabelProductTypeOpacityFabricLift([]);
    setbrandLabelProductTypeOpacityFabricLiftDirection([]);
    setbrandLabelProductTypeOpacityFabricLiftDirectionHeadrail([]);
    setbrandLabelProductTypeOpacityFabricLiftDirectionHeadrailValance([]);
    setbrandLabelProductTypeOpacityFabricLiftDirectionHeadrailValanceFeatures([]);
    setbrandLabelProductTypeOpacityFabricLiftDirectionHeadrailValanceFeaturesTrack([]);

    setShowTable(false);
    setShowInTable([]);
  };

  let FilteredCovering_Category = [];
  let FilteredBrand = [];
  let FilteredLabel = [];
  let FilteredProduct = [];
  let FilteredType = [];
  let FilteredOpacity = [];
  let FilteredFabric = [];

  useEffect(() => {
    let keywordsArr = [];
    if (CoveringDetails !== undefined && CoveringDetails.length > 0) {
      CoveringDetails.forEach((element) => {
        if (element.keywords) {
          keywordsArr.push(...element.keywords);
        }
      });

      setAllKeywords(keywordsArr.filter(unique));
      if (CoveringSearchResults.length > 0) {
        CoveringSearchResults.forEach((element, i) => {
          FilteredCovering_Category.push(element.covering_category);
          FilteredBrand.push(element.brand);
          FilteredLabel.push(element.label);
          FilteredProduct.push(element.product);
          FilteredType.push(element.type);
          FilteredOpacity.push(element.opacity);
          FilteredFabric.push(element.material);
        });
        FilteredCovering_Category = FilteredCovering_Category.filter(unique);
        FilteredBrand = FilteredBrand.filter(unique);
        FilteredProduct = FilteredProduct.filter(unique);
        FilteredLabel = FilteredLabel.filter(unique);
        FilteredType = FilteredType.filter(unique);
        FilteredOpacity = FilteredOpacity.filter(unique);
        FilteredFabric = FilteredFabric.filter(unique);

        if (FilteredCovering_Category.length === 1) {
          setWCC(FilteredCovering_Category[0]);
          setCategoryNames([FilteredCovering_Category[0]]);
        }
        if (FilteredBrand.length === 1) {
          setBrand(FilteredBrand[0]);
          setBrands([FilteredBrand[0]]);
        }
        if (FilteredBrand.length > 1) {
          setBrand('');
          setBrands(FilteredBrand);
        }

        if (FilteredCovering_Category.length > 0) {
          setCategoryNames(FilteredCovering_Category);
        }

        if (FilteredProduct.length === 1) {
          setProduct(FilteredProduct[0]);
          setbrandLabelProduct([FilteredProduct[0]]);
        }
        if (FilteredProduct.length > 1) {
          setProduct('');
          setbrandLabelProduct(FilteredProduct);
        }
        if (FilteredLabel.length === 1) {
          setLabel(FilteredLabel[0]);
          setBrandLabel([FilteredLabel[0]]);
        }
        if (FilteredLabel.length > 1) {
          setLabel('');
          setBrandLabel(FilteredLabel);
        }
        if (FilteredType.length === 1) {
          setType(FilteredType[0]);
          setbrandLabelProductType([FilteredType[0]]);
        }
        if (FilteredType.length > 1) {
          setType('');
          setbrandLabelProductType(FilteredType);
        }
        if (FilteredOpacity.length === 1) {
          setOpacity(FilteredOpacity[0]);
          setbrandLabelProductTypeOpacity([FilteredOpacity[0]]);
        }
        if (FilteredOpacity.length > 1) {
          setOpacity('');
          setbrandLabelProductTypeOpacity(FilteredOpacity);
        }
        if (FilteredFabric.length === 1) {
          setFabric(FilteredFabric[0]);
          setbrandLabelProductTypeOpacityFabric(FilteredFabric);
        }
        if (FilteredFabric.length > 1) {
          setFabric('');
          setSelectedFabricSKU('');
          setShowSKUCombination(false);
          setShowProductDescription(false);
          setbrandLabelProductTypeOpacityFabric(FilteredFabric);
        }
      }
    }
    if (CoveringSearchResults.length === 0) {
      if (CoveringDetails !== undefined && CoveringDetails.length > 0) {
        const covering_category = CoveringDetails.map((element) => element.covering_category);
        const Brand = CoveringDetails.map((element) => element.brand);
        const Label = CoveringDetails.map((element) => element.label);
        const Product = CoveringDetails.map((element) => element.product);
        const Type = CoveringDetails.map((element) => {
          if (element.type) {
            return element.type;
          }
        });
        const Opacity = CoveringDetails.map((element) => {
          if (element.sku_type === 'M') {
            return element.opacity;
          }
        });
        const Fabric = CoveringDetails.map((element) => {
          if (element.sku_type === 'M') {
            return element.fabric;
          }
        });
        const LiftSystems = [];
        const Directions = [];
        const LiftSystemDetails = [];
        const OptionsAvailable = CoveringDetails.map((element) => {
          if (element.sku_type === 'P') {
            return element.value_1;
          }
        });
        const OptionsAvailableDetails = CoveringDetails.map((element) => {
          if (element.sku_type === 'P') {
            return element;
          }
        });

        CoveringDetails.forEach((element, i) => {
          if (element.sku_type === 'L') {
            if (lift) {
              if (element.value_1 === lift) {
                Directions.push(element.value_2);
              }
            } else {
              Directions.push(element.value_2);
            }
            if (direction) {
              if (element.value_2 === direction) {
                LiftSystems.push(element.value_1);
              }
            } else {
              LiftSystems.push(element.value_1);
            }
            LiftSystemDetails.push(element);
          }
        });

        setCategoryNames(covering_category.filter(unique));
        setLabelNames(Label.filter(unique));
        setBrandLabel(Label.filter(unique));
        setBrands(Brand.filter(unique));

        setbrandLabelProduct(Product.filter(unique));
        setbrandLabelProductType(Type.filter(unique));

        setLiftSystem(LiftSystems.filter(unique));
        setLiftSytemDetails(LiftSystemDetails.filter(unique));
        setSelectedLiftSytemDetails(LiftSystemDetails.filter(unique));
        setbrandLabelProductTypeOpacityFabricLiftDirection(Directions.filter(unique));
        setbrandLabelProductTypeOpacity(Opacity.filter(unique));
        setbrandLabelProductTypeOpacityFabric(Fabric.filter(unique));
        setOptionName(OptionsAvailable.filter(unique));
        setSelectedOptionDetails(OptionsAvailableDetails.filter(unique));
      }
    }
  }, [CoveringSearchResults, CoveringDetails]);

  // Get Token
  const token = sessionStorage.getItem('token');
  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };

  // To navigate proposalDetails page after fill the all fields
  const applyProposalCoverings = async () => {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        proposal_id: location.state.proposalID,
        window_set_id: location.state.customerdata.window_set_id,
        windows: windowsendforapplycovering,
        sku: skuCombination,
        description: ProductDescription,
        direction: direction,
        option: option,
        installation: install,
        windowCoveringCategory: wcc,
        confirm_to_sales_order: markAsConfirmedSalesOrderStatus,
        OptionalFinalizationFields: OptionalFinalizationFields,
        proposal_window_width: '',
        proposal_window_height: '',
        measurement_type: '',
        measurement_type_specific_factor: {},
        build_type: '',
        existing_covering: '',
        mount_surface: '',
        ladder: '',
        proposal_additional_sku: [],
      }),
    };

    const response = await fetch(`${CONFIG.API_URL}/pm/ApplyCoverings`, requestOptions);
    const data = await response.json();

    if (!response.ok || data.error) {
      setApplyCoveringsErrorMsg(data.error_message);
      return setApplyCoveringsErrorAlert(true);
    }
    let windowsselectedforcovering = [];
    let coverings_applied_windows = [];

    data.proposalDetails.forEach((proposal_detail) => {
      if (proposal_detail.window_selected) {
        windowsselectedforcovering.push(proposal_detail);
      }
      if (proposal_detail.covering_selected) {
        coverings_applied_windows.push(proposal_detail);
      }
      if (proposal_detail.covering_selected && proposal_detail.covering_status === 'applied') {
        appliedcoveringswindowname.push(proposal_detail.window_name);
        setAllreadyCoveringAppliedWindows(appliedcoveringswindowname.toString());
        setSuccessMsg(true);
      }
      if (proposal_detail.covering_selected && proposal_detail.covering_status === 'not_applied') {
        windowname.push(proposal_detail.window_name);
        setCoveringnotAppliedtoWindows(windowname.toString());
        setErrorMsg(true);
      }
    });

    if (windowsselectedforcovering.length === coverings_applied_windows.length) {
      contextvalue.setSuccesswindows(true);
      contextvalue.setFailurewindows(true);
      navigate(`/tbm/ps/proposal/${params.id}/details`, {
        state: {
          selectedwindows: selectedwindowsIDs,
          customerdata: windowsetdata,
          totalselectedwindowsforcoverings: data.covering_proposal_details,
          proposalID: location.state.proposalID,
          proposalName: location.state.proposalName,
          totalwindows: location.state.totalwindows,
          windowsselectednotnull: windowsetnull,
          jsonData: jsonData,
          coveringdetails: coveringdetails,
          successwindownames: appliedcoveringswindowname,
          failurewindownames: windowname,
          alerts: data.proposalCoverings,
        },
      });
    } else {
      navigate(`/tbm/ps/proposalselectwindow/${params.id}`, {
        state: {
          coveringdetails: coveringdetails,
          selectedwindows: checkedrowdata,
          customerdata: windowsetdata,
          totalwindows: location.state.totalwindows,
          totalselectedwindowsforcoverings: data.covering_proposal_details,
          windowsselectednotnull: windowsetnull,
          proposalID: location.state.proposalID,
          proposalName: location.state.proposalName,
          jsonData: jsonData,
          path: location.state.path,
        },
      });
    }
  };

  const appliedWindows = [];

  for (let i = 0; i < totalrowdata.length; i++) {
    const window = totalrowdata[i];
    if (window.covering_status === 'applied') {
      appliedWindows.push(window);
    }
  }

  let checkedrowdata = [];
  let selectedwindowsIDs = [];

  // get state from proposalSelectWindow page
  selectedwindowsIDs = location.state.selectedwindows;
  checkedrowdata = selectedwindowsIDs;

  let windowsetdata = [];
  windowsetdata = location.state.customerdata;
  const unique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const send = () => setDeleteItem(true);

  const onDelete = (title) => () => setValue((value) => value.filter((v) => v !== title));

  const jsonData = { brand, label, product, type };

  const [WindowId, setWindowId] = useState(location.state.selectedwindows);
  const [EditNotes, setEditNote] = useState('');
  const [notesData, setNotesData] = useState('');
  const [noteTypeId, setNoteTypeId] = useState('');
  const [noteId, setNoteId] = useState();
  const [eyeButtonMsg, setEyeButtonMsg] = useState(false);
  const [notesDataDescription, setNotesDataDescription] = useState([]);

  const NotesCustomerDetails = {
    firstName: FirstName,
    lastname: LastName,
    customerId: CustomerID,
    proposalId: ProposalID,
    windowSetId: WindowSetID,
    WindowId: WindowID,
    proposalName: ProposalName,
    windowName: WindowName,
  };

  const [valueValue, setValueValue] = useState();

  const [notes, setNotes] = useState(false);
  const [notesContent, setnotesContent] = useState([]);

  const [proposalNotesContent, setProposalNotesContent] = useState([]);
  const [proposalWindowNotes, setproposalWindowNotes] = useState('');

  const getProposalNotes = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    };
    const response = await fetch(`${CONFIG.API_URL}/cm/Notes/${location.state.proposalID}`, requestOptions);
    const data = await response.json();

    if (!data.error) {
      setProposalNotesContent(data.Notes);
    }
  };

  const handleHover = () => {
    setIsImageHovered(!isImageHovered);
  };

  const handleHover2 = () => {
    setIsImageHovered2(!isImageHovered2);
  };

  const checkLift = (newval) => {
    if (newval !== lift) {
      setLIValueChanged(true);
    } else {
      setLIValueChanged(false);
    }
    setOutLift(newval);
  };

  const [deleteItem, setDeleteItem] = useState(false);

  const handleClose = () => setDeleteItem(false);

  const handlenavigateclose = () => {
    setDeleteItem(false);
    navigate(-1);
  };

  const [WindowDepthValue, setWindowDepthValue] = useState('');
  const [ObstructionsValue, setObstructionsValue] = useState('');

  const [WindowDepthCount, setWindowDepthCount] = useState('');
  const [ObstructionsCount, setObstructionsCount] = useState('');

  const [VerticalConfigurationValue, setVerticalConfigurationValue] = useState(false);
  const [VerticalConfigurationCount, setVerticalConfigurationCount] = useState();

  const [WindowConfigurationValue, setWindowConfigurationValue] = useState(false);
  const [WindowConfigurationCount, setWindowConfigurationCount] = useState(false);

  useEffect(() => {
    setConfirmationforSO(confirmSalesOrderStatus && allOptionNPSSelectedStatus);
  }, [confirmSalesOrderStatus, allOptionNPSSelectedStatus]);

  const [editingNote, setEditingNote] = useState(false);
  const [noteDrawerDetails, setNoteDrawerDetails] = useState({});
  const [notesDrawerOpen, setNotesDrawerOpen] = useState(false);

  const closeNotesDrawer = (alert) => {
    setNotesDrawerOpen(false);
    setEditingNote(false);
    setNoteDrawerDetails({});

    if (alert.success === 'Note added successfully!' || alert.success === 'Note updated successfully!') {
      getProposalNotes();
    }
  };

  const openNotesDrawer = (note) => {
    setEditingNote(!!note);
    setNoteDrawerDetails({
      name: proposalName,
      referenceId: ProposalID,
      noteId: note ? note.id : null,
    });
    setNotesDrawerOpen(true);
  };

  return (
    <ThemeProvider theme={outerTheme}>
      {/* ApplyCoveringsErrorAlert */}
      <Snackbar className='snackbar_bar_postition' open={ApplyCoveringsErrorAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setApplyCoveringsErrorAlert(false)}>
        <Alert className='Error1 mainAlert' variant='filled' icon={false}>
          <div className='alertCancel_2'>
            <img
              onClick={() => {
                setApplyCoveringsErrorAlert(false);
              }}
              src={RedDrawerCancelIcon}
              alt='CANCELICONACA'
              className='drawer_secondary_icons_dimension drawer_cancel_cursor'
            />
          </div>
          <div className='alertMain_2_4 confirmIconStyle1'>
            <img src={RedExclamationIcon} alt='CONFIRMICONACA' className='primary_icons_dimension alertImageMargin' />
            <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{ApplyCoveringsErrorMsg}</div>
          </div>
        </Alert>
      </Snackbar>

      {/* ProposalByProposalIdErrorAlert */}
      <Snackbar className='snackbar_bar_postition' open={ProposalByProposalIdErrorAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setProposalByProposalIdErrorAlert(false)}>
        <Alert className='Error1 mainAlert' variant='filled' icon={false}>
          <div className='alertCancel_2'>
            <img
              onClick={() => {
                setProposalByProposalIdErrorAlert(false);
              }}
              src={RedDrawerCancelIcon}
              alt='CANCELICONPBPA'
              className='drawer_secondary_icons_dimension drawer_cancel_cursor'
            />
          </div>
          <div className='alertMain_2_4 confirmIconStyle1'>
            <img src={RedExclamationIcon} alt='CONFIRMICONPBPA' className='primary_icons_dimension alertImageMargin' />
            <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{ProposalByProposalIdErrorMsg}</div>
          </div>
        </Alert>
      </Snackbar>

      <Snackbar className='snackbar_bar_postition' open={deleteItem} anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
        <Alert className='Success mainAlert' variant='filled' icon={false}>
          <div className='alertCancel' align='right'>
            <img onClick={handleClose} src={DrawerCancelIcon} alt='CANCELICONS' className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
          </div>

          <div className='alertMainNew'>
            <div align='left' style={{ paddingTop: 37 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'Center',
                }}
              >
                <Box className='alertCantinueButton'>
                  <img src={DrawerAlertIcon} alt='CONFIRMICONS' className='primary_icons_dimension' />
                </Box>
              </Box>

              <div
                style={{
                  paddingTop: 20,
                  paddingLeft: '26px',
                  fontSize: '20px',
                  fontFamily: 'RobotoRegular',
                }}
              >
                Clicking BACK will lose any unsaved work. Okay to continue?
              </div>
            </div>

            <div
              align='left'
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '20px 10px 0px 24px',
                justifyContent: 'center',
                alignItems: 'start',
                lineHeight: '24px',
              }}
            >
              <Box
                onClick={() => {
                  handlenavigateclose();
                }}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'Center',
                  cursor: 'pointer',
                  marginBottom: '20px',
                }}
              >
                <Box onClick={() => {}} className='alertCantinueButton'>
                  <img src={DrawerConinueIcon} className='primary_icons_dimension' alt='CONTINUE' />
                </Box>
                <Typography className='globalTextForDrawer' sx={{ marginLeft: '12px' }}>
                  CONTINUE
                </Typography>
              </Box>

              <Box
                onClick={handleClose}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'Center',
                  cursor: 'pointer',
                  paddingBottom: '16px',
                  paddingLeft: '15px',
                }}
              >
                <Box className='globalIconMainBox'>
                  <Box className='iconSecondaryButtonGlobalForDrawer'>
                    <img src={DrawerCancelIcon} alt='CANCELICON' className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                  </Box>
                  <Typography className='globalTextForDrawer'>CANCEL</Typography>
                </Box>
              </Box>
            </div>
          </div>
        </Alert>
      </Snackbar>

      <Box sx={{ flexGrow: 1, marginBottom: '30px' }} p={0}>
        <div align='center'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div style={{ width: '50%', margin: 'auto' }}>
              <ShowProposalName proposaldetails={proposaldetails} />
            </div>

            <img src={NoteMiscIcon} className='misc_icons_dimensions' onClick={() => openNotesDrawer()} />
          </div>

          <ThemeProvider theme={outerTheme}>
            <Box sx={{ marginTop: '20px' }}>
              {proposalNotesContent.map((note, index) => (
                <Box sx={{ display: 'flex', marginBottom: '0px' }}>
                  {note.visible ? <VisibilityIcon sx={{ color: '#008593', paddingRight: '10px' }} /> : null}{' '}
                  <Box>
                    <Typography className='' onClick={() => openNotesDrawer(note)}>
                      {' '}
                      <span
                        style={{
                          color: '#008593',
                          cursor: 'pointer',
                          fontWeight: 'bold',
                        }}
                      >
                        Note #{index + 1} :
                      </span>{' '}
                      {note.content}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </ThemeProvider>

          <div style={{ marginBottom: '30px', marginTop: '25px' }} align='center'>
            <ProposalsStepper
              windowDetails={{
                totalselectedwindows: location.state.totalselectedwindows,
                selectedwindows: location.state.selectedwindows,
                totalwindows: location.state.totalwindows,
              }}
              stepperData='ApplyCoveringsFilters'
            />
          </div>
        </div>

        <Grid container rowSpacing={2} p={0} direction='row'>
          <Grid
            item
            xs={0}
            sm={5}
            md={4}
            lg={3}
            sx={{
              height: '60vh',
              overflowX: 'scroll',
              padding: '0 10px',
              scrollbarWidth: 'none',
            }}
            display={{ xs: 'none', lg: 'block', md: 'block', sm: 'block' }}
          >
            <CoveringDrawer2 func2={fetchdata} data={filteredCoveringsDetails} />
          </Grid>
          {/* Grid Gap here instead */}
          <Grid item xs={0} sm={0.5} md={0.5} lg={0.25} display={{ xs: 'none', lg: 'block', md: 'block', sm: 'block' }}>
            &nbsp;
          </Grid>
          {/* Mobile  */}
          <Grid item xs={12} sm={6.5} md={7.5} lg={8.75}>
            <Box component='main' className='apply_covering_box'>
              <Drawer classes={{ paper: classes.paper }} anchor='right' open={isDrawerOpen} onClose={() => setIsDrawerOpen(true)} className='drawer_Header_page'>
                <CoveringDrawer func={fetchdata} data={filteredCoveringsDetails} />
              </Drawer>
              <ThemeProvider theme={theme1}>
                <Drawer
                  classes={{ paper: classes.paper }}
                  PaperProps={{
                    sx: { width: '90%' },
                  }}
                  BackdropProps={{
                    classes: {
                      root: classes.backDrop,
                    },
                  }}
                  anchor='right'
                  open={notesDrawerOpen}
                  onClose={() => setNotesDrawerOpen(false)}
                  className='drawer_Header_page'
                >
                  <NotesDrawer closeDrawer={closeNotesDrawer} editing={editingNote} type='proposal' details={noteDrawerDetails} />
                </Drawer>
              </ThemeProvider>
              <Grid
                item
                xs={12}
                sm={0}
                md={0}
                lg={0}
                display={{
                  xs: 'block',
                  lg: 'none',
                  md: 'none',
                  sm: 'none',
                }}
              >
                <Box>
                  <span className='gray_button_span button_top_space'>
                    <Box className='globalIconMainBox' onClick={reset}>
                      <img alt='CLEARFILTERS' className={`secondary_icons_dimension ${isImageHovered ? 'hovered' : ''}`} src={FilterIcon} />
                      <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover} onMouseLeave={handleHover}>
                        {' '}
                        CLEAR FILTERS
                      </Typography>
                    </Box>
                  </span>
                  <hr className='button_top_space button_bottom_space' />
                  <Box className='container small'>
                    <span className='button_span' onClick={() => setIsDrawerOpen(true)}>
                      <Box className='globalIconMainBox'>
                        <img alt='SELECTPRODUCTOPTIONS' src={ProposalIcon} className='primary_icons_dimension' />
                        <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                          {' '}
                          SELECT PRODUCT OPTIONS
                        </Typography>
                      </Box>
                    </span>
                  </Box>
                </Box>
              </Grid>

              {/* Window Details */}
              <WindowDetails windowDetails={windowDetails} />

              {/* Product Details */}
              <Box className='show_Sku_Box_Proposal_Apply_Covering_Page'>
                <Box className='show_Sku_Sub_Box_Proposal_Apply_Covering_Page'>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    display={{
                      xs: 'block',
                      lg: 'block',
                      md: 'block',
                      sm: 'block',
                    }}
                  >
                    <>
                      {WindowDepthValue === 'Other' || ObstructionsValue === 'Yes' || VerticalConfigurationValue || WindowConfigurationValue ? (
                        <Box>
                          <hr className='button_bottom_space button_top_space' />
                          <h3 style={{ marginBottom: '15px !important' }} className='proposal_result_header'>
                            Measurement Alerts :
                          </h3>{' '}
                        </Box>
                      ) : null}

                      {WindowDepthCount === 1 ? (
                        <>
                          {WindowDepthValue === 'Other' ? (
                            <Box className='cspMeasurementAlerts'>
                              <ErrorIcon sx={{ color: '#aa0d0d' }} />
                              <Typography className='cspMeasurementAlertsText'>
                                <b> A non-standard Window Depth was indicated on this window. Check the valance and return for compatibility.</b>
                              </Typography>
                            </Box>
                          ) : null}
                        </>
                      ) : (
                        <>
                          {WindowDepthValue === 'Other' ? (
                            <Box className='cspMeasurementAlerts'>
                              <ErrorIcon sx={{ color: '#aa0d0d' }} />
                              <Typography className='cspMeasurementAlertsText'>
                                <b> A non-standard Window Depth was selected on one or more windows. Check the valances and returns for compatibility.</b>
                              </Typography>
                            </Box>
                          ) : null}
                        </>
                      )}

                      {ObstructionsCount === 1 ? (
                        <>
                          {ObstructionsValue === 'Yes' ? (
                            <Box className='cspMeasurementAlerts'>
                              <ErrorIcon sx={{ color: '#aa0d0d' }} />
                              <Typography className='cspMeasurementAlertsText'>
                                <b> An Obstruction was indicated on this window. Check that any necessary cutout or modification is defined.</b>
                              </Typography>
                            </Box>
                          ) : null}
                        </>
                      ) : (
                        <>
                          {ObstructionsValue === 'Yes' ? (
                            <Box className='cspMeasurementAlerts'>
                              <ErrorIcon sx={{ color: '#aa0d0d' }} />
                              <Typography className='cspMeasurementAlertsText'>
                                <b> An Obstruction was indicated on one or more windows. Check that any necessary cutouts or modifications are defined.</b>
                              </Typography>
                            </Box>
                          ) : null}
                        </>
                      )}

                      {VerticalConfigurationValue ? (
                        <>
                          {!VerticalConfigurationCount ? (
                            <>
                              <Box className='cspMeasurementAlerts'>
                                <ErrorIcon sx={{ color: '#aa0d0d' }} />
                                <Typography className='cspMeasurementAlertsText'>
                                  <b> Windows with multiple sections have been indicated. Check that any necessary information is included.</b>
                                </Typography>
                              </Box>
                            </>
                          ) : (
                            <>
                              <Box className='cspMeasurementAlerts'>
                                <ErrorIcon sx={{ color: '#aa0d0d' }} />
                                <Typography className='cspMeasurementAlertsText'>
                                  <b> More than 1 section has been indicated for this window. Check that any necessary information is included.</b>
                                </Typography>
                              </Box>
                            </>
                          )}
                        </>
                      ) : null}
                    </>

                    <hr className='button_bottom_space button_top_space' />
                    <h3 className='proposal_result_header'>Covering System :</h3>

                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={{ xs: 0, sm: 0, md: 0 }} columns={2}>
                        <Grid item xs={2} sm={1}>
                          <Item>
                            {wcc ? (
                              <>
                                Category :{' '}
                                <Typography variant='h7' className='proposal_result_header'>
                                  {wcc}
                                </Typography>
                                <br />
                              </>
                            ) : null}
                            {brand ? (
                              <>
                                Brand :{' '}
                                <Typography variant='h7' className='proposal_result_header'>
                                  {brand}
                                </Typography>
                                <br />
                              </>
                            ) : null}
                            {label ? (
                              <>
                                Label :{' '}
                                <Typography variant='h7' className='proposal_result_header'>
                                  {label}
                                </Typography>
                                <br />
                              </>
                            ) : null}
                            {product ? (
                              <>
                                Product :{' '}
                                <Typography variant='h7' className='proposal_result_header'>
                                  {product}
                                </Typography>
                                <br />
                              </>
                            ) : null}
                            {type ? (
                              <>
                                Type :{' '}
                                <Typography variant='h7' className='proposal_result_header'>
                                  {type}
                                </Typography>
                                <br />
                              </>
                            ) : null}
                          </Item>
                        </Grid>
                        <Grid item xs={2} sm={1}>
                          <Item>
                            {fabric ? (
                              <>
                                Material :{' '}
                                <Typography variant='h7' className='proposal_result_header'>
                                  {fabric}
                                </Typography>
                                <br />
                              </>
                            ) : null}
                            {opacity ? (
                              <>
                                {wcc === 'Blinds' || wcc === 'Shutters' ? <>Louver Size : </> : <>Opacity : </>}

                                <Typography variant='h7' className='proposal_result_header'>
                                  {opacity}
                                </Typography>
                                <br />
                              </>
                            ) : null}
                            {lift ? (
                              <>
                                Lift System :{' '}
                                <Typography variant='h7' className='proposal_result_header'>
                                  {lift}
                                </Typography>
                                <br />
                              </>
                            ) : null}
                            {direction ? (
                              <>
                                Direction :{' '}
                                <Typography variant='h7' className='proposal_result_header'>
                                  {direction}
                                </Typography>
                                <br />
                              </>
                            ) : null}
                            {Object.keys(selectedOptionJSON).length
                              ? Object.entries(selectedOptionJSON).map(([key, value]) =>
                                  value.name !== ' ' ? (
                                    <>
                                      <span>{key} : </span>
                                      <Typography variant='h7' className='proposal_result_header'>
                                        {value.name}
                                      </Typography>
                                      <br />
                                    </>
                                  ) : null
                                )
                              : null}
                          </Item>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Box>
              </Box>

              <hr className='button_top_space button_bottom_space' />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '16px',
                }}
              >
                {confirmationforSO ? (
                  <span className='button_span padding_Continue_Button_Proposal_Apply_Covering_Page'>
                    <FormControlLabel
                      className='confirm-checkbox'
                      label={
                        <span
                          style={{
                            fontSize: '18px',
                            fontFamily: 'RobotoLight',
                            marginLeft: '8px',
                          }}
                        >
                          &nbsp;CONFIRM FOR SALES ORDER
                        </span>
                      }
                      control={
                        <Checkbox
                          sx={{ padding: '0px !important' }}
                          style={{
                            transform: 'scale(1.2)',
                            padding: '0px !important',
                          }}
                          checked={markAsConfirmedSalesOrderStatus}
                          onChange={(e) => {
                            setMarkAsConfirmSalesOrderStatus(e.target.checked);
                          }}
                        />
                      }
                    />
                  </span>
                ) : (
                  <span className='button_span_notes_disabled padding_Continue_Button_Proposal_Apply_Covering_Page'>
                    <FormControlLabel
                      className='confirm-checkbox'
                      label={
                        <span
                          style={{
                            fontSize: '18px',
                            fontFamily: 'RobotoLight',
                            marginLeft: '8px',
                          }}
                        >
                          &nbsp;CONFIRM FOR SALES ORDER
                        </span>
                      }
                      control={
                        <Checkbox
                          disabled
                          sx={{ padding: '0px !important' }}
                          style={{
                            transform: 'scale(1.2)',
                            padding: '0px !important',
                          }}
                          checked={markAsConfirmedSalesOrderStatus}
                        />
                      }
                    />
                  </span>
                )}
              </Box>
              <hr className='button_top_space button_bottom_space' />
              <Box className='continue_Button_Box_Proposal_Apply_Covering_Page '>
                <Button
                  onClick={applyProposalCoverings}
                  disable={!(SelectedFabricSKU && SelectedLiftSytemSKU && AllOptionSelectedStatus)}
                  onMouseEnter={handleHoverFun}
                  onMouseLeave={handleMouseLeaveFun}
                  className={`${!(SelectedFabricSKU && SelectedLiftSytemSKU && AllOptionSelectedStatus) ? 'button_span_disabled' : 'button_span'} padding_Continue_Button_Proposal_Apply_Covering_Page_1`}
                  sx={{ justifyContent: 'left', paddingLeft: 0 }}
                >
                  <Box className='globalIconMainBox globalMarginLeftAddWindow'>
                    <img
                      alt='Apply Covering'
                      className={!(SelectedFabricSKU && SelectedLiftSytemSKU && AllOptionSelectedStatus) ? 'primary_icons_dimension_disable' : 'primary_icons_dimension_1'}
                      src={isHovered && !(SelectedFabricSKU && SelectedLiftSytemSKU && AllOptionSelectedStatus) ? ApplyCoveringIcon : ApplyCoveringHovIcon}
                    />
                    <Typography sx={{ fontFamily: 'RobotoLight' }} className={!(SelectedFabricSKU && SelectedLiftSytemSKU && AllOptionSelectedStatus) ? 'globalTextDisabled' : 'globalText'}>
                      Apply Options
                    </Typography>
                  </Box>
                </Button>
                <Button onClick={send} onMouseEnter={handleHover2} onMouseLeave={handleHover2} className='gray_button_span' sx={{ justifyContent: 'left', paddingBottom: '30px', paddingLeft: '23px' }}>
                  <Box className='globalIconMainBox'>
                    <img alt='CANCEL' src={CancelIcon} className={`secondary_icons_dimension ${isImageHovered2 ? 'hovered' : ''}`} />
                    <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                      CANCEL
                    </Typography>
                  </Box>
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
