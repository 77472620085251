import { useReducer, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Drawer, TextField, Typography } from '@mui/material';
import { Circle as CircleIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import Header from './Header';
import Footer from './Footer';
import Dropdown from '../DropDown';
import AddOnList from '../AddOnList';
import DrawerSaveIcon from '../../../assets/icons/drawer-alert/drw-save.svg';
import InstallIcon from '../../../assets/icons/install.svg';
import NoInstallIcon from '../../../assets/icons/install-no.svg';
import MoneyIcon from '../../../assets/icons/money.svg';
import MoneyOffIcon from '../../../assets/icons/money-off.svg';
import AddIcon from '../../../assets/icons/drawer-alert/2drw-add.svg';
import DeleteIcon from '../../../assets/icons/trash button.svg';
import CONFIG from '../../../config/config.js';

const reducer = (state, { action, payload }) => {
  switch (action) {
    case 'SET_ALL_EXISTING_ADD_ONS':
      return { ...state, ...payload };
    case 'SET_ACCESSORIES':
      return {
        ...state,
        accessories: [...state.accessories, { ...payload, quantity: 1 }],
      };
    case 'SET_CUSTOM_COLORS':
      return {
        ...state,
        customColors: [...state.customColors, { ...payload, quantity: 1 }],
      };
    case 'SET_REPAIR_CHARGES':
      return {
        ...state,
        repairCharges: [...state.repairCharges, { ...payload, quantity: 1 }],
      };
    case 'SET_TRIP_CHARGE':
      return {
        ...state,
        tripCharges: [...state.tripCharges, { ...payload, quantity: 1 }],
      };
    case 'UPDATE_ACCESSORY':
      return {
        ...state,
        accessories: state.accessories.map((accessory) => {
          if (accessory.sku === payload.sku) {
            return payload;
          }
          return accessory;
        }),
      };
    case 'UPDATE_CUSTOM_COLOR':
      return {
        ...state,
        customColors: state.customColors.map((color) => {
          if (color.sku === payload.sku) {
            return payload;
          }
          return color;
        }),
      };
    case 'UPDATE_REPAIR_CHARGE':
      return {
        ...state,
        repairCharges: state.repairCharges.map((charge) => {
          if (charge.sku === payload.sku) {
            return payload;
          }
          return charge;
        }),
      };
    case 'UPDATE_TRIP_CHARGE':
      return {
        ...state,
        tripCharges: state.tripCharges.map((charge) => {
          if (charge.sku === payload.sku) {
            return payload;
          }
          return charge;
        }),
      };
    case 'REMOVE_ACCESSORY':
      return {
        ...state,
        accessories: state.accessories.filter(({ sku }) => sku !== payload.sku),
      };
    case 'REMOVE_CUSTOM_COLOR':
      return {
        ...state,
        customColors: state.customColors.filter(({ sku }) => sku !== payload.sku),
      };
    case 'REMOVE_REPAIR_CHARGE':
      return {
        ...state,
        repairCharges: state.repairCharges.filter(({ sku }) => sku !== payload.sku),
      };
    case 'REMOVE_TRIP_CHARGE':
      return {
        ...state,
        tripCharges: state.tripCharges.filter(({ sku }) => sku !== payload.sku),
      };
    default:
      return state;
  }
};

const useStyles = makeStyles({
  paper: {
    width: '90%',
  },
});

export default function AddOnDrawer({ isOpen, close, proposalId = '' }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [addOns, dispatch] = useReducer(reducer, {
    accessories: [],
    customColors: [],
    repairCharges: [],
    tripCharges: [],
  });
  const [options, setOptions] = useState({
    accessories: [],
    tripCharges: [],
    customColors: [],
    repairCharges: [],
  });
  const [requestInstall, setRequestInstall] = useState(true);
  const [additionalCharges, setAdditionalCharges] = useState([]);

  const getProposalByProposalId = async () => {
    const token = sessionStorage.getItem('token');
    const requestOptions = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    };
    // Created Proposal will be added to the database through CreateProposal API
    const response = await fetch(`${CONFIG.API_URL}/pm/ProposalByProposalId/${proposalId}`, requestOptions);
    const { error, ...data } = await response.json();
    if (!response.ok || error) {
      if (error.message === 'Token is not valid') {
        localStorage.removeItem('token');
        navigate('/login');
      }
      return;
    }
    setRequestInstall(data.proposal.install_requested);
    setAdditionalCharges(data.proposal_level_accessory_service_charges.filter((charge) => charge.charge_type === 'AD'));

    getOptions(
      data.proposal_details
        .map(({ sku_details }) => sku_details)
        .flat()
        .map(({ label }) => label)
        .filter((value, index, self) => self.indexOf(value) === index)
    );
  };

  const getOptions = async (labels) => {
    const token = sessionStorage.getItem('token');
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        proposal_id: proposalId,
        label: labels,
      }),
    };
    const response = await fetch(`${CONFIG.API_URL}/pm/GetAccessoryServiceList`, requestOptions);
    const { error, error_message, AccessoryList, ServiceList, ExistingAccessoryList, ExistingServiceList, ...data } = await response.json();
    if (!response.ok || error) {
      if (error.message === 'Token is not valid') {
        localStorage.removeItem('token');
        navigate('/login');
      }
      return;
    }

    const accessories = AccessoryList.map((accessory) => ({ ...accessory, type: 'Accessory', dollar_retail_charge: accessory.dollar_retail_surcharge }));
    const customColors = ServiceList.filter(({ type }) => type === 'Custom Color');
    const tripCharges = ServiceList.filter(({ type }) => type === 'Trip Charge');
    const repairCharges = ServiceList.filter(({ type }) => type === 'Repair Charge');

    setOptions({
      accessories,
      tripCharges,
      customColors,
      repairCharges,
    });

    dispatch({
      action: 'SET_ALL_EXISTING_ADD_ONS',
      payload: {
        accessories: ExistingAccessoryList.map((accessory) => ({ ...accessory, type: 'Accessory', dollar_retail_charge: accessory.dollar_retail_surcharge })),
        customColors: ExistingServiceList.filter(({ type }) => type === 'Custom Color'),
        tripCharges: ExistingServiceList.filter(({ type }) => type === 'Trip Charge'),
        repairCharges: ExistingServiceList.filter(({ type }) => type === 'Repair Charge'),
      },
    });
  };

  const addCharge = ({ value }, i) => {
    const newCharges = [...additionalCharges];
    const isValidInput = (value) => {
      const regex = /^(?!.*\..*\.)[0-9]*(\.[0-9]{0,2})?$/;
      return regex.test(value);
    };
    if (isValidInput(value)) {
      newCharges[i].base_charge = value;
      setAdditionalCharges(newCharges);
    }
  };

  const handleAdditionalCharges = (action) => {
    if (action === 'TOGGLE' && additionalCharges.length) {
      return setAdditionalCharges([]);
    }
    return setAdditionalCharges((prev) => [...prev, { charge_description: '', base_charge: null }]);
  };

  const save = async () => {
    const token = sessionStorage.getItem('token');
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        proposal_id: proposalId,
        accessories: addOns.accessories,
        custom_colors: addOns.customColors,
        repair_charges: addOns.repairCharges,
        trip_charges: addOns.tripCharges,
        additional_charge_option: additionalCharges.length > 0,
        additional_data: additionalCharges.filter(({ charge_description }) => charge_description !== '').map((charge, i) => ({ ...charge, sku: `AD${i.toString().padStart(8, '0')}` })),
        install_requested: requestInstall,
      }),
    };
    const response = await fetch(`${CONFIG.API_URL}/pm/UpdateProposalAccessoriesServices`, requestOptions);
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        localStorage.removeItem('token');
        navigate('/login');
      }
      return close(false);
    }

    close(true);
  };

  useEffect(() => {
    getProposalByProposalId();
  }, [proposalId]);

  return (
    <Drawer
      PaperProps={{
        sx: {
          backdropFilter: 'blur(10px)',
          background: 'rgba(0, 133, 147, 0.73)',
          width: { md: '50vw', xs: '90vw' },
        },
      }}
      slotProps={{
        backdrop: {
          classes: {
            root: classes.backDrop,
          },
        },
      }}
      anchor='right'
      open={isOpen}
      onClose={close}
      sx={{ zIndex: '1000000' }}
    >
      <Container sx={{ sm: false, lg: false, padding: { sm: '0 90px', md: '0 90px' }, paddingRight: { lg: '120px' } }}>
        <Header closeDrawer={close} />
        <Container>
          <Box onClick={() => setRequestInstall(!requestInstall)} className='common_display_flex_css button_top_space button_bottom_space'>
            <Box>
              {!requestInstall ? (
                <Box className='switch_icon_before'>
                  <CircleIcon className='cursor notes_color' />
                  <img alt='Apply Measurement' src={NoInstallIcon} className='toggle_icon' />
                </Box>
              ) : (
                <Box className='switch_icon_after'>
                  <img alt='Apply Measurement' src={InstallIcon} className='toggle_icon' />
                  <CircleIcon className='cursor notes_color' />
                </Box>
              )}
            </Box>
            <Box sx={{ color: 'white', marginLeft: '10px' }}>
              <Typography>Install Requested</Typography>
            </Box>
          </Box>

          <hr className='button_top_space button_bottom_space' />
          <Container>
            {options.accessories.length ? (
              <>
                <Dropdown options={options.accessories.filter(({ sku }) => !addOns.accessories.some((fee) => sku === fee.sku))} onChange={dispatch} label='Accessories' sx={{ zIndex: 1000000, width: '100%' }} type={'accessory_name'} />
                <AddOnList addOns={addOns.accessories} updateAddOns={dispatch} />
              </>
            ) : null}
            {options.customColors.length ? (
              <>
                <Dropdown options={options.customColors.filter(({ sku }) => !addOns.customColors.some((fee) => sku === fee.sku))} onChange={dispatch} label='Custom Colors' sx={{ zIndex: 1000000, width: '100%' }} />
                <AddOnList addOns={addOns.customColors} updateAddOns={dispatch} />
              </>
            ) : null}
            {options.repairCharges.length ? (
              <>
                <Dropdown options={options.repairCharges.filter(({ sku }) => !addOns.repairCharges.some((fee) => sku === fee.sku))} onChange={dispatch} label='Repair Charges' sx={{ zIndex: 1000000, width: '100%' }} />
                <AddOnList addOns={addOns.repairCharges} updateAddOns={dispatch} />
              </>
            ) : null}

            {options.tripCharges.length ? (
              <>
                <Dropdown sx={{ zIndex: 1000000, width: '100%' }} options={options.tripCharges.filter(({ sku }) => !addOns.tripCharges.some((fee) => sku === fee.sku))} onChange={dispatch} label='Trip Charge' />
                <AddOnList addOns={addOns.tripCharges} updateAddOns={dispatch} />
              </>
            ) : null}
          </Container>

          <hr className='button_top_space button_bottom_space' />

          <Box onClick={() => handleAdditionalCharges('TOGGLE')} className='common_display_flex_css button_top_space'>
            <Box>
              {!additionalCharges.length ? (
                <Box className='switch_icon_before'>
                  <CircleIcon className='cursor notes_color' />
                  <img alt='Apply Measurement' src={MoneyOffIcon} className='toggle_icon' />
                </Box>
              ) : (
                <Box className='switch_icon_after'>
                  <img alt='Apply Measurement' src={MoneyIcon} className='toggle_icon' />
                  <CircleIcon className='cursor notes_color' />
                </Box>
              )}
            </Box>
            <Box sx={{ color: 'white', marginLeft: '10px' }}>
              <Typography>Additional Charges</Typography>
            </Box>
            ``
          </Box>
          {additionalCharges.length ? (
            <Container>
              {additionalCharges.map((charge, index) => (
                <Container key={index} sx={{ display: 'flex', alignItems: { md: 'center', sm: 'flex-start' }, flexFlow: { xs: 'column', xl: 'row' } }} className='button_top_space'>
                  <TextField
                    type='text'
                    sx={{ width: '100%' }}
                    value={charge.charge_description}
                    onChange={(e) => {
                      const newCharges = [...additionalCharges];
                      newCharges[index].charge_description = e.target.value;
                      setAdditionalCharges(newCharges);
                    }}
                    placeholder='Description'
                    className='input_field'
                  />
                  <Container sx={{ marginTop: '1em', marginRight: 0, display: 'flex', color: 'white', flexFlow: 'row', alignItems: 'center', justifyContent: { sm: 'right' }, paddingLeft: 0, paddingRight: 0 }}>
                    ${' '}
                    <TextField
                      type='number'
                      sx={{
                        width: '8em',
                        marginLeft: '.5em',
                        marginRight: '1em',
                      }}
                      value={charge.base_charge}
                      onChange={({ target }) => addCharge(target, index)}
                      placeholder='0.00'
                      className='input_field'
                    />
                    <img src={DeleteIcon} onClick={() => setAdditionalCharges(additionalCharges.filter((_, i) => i !== index))} className='drawer_secondary_icons_dimension' alt='Delete Additional Charge' />
                  </Container>
                </Container>
              ))}
            </Container>
          ) : null}

          {additionalCharges.length ? (
            <Container sx={{ display: 'flex', flexDirection: 'column', paddingTop: '20px' }}>
              <Button onClick={handleAdditionalCharges} sx={{ justifyContent: 'left', marginLeft: '15px' }}>
                <img src={AddIcon} className='drawer_secondary_icons_dimension' alt='Add Additional Charge' />
                <Typography
                  sx={{
                    marginLeft: '30px',
                    fontFamily: 'RobotoLight',
                  }}
                  className='drawerGlobalText'
                >
                  ADD ANOTHER CHARGE
                </Typography>
              </Button>
            </Container>
          ) : null}

          <hr className='button_top_space button_bottom_space' />
        </Container>
        <Footer cancel={close}>
          <Button onClick={save} sx={{ justifyContent: 'left' }}>
            <img alt='Apply Measurement' src={DrawerSaveIcon} className='primary_icons_dimension' />
            <Typography
              sx={{
                width: 'content-fit',
                marginLeft: '30px',
                fontFamily: 'RobotoLight',
                textAlign: 'start',
              }}
              className='drawerGlobalText'
            >
              SAVE
            </Typography>
          </Button>
        </Footer>
      </Container>
    </Drawer>
  );
}
