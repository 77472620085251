import CONFIG from '../../config/config.js';

import React, { useEffect, useState, useContext } from 'react';
import { Button, Container, Typography, TableContainer, TableBody, Tooltip, Zoom } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import fracty from 'fracty';

import ProductGridViewIcon from '../../assets/icons/secondary/productGridView.svg';
import LongText from '../LongText';
import Table from '../Shared/Table';
import { RollerShades, Shutters, Blinds, SoftShades } from './SalesOrderProductTables';
import ProductionStatus from './ProductionStatus';
import { WorkOrderContext } from '../Shared/WorkOrderProvider';

export default function WorkOrderDetails({}) {
  const location = useLocation();
  const { workOrderDetails } = useContext(WorkOrderContext);
  const [workOrder, setWorkOrder] = useState({
    salesOrder: {},
    details: {},
  });
  const [orders, setOrders] = useState([]);
  const [shutterStatus, setShutterStatus] = useState({});
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [openWindowDetails, setOpenWindowDetails] = useState(false);
  const [openProductGridView, setOpenProductGridView] = useState(false);

  const getSaleOrderWindows = async () => {
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${CONFIG.API_URL}/so/salesorderdetails/${location.state.Custdata.Sales_Order_Id}`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      return console.error(`Unable to fetch sales order details: ${data.error}`);
    }
    setWorkOrder({
      salesOrder: data.SalesOrder,
      details: data.SalesOrderDetails,
    });
  };

  const getWorkOrderDetails = async () => {
    const token = sessionStorage.getItem('token');
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    };

    const response = await fetch(`${CONFIG.API_URL}/pm/WorkOrderDetails/${location.state.Custdata.Invoice}`, requestOptions);
    const data = await response.json();
    if (!response.ok || data.error) {
      return console.error(data.error);
    }
    setOrders([...data.ordersB_data, ...data.ordersS_data]);
    setShutterStatus(data.shuttersStatus);
  };

  useEffect(() => {
    getSaleOrderWindows();
    getWorkOrderDetails();
  }, [location.state.Custdata.Sales_Order_Id]);

  return (
    <>
      <Button
        onClick={() => setOpenWindowDetails(!openWindowDetails)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          display: 'flex',
          contentAlign: 'center',
          justifyContent: 'left',
          paddingLeft: '1.5em',
        }}
      >
        <img alt='Product Grid View' src={ProductGridViewIcon} className={`secondary_icons_dimension ${isHovered ? 'hovered' : null}`} />
        <Typography sx={{ fontFamily: 'RobotoLight' }} className={`globalText ${isHovered ? 'globalTextDecoration' : ''}`}>
          {' '}
          Window Details
        </Typography>
      </Button>
      {openWindowDetails ? <WindowDetails windows={workOrder.details} /> : null}

      <hr className='button_top_space button_bottom_space' />

      <Button
        onClick={() => setOpenProductGridView(!openProductGridView)}
        onMouseEnter={() => setIsHovered2(true)}
        onMouseLeave={() => setIsHovered2(false)}
        sx={{
          display: 'flex',
          contentAlign: 'center',
          justifyContent: 'left',
          paddingLeft: '1.5em',
        }}
      >
        <img alt='Product Grid View' src={ProductGridViewIcon} className={`secondary_icons_dimension ${isHovered2 ? 'hovered' : null}`} />
        <Typography sx={{ fontFamily: 'RobotoLight' }} className={`globalText ${isHovered2 ? 'globalTextDecoration' : ''}`}>
          {' '}
          Product Grid View
        </Typography>
      </Button>

      {openProductGridView ? <ProductGridView windows={workOrder.details} /> : null}
      <hr className='button_top_space button_bottom_space' />

      <AssemblyDetails softShades={workOrderDetails.softShades} rollerShades={workOrderDetails.rollerShades} blinds={workOrderDetails.blinds} shutters={workOrderDetails.shutters} additionalItems={workOrder.additionalItems} />
    </>
  );
}

function formatLabel(key) {
  if (key === 'name') {
    return (
      <>
        <span style={{ paddingRight: '30px' }}>#</span>
        <span>Name</span>
      </>
    );
  }
  const words = key.replace(/([a-z])([A-Z])/g, '$1 $2').split(/(?=[A-Z])/);
  return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}
const TurquoiseTooltipforNotes = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#faf3dd',
    color: '#000000',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 30,
    paddingRight: 30,
    maxWidth: 300,
    maxHeight: 300,
    textAlign: 'left',
    align: 'left',
    border: '1px solid #000000',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    '&::before': {
      backgroundColor: '#f2f3f2',
      border: '1px solid #000000',
    },
    top: '100%', // Position the arrow at the bottom of the tooltip box
    right: '100', // Position the arrow at the right side of the tooltip box
  },
}));

export function WindowDetails({ windows, ...props }) {
  const rows = windows.map((window) => {
    const { sales_order_measurement_type_specific_factor, sales_order_measurement_type } = window;
    const measurementType =
      sales_order_measurement_type === 'Quick'
        ? '(Q)'
        : sales_order_measurement_type === 'Shutters'
        ? '(SH)'
        : sales_order_measurement_type === 'Blinds'
        ? '(BL)'
        : `(${sales_order_measurement_type
            .split(' ')
            .map((word) => word[0])
            .join('')})`;

    return {
      id: window.window_id,
      name: (
        <>
          <span
            style={{
              paddingRight: +window.item_sequence + 1 > 9 ? '20px' : '30px',
            }}
          >
            {+window.item_sequence + 1}
          </span>
          <LongText>{window.window_name}</LongText>
        </>
      ),
      size: (
        <>
          {`${fracty(window.sales_order_window_width)}" X ${fracty(window.sales_order_window_height)}"`} <span className={sales_order_measurement_type.replace(/ /g, '')}>{measurementType}</span>
        </>
      ),
      category: window.covering_type,
      retail: `$${parseFloat(window.retail_price).toFixed(2)}`,
      install: `$${(parseFloat(window.covering_install_price) + parseFloat(window.sales_order_additional_price)).toFixed(2)}`,
      total: `$${parseFloat(window.total_covering_price_with_install).toFixed(2)}`,
      build: window.build_type,
      depth: sales_order_measurement_type_specific_factor.WindowDepth,
      mount: window.mount_surface,
      obst: sales_order_measurement_type_specific_factor.Obstructions !== 'None' ? sales_order_measurement_type_specific_factor.Description : 'None',
      ladder: window.ladder,
      exstCov: window.existing_covering,
      notes: window.window_notes?.length ? (
        <TurquoiseTooltipforNotes
          title={
            <Typography>
              {window.window_notes.map((note, index) => (
                <div key={note.id}>
                  {index > 0 && <br />}
                  <b>Note:</b> {note.content}
                </div>
              ))}
            </Typography>
          }
          placement='bottom-end'
          TransitionComponent={Zoom}
          arrow
        >
          <Typography className={'link ellipsis'}>{window.notes_count}</Typography>
        </TurquoiseTooltipforNotes>
      ) : (
        '- -'
      ),
    };
  });
  const columns = [...new Set(rows.flatMap((obj) => Object.keys(obj)))].map((key) => ({
    label: formatLabel(key),
    align: 'center',
    dataKey: key,
  }));

  return (
    <TableContainer {...props}>
      <Table.Head columns={columns} />
      <TableBody>
        {rows.map((row) => (
          <Table.Row key={row.window_id} columns={columns} window={row} />
        ))}
      </TableBody>
    </TableContainer>
  );
}

export function ProductGridView({ windows, ...props }) {
  const softShades = windows.filter((window) => window.covering_type === 'Soft Shades');
  const shutters = windows.filter((window) => window.covering_type === 'Shutters');
  const blinds = windows.filter((window) => window.covering_type === 'Blinds');
  const rollerShades = windows.filter((window) => window.covering_type === 'Roller Shades');

  return (
    <Container>
      {rollerShades.length ? <RollerShades windows={rollerShades} {...props} /> : null}
      {shutters.length ? <Shutters windows={shutters} {...props} /> : null}
      {blinds.length ? <Blinds windows={blinds} {...props} /> : null}
      {softShades.length ? <SoftShades windows={softShades} {...props} /> : null}
    </Container>
  );
}

export function AssemblyDetails({ softShades, rollerShades, blinds, shutters, additionalItems, ...props }) {
  const { workOrderDetails } = useContext(WorkOrderContext);
  return (
    <>
      {workOrderDetails.blinds.length ? (
        <ProductionStatus
          title='Assembly Details - Blinds'
          windows={blinds.map((window) => ({
            coveringType: (
              <>
                <span
                  style={{
                    paddingRight: +window.Number > 9 ? '20px' : '30px',
                  }}
                >
                  {+window.Number}
                </span>
                <LongText>{window.CoveringType}</LongText>
              </>
            ),
            Checked: window.Checked,
            CheckedBy: window.CheckedBy,
            CheckedDate: window.CheckedDate,
            HeadCut: window.HeadCutDate,
            HeadGut: window.HeadGutDate,
            HeadCord: window.HeadCordDate,
            MatCut: window.MatCutDate,
            MatPunch: window.MatPunchDate,
            CordCut: window.CordCutDate,
            Assembled: window.AssemDate,
          }))}
          {...props}
        />
      ) : null}
      {workOrderDetails.softShades.length ? (
        <ProductionStatus
          title='Assembly Details - Soft Shades'
          windows={softShades.map((window) => ({
            coveringType: (
              <>
                <span
                  style={{
                    paddingRight: +window.Number > 9 ? '20px' : '30px',
                  }}
                >
                  {+window.Number}
                </span>
                <LongText>{window.CoveringType}</LongText>
              </>
            ),
            Checked: window.Checked,
            CheckedBy: window.CheckedBy,
            CheckedDate: window.CheckedDate,
            HeadCut: window.HeadCutDate,
            HeadGut: window.HeadGutDate,
            HeadCord: window.HeadCordDate,
            MatCut: window.MatCutDate,
            MatPunch: window.MatPunchDate,
            CordCut: window.CordCutDate,
            Assembled: window.AssemDate,
          }))}
          {...props}
        />
      ) : null}
      {workOrderDetails.rollerShades.length ? (
        <ProductionStatus
          title='Assembly Details - Roller Shades'
          windows={rollerShades.map((window) => ({
            coveringType: (
              <>
                <span
                  style={{
                    paddingRight: +window.Number > 9 ? '20px' : '30px',
                  }}
                >
                  {+window.Number}
                </span>
                <LongText>{window.CoveringType}</LongText>
              </>
            ),
            Checked: window.Checked,
            CheckedBy: window.CheckedBy,
            CheckedDate: window.CheckedDate,
            HeadCut: window.HeadCutDate,
            HeadGut: window.HeadGutDate,
            HeadCord: window.HeadCordDate,
            MatCut: window.MatCutDate,
            MatPunch: window.MatPunchDate,
            CordCut: window.CordCutDate,
            Assembled: window.AssemDate,
          }))}
          {...props}
        />
      ) : null}
      {workOrderDetails.shutters.length ? (
        <ProductionStatus
          title='Assembly Details - Shutters'
          windows={workOrderDetails.shutters.map((window) => ({
            coveringType: (
              <>
                <span
                  style={{
                    paddingRight: +window.Number > 9 ? '20px' : '30px',
                  }}
                >
                  {+window.Number}
                </span>
                <LongText>{window.CoveringType}</LongText>
              </>
            ),
            Checked: window.Checked,
            CheckedBy: window.CheckedBy,
            RemBy: window?.RemBy,
            Remeasured: window?.RemeasuredDate,
            Designed: window?.Designed,
            DesignedBy: window?.DesignedBy,
            DesignedDate: window?.DesignedDate,
            Production: window?.ProductionDate,
            Cut: window?.CutDate,
            Assembled: window?.AssembleDate,
            Primed: window?.PrimeDate,
            Sanded: window?.SandDate,
            Painted: window?.PainDate,
            Cutout: window?.CutoutDate,
            Psqft: window?.Psqft,
            PDate: window?.PDate,
          }))}
          {...props}
        />
      ) : null}
      {workOrderDetails.additionalItems.length ? (
        <ProductionStatus
          title='Additional Charges'
          windows={workOrderDetails.additionalItems
            .sort((a, b) => a.Number - b.Number)
            .map((item) => ({
              name: (
                <>
                  <span
                    style={{
                      paddingRight: +item.Number > 9 ? '20px' : '30px',
                    }}
                  >
                    {+item.Number}
                  </span>

                  <LongText>{item.Location}</LongText>
                </>
              ),
              price: `$${+item.Price > 0 ? (+item.Price).toFixed(2) : (0.0).toFixed(2)}`,
            }))}
          {...props}
        />
      ) : null}
    </>
  );
}
