/** @format */

import React from 'react';
import pic from '../img/1.png';
import CONFIG from '../config/config.js';
import { useNavigate } from 'react-router-dom';
const SystemInformation = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    getVerified();
  }, []);
  // Get Token
  const token = sessionStorage.getItem('token');
  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };

  return (
    <div align='center'>
      <h2>TBM NextGen SALES App</h2>

      <img
        src={pic}
        style={{
          width: '250px',
          height: '250px',
        }}
        alt='theBlindman'
      />
      <br />

      <h2 align='center'>
        <b>Environment:</b>
        <p>TBM QA Environment </p>
        <b>App Version:</b>
        <span> {CONFIG.appVersion}</span>
        <br />

        <b>Build: </b>
        <span>{CONFIG.buildNumber}</span>
        <br />
        <b>App Name: </b>
        <span>{CONFIG.appCodename}</span>
      </h2>
      <br />
    </div>
  );
};

export default SystemInformation;
