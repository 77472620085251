const { REACT_APP_BASE_URI, REACT_APP_BASE_API_URI } = process.env;

export default {
  URL: REACT_APP_BASE_URI,
  API_URL: REACT_APP_BASE_API_URI,
  appVersion: '8.0.0',
  appReleasedDate: 'Nov 06, 2023',
  buildNumber: '#110623.00',
  appCodename: 'Venus',
  WindowsManagement: {
    WindowSetLimit: 5,
    RowperPageLimit: -1,
  },
  GlobalTruncateCharacterLimit: 30,
};
