import React from 'react';
import { Link } from 'react-router-dom';
import fracty from 'fracty';
import { TableContainer, TableHead, Table, TableBody, TableRow, TableCell, Typography } from '@mui/material';
import LongText from '../LongText';

function formatLabel(key) {
  if (key === 'name') {
    return (
      <>
        <span style={{ paddingRight: '30px' }}>#</span>
        <span>Name</span>
      </>
    );
  }
  const words = key.replace(/([a-z])([A-Z])/g, '$1 $2').split(/(?=[A-Z])/);
  return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export function Head({ columns }) {
  return (
    <TableHead>
      <TableRow sx={{ margin: 0, fontWeight: 'bold' }}>
        {columns.map((column) =>
          column.dataKey !== 'id' ? (
            <TableCell
              key={column.dataKey}
              className={`${column.dataKey === 'name' ? 'table-headerps sticky-columnws-2' : 'table-headerpd_size'} font_weight_bold`}
              variant='head'
              style={{ width: column.width }}
              sx={{
                textAlign: 'left',
                whiteSpace: 'nowrap',
              }}
            >
              {column.label}
            </TableCell>
          ) : null
        )}
      </TableRow>
    </TableHead>
  );
}

export function Row({ columns, window }) {
  return (
    <TableRow hover role='checkbox' tabIndex={-1} key={window.code}>
      {columns.map((column) =>
        column.dataKey !== 'id' ? (
          <TableCell key={column.id} sx={{ whiteSpace: 'nowrap' }} className={column.dataKey === 'name' ? 'table-contentid sticky-columnws-2' : 'table-content-after-material'} align={'left'}>
            {window[column.dataKey]}
          </TableCell>
        ) : null
      )}
    </TableRow>
  );
}

export function RollerShades({ windows }) {
  const rows = windows.map((window) => {
    const { sales_order_measurement_type, sku_details } = window;
    const measurementType =
      sales_order_measurement_type === 'Quick'
        ? '(Q)'
        : sales_order_measurement_type === 'Shutters'
        ? '(SH)'
        : sales_order_measurement_type === 'Blinds'
        ? '(BL)'
        : '(' +
          sales_order_measurement_type
            .split(' ')
            .map((word) => word[0])
            .join('') +
          ')';

    const base = {
      id: window.window_id,
      name: (
        <>
          <span style={{ paddingRight: +window.item_sequence + 1 > 9 ? '20px' : '30px' }}>{+window.item_sequence + 1}</span>
          <LongText>{window.window_name}</LongText>
        </>
      ),
      size: (
        <>
          {`${fracty(window.sales_order_window_width)}" X ${fracty(window.sales_order_window_height)}"`} <span className={sales_order_measurement_type.replace(/ /g, '')}>{measurementType}</span>
        </>
      ),
    };

    const materialData = sku_details.filter((sku) => sku.sku_type === 'M')[0];
    const materials = {
      brand: materialData.brand,
      label: materialData.label,
      product: materialData.product,
      type: materialData.type,
      fabric: materialData.fabric,
      opacity: materialData.opacity,
    };

    const liftSystemData = sku_details.filter((sku) => sku.sku_type === 'L')[0];
    const liftSystem = {
      lift: liftSystemData.sku_name,
      direction: liftSystemData.type,
    };

    const optionsData = sku_details.filter((sku) => sku.sku_type === 'P');
    return optionsData
      .map(({ type, sku_name }) => ({ [type]: sku_name }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {
        ...base,
        ...materials,
        ...liftSystem,
      });
  });

  const columns = [...new Set(rows.flatMap((obj) => Object.keys(obj)))].map((key) => ({
    label: formatLabel(key),
    align: 'center',
    dataKey: key,
  }));

  return (
    <>
      <Typography variant='h6' sx={{ paddingTop: '1em', paddingBottom: '1em' }}>
        Roller Shade
      </Typography>
      <TableContainer>
        <Table>
          <Head columns={columns} />
          <TableBody>
            {rows.map((window) => (
              <Row columns={columns} window={window} key={window.window_id} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export function Shutters({ windows }) {
  const rows = windows.map((window) => {
    const { sales_order_measurement_type, sku_details } = window;
    const measurementType =
      sales_order_measurement_type === 'Quick'
        ? '(Q)'
        : sales_order_measurement_type === 'Shutters'
        ? '(SH)'
        : sales_order_measurement_type === 'Blinds'
        ? '(BL)'
        : '(' +
          sales_order_measurement_type
            .split(' ')
            .map((word) => word[0])
            .join('') +
          ')';

    const base = {
      id: window.window_id,
      name: (
        <>
          <span style={{ paddingRight: +window.item_sequence + 1 > 9 ? '20px' : '30px' }}>{+window.item_sequence + 1}</span>
          <LongText>{window.window_name}</LongText>
        </>
      ),
      size: (
        <>
          {`${fracty(window.sales_order_window_width)}" X ${fracty(window.sales_order_window_height)}"`} <span className={sales_order_measurement_type.replace(/ /g, '')}>{measurementType}</span>
        </>
      ),
    };

    const materialData = sku_details.filter((sku) => sku.sku_type === 'M')[0];
    const materials = {
      brand: materialData.brand,
      label: materialData.label,
      product: materialData.product,
      type: materialData.type,
      fabric: materialData.fabric,
      opacity: materialData.opacity,
    };

    const liftSystemData = sku_details.filter((sku) => sku.sku_type === 'L')[0];
    const liftSystem = {
      lift: liftSystemData.sku_name,
      direction: liftSystemData.type,
    };

    const optionsData = sku_details.filter((sku) => sku.sku_type === 'P');
    return optionsData
      .map(({ type, sku_name }) => ({ [type]: sku_name }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {
        ...base,
        ...materials,
        ...liftSystem,
      });
  });

  const columns = [...new Set(rows.flatMap((obj) => Object.keys(obj)))].map((key) => ({
    label: formatLabel(key),
    align: 'center',
    dataKey: key,
  }));

  return (
    <>
      <Typography variant='h6' sx={{ paddingTop: '1em', paddingBottom: '1em' }}>
        Shutters
      </Typography>
      <TableContainer>
        <Table>
          <Head columns={columns} />
          <TableBody>
            {rows.map((window, index) => (
              <Row columns={columns} window={window} key={index} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export function Blinds({ windows }) {
  const rows = windows.map((window) => {
    const { sales_order_measurement_type, sku_details } = window;
    const measurementType =
      sales_order_measurement_type === 'Quick'
        ? '(Q)'
        : sales_order_measurement_type === 'Shutters'
        ? '(SH)'
        : sales_order_measurement_type === 'Blinds'
        ? '(BL)'
        : '(' +
          sales_order_measurement_type
            .split(' ')
            .map((word) => word[0])
            .join('') +
          ')';

    const base = {
      id: window.window_id,
      name: (
        <>
          <span style={{ paddingRight: +window.item_sequence + 1 > 9 ? '20px' : '30px' }}>{+window.item_sequence + 1}</span>
          <LongText>{window.window_name}</LongText>
        </>
      ),
      size: (
        <>
          {`${fracty(window.sales_order_window_width)}" X ${fracty(window.sales_order_window_height)}"`} <span className={sales_order_measurement_type.replace(/ /g, '')}>{measurementType}</span>
        </>
      ),
    };

    const materialData = sku_details.filter((sku) => sku.sku_type === 'M')[0];
    const materials = {
      brand: materialData.brand,
      label: materialData.label,
      product: materialData.product,
      type: materialData.type,
      fabric: materialData.fabric,
      opacity: materialData.opacity,
    };

    const liftSystemData = sku_details.filter((sku) => sku.sku_type === 'L')[0];
    const liftSystem = {
      lift: liftSystemData.sku_name,
      direction: liftSystemData.type,
    };

    const optionsData = sku_details.filter((sku) => sku.sku_type === 'P');
    return optionsData
      .map(({ type, sku_name }) => ({ [type]: sku_name }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {
        ...base,
        ...materials,
        ...liftSystem,
      });
  });

  const columns = [...new Set(rows.flatMap((obj) => Object.keys(obj)))].map((key) => ({
    label: formatLabel(key),
    align: 'center',
    dataKey: key,
  }));

  return (
    <>
      <Typography variant='h6' sx={{ paddingTop: '1em', paddingBottom: '1em' }}>
        Blinds
      </Typography>
      <TableContainer>
        <Table>
          <Head columns={columns} />
          <TableBody>
            {rows.map((window, index) => (
              <Row columns={columns} window={window} key={index} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export function SoftShades({ windows }) {
  const rows = windows.map((window) => {
    const { sales_order_measurement_type, sku_details } = window;
    const measurementType =
      sales_order_measurement_type === 'Quick'
        ? '(Q)'
        : sales_order_measurement_type === 'Shutters'
        ? '(SH)'
        : sales_order_measurement_type === 'Blinds'
        ? '(BL)'
        : '(' +
          sales_order_measurement_type
            .split(' ')
            .map((word) => word[0])
            .join('') +
          ')';

    const base = {
      id: window.window_id,
      name: (
        <>
          <span style={{ paddingRight: +window.item_sequence + 1 > 9 ? '20px' : '30px' }}>{+window.item_sequence + 1}</span>
          <LongText>{window.window_name}</LongText>
        </>
      ),
      size: (
        <>
          {`${fracty(window.sales_order_window_width)}" X ${fracty(window.sales_order_window_height)}"`} <span className={sales_order_measurement_type.replace(/ /g, '')}>{measurementType}</span>
        </>
      ),
    };

    const materialData = sku_details.filter((sku) => sku.sku_type === 'M')[0];
    const materials = {
      brand: materialData.brand,
      label: materialData.label,
      product: materialData.product,
      type: materialData.type,
      fabric: materialData.fabric,
      opacity: materialData.opacity,
    };

    const liftSystemData = sku_details.filter((sku) => sku.sku_type === 'L')[0];
    const liftSystem = {
      lift: liftSystemData.sku_name,
      direction: liftSystemData.type,
    };

    const optionsData = sku_details.filter((sku) => sku.sku_type === 'P');
    return optionsData
      .map(({ type, sku_name }) => ({ [type]: sku_name }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {
        ...base,
        ...materials,
        ...liftSystem,
      });
  });

  const columns = [...new Set(rows.flatMap((obj) => Object.keys(obj)))].map((key) => ({
    label: formatLabel(key),
    align: 'center',
    dataKey: key,
  }));

  return (
    <>
      <Typography variant='h6' sx={{ paddingTop: '1em', paddingBottom: '1em' }}>
        Soft Shades
      </Typography>
      <TableContainer>
        <Table>
          <Head columns={columns} />
          <TableBody>
            {rows.map((window, index) => (
              <Row columns={columns} window={window} key={index} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

const SalesOrderProductTables = {
  Blinds,
  RollerShades,
  Shutters,
  SoftShades,
};

export default SalesOrderProductTables;
