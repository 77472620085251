import React from 'react';
import { Link } from 'react-router-dom';
import fracty from 'fracty';
import { TableContainer, TableHead, Table, TableBody, TableRow, TableCell, Typography } from '@mui/material';
import LongText from '../LongText';

function formatLabel(key) {
  if (key === 'name') {
    return (
      <>
        <span style={{ paddingRight: '30px' }}>#</span>
        <span>Name</span>
      </>
    );
  }
  const words = key.replace(/([a-z])([A-Z])/g, '$1 $2').split(/(?=[A-Z])/);
  return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export function Header({ columns }) {
  return (
    <TableHead>
      <TableRow style={{ margin: 0, fontWeight: 'bold' }}>
        {columns.map((column) =>
          column.dataKey !== 'id' ? (
            <TableCell
              key={column.dataKey}
              className={`${column.dataKey === 'name' ? 'table-headerps sticky-columnws-2' : 'table-headerpd_size'} font_weight_bold`}
              variant='head'
              style={{ width: column.width }}
              sx={{
                textAlign: 'left',
                whiteSpace: 'nowrap',
              }}
            >
              {column.label}
            </TableCell>
          ) : null
        )}
      </TableRow>
    </TableHead>
  );
}

export function Row({ columns, row }) {
  return (
    <TableRow hover role='checkbox' tabIndex={-1} key={row.code}>
      {columns.map((column) =>
        column.dataKey !== 'id' ? (
          <TableCell key={column.dataKey} sx={{ whiteSpace: 'nowrap' }} className={column.dataKey === 'name' ? 'table-contentid sticky-columnws-2' : 'table-content-after-material'} align={'left'}>
            {row[column.dataKey]}
          </TableCell>
        ) : null
      )}
    </TableRow>
  );
}

export function RollerShades({ windows, state, disabled }) {
  const rows = windows.map((window) => {
    const { proposal_measurement_type, sku_details } = window;
    const measurementType =
      proposal_measurement_type === 'Quick'
        ? '(Q)'
        : proposal_measurement_type === 'Shutters'
        ? '(SH)'
        : proposal_measurement_type === 'Blinds'
        ? '(BL)'
        : '(' +
          proposal_measurement_type
            .split(' ')
            .map((word) => word[0])
            .join('') +
          ')';

    const baseWindow = {
      id: window.window_id,
      name: (
        <>
          <span style={{ paddingRight: +window.item_sequence > 9 ? '20px' : '30px' }}>{+window.item_sequence}</span>
          {!disabled ? (
            <Link
              className={window.covering_status === 'applied' ? 'link3' : 'red-link-for-covering_not_applied ellipsis2'}
              to='/tbm/ps/editingwindowproposal'
              state={{
                ...state,
                covering_status: window.covering_status,
                row_id: window.window_id,
                window_name: window.window_name,
              }}
            >
              <LongText>{window.window_name}</LongText>
            </Link>
          ) : (
            <LongText>{window.window_name}</LongText>
          )}
        </>
      ),
      size: (
        <>
          {`${fracty(window.proposal_window_width)}" X ${fracty(window.proposal_window_height)}"`}
          <span className={proposal_measurement_type.replace(/ /g, '')}>{measurementType}</span>
        </>
      ),
    };

    const materialData = sku_details.filter((sku) => sku.sku_type === 'M')[0];
    const materials = {
      brand: materialData.brand,
      label: materialData.label,
      product: materialData.product,
      type: materialData.type,
      material: materialData.fabric,
      opacity: materialData.opacity,
    };

    const liftSystemData = sku_details.filter((sku) => sku.sku_type === 'L')[0];
    const liftSystem = {
      lift: liftSystemData.sku_name,
      direction: liftSystemData.type,
    };

    const optionsData = sku_details.filter((sku) => sku.sku_type === 'P');
    return optionsData
      .map(({ type, sku_name }) => ({ [type]: sku_name }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {
        ...baseWindow,
        ...materials,
        ...liftSystem,
      });
  });

  const columns = [...new Set(rows.flatMap((obj) => Object.keys(obj)))].map((key) => ({
    label: formatLabel(key),
    align: 'center',
    dataKey: key,
  }));

  return (
    <>
      <Typography variant='h6' sx={{ paddingTop: '1em', paddingBottom: '1em' }}>
        Roller Shades
      </Typography>
      <TableContainer>
        <Table className='proposal_table' aria-labelledby='tableTitle' size='small'>
          <Header columns={columns} />
          <TableBody>
            {rows.map((row) => (
              <Row key={row.id} columns={columns} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export function Shutters({ windows, state, disabled }) {
  const rows = windows.map((window) => {
    const { proposal_measurement_type, sku_details } = window;
    const measurementtype =
      proposal_measurement_type === 'Quick'
        ? '(Q)'
        : proposal_measurement_type === 'Shutters'
        ? '(SH)'
        : proposal_measurement_type === 'Blinds'
        ? '(BL)'
        : '(' +
          proposal_measurement_type
            .split(' ')
            .map((word) => word[0])
            .join('') +
          ')';

    const baseWindow = {
      id: window.window_id,
      name: (
        <>
          <span style={{ paddingRight: +window.item_sequence > 9 ? '20px' : '30px' }}>{+window.item_sequence}</span>
          {!disabled ? (
            <Link
              className={window.covering_status === 'applied' ? 'link3' : 'red-link-for-covering_not_applied ellipsis2'}
              to='/tbm/ps/editingwindowproposal'
              state={{
                ...state,
                covering_status: window.covering_status,
                row_id: window.window_id,
                window_name: window.window_name,
              }}
            >
              <LongText>{window.window_name}</LongText>
            </Link>
          ) : (
            <LongText>{window.window_name}</LongText>
          )}
        </>
      ),
      size: `${fracty(window.proposal_window_width)}" X ${fracty(window.proposal_window_height)}" ${measurementtype}`,
    };

    const materialData = sku_details.filter((sku) => sku.sku_type === 'M')[0];
    const materials = {
      brand: materialData.brand,
      label: materialData.label,
      product: materialData.product,
      type: materialData.type,
      material: materialData.fabric,
      opacity: materialData.opacity,
    };

    const liftSystemData = sku_details.filter((sku) => sku.sku_type === 'L')[0];
    const liftSystem = {
      liftSystem: liftSystemData.sku_name,
      direction: liftSystemData.type,
    };
    const optionsData = sku_details.filter((sku) => sku.sku_type === 'P');
    return optionsData
      .map(({ type, sku_name }) => ({ [type]: sku_name }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {
        ...baseWindow,
        ...materials,
        ...liftSystem,
      });
  });

  const columns = [...new Set(rows.flatMap((obj) => Object.keys(obj)))].map((key) => ({
    label: formatLabel(key),
    dataKey: key,
  }));

  return (
    <>
      <Typography variant='h6' sx={{ paddingTop: '1em', paddingBottom: '1em' }}>
        Shutters
      </Typography>
      <TableContainer>
        <Table className='proposal_table' aria-labelledby='tableTitle' size='small'>
          <Header columns={columns} />
          <TableBody>
            {rows.map((row) => (
              <Row key={row.id} columns={columns} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export function Blinds({ windows, state, disabled }) {
  const rows = windows.map((window) => {
    const { proposal_measurement_type, sku_details } = window;
    const measurementType =
      proposal_measurement_type === 'Quick'
        ? '(Q)'
        : proposal_measurement_type === 'Shutters'
        ? '(SH)'
        : proposal_measurement_type === 'Blinds'
        ? '(BL)'
        : '(' +
          proposal_measurement_type
            .split(' ')
            .map((word) => word[0])
            .join('') +
          ')';
    const baseWindow = {
      id: window.window_id,
      name: (
        <>
          <span style={{ paddingRight: +window.item_sequence > 9 ? '20px' : '30px' }}>{+window.item_sequence}</span>
          {!disabled ? (
            <Link
              className={window.covering_status === 'applied' ? 'link3' : 'red-link-for-covering_not_applied ellipsis2'}
              to='/tbm/ps/editingwindowproposal'
              state={{
                ...state,
                covering_status: window.covering_status,
                row_id: window.window_id,
                window_name: window.window_name,
              }}
            >
              <LongText>{window.window_name}</LongText>
            </Link>
          ) : (
            <LongText>{window.window_name}</LongText>
          )}
        </>
      ),
      size: `${fracty(window.proposal_window_width)}" X ${fracty(window.proposal_window_height)}" ${measurementType}`,
    };

    const materialData = sku_details.filter((sku) => sku.sku_type === 'M')[0];
    const materials = {
      brand: materialData.brand,
      label: materialData.label,
      product: materialData.product,
      type: materialData.type,
      fabric: materialData.fabric,
      opacity: materialData.opacity,
    };

    const liftSystemData = sku_details.filter((sku) => sku.sku_type === 'L')[0];
    const liftSystem = {
      lift: liftSystemData.sku_name,
      direction: liftSystemData.type,
    };

    const optionsData = sku_details.filter((sku) => sku.sku_type === 'P');
    return optionsData
      .map(({ type, sku_name }) => ({ [type]: sku_name }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {
        ...baseWindow,
        ...materials,
        ...liftSystem,
      });
  });

  const columns = [...new Set(rows.flatMap((obj) => Object.keys(obj)))].map((key) => ({
    // width: "auto",
    label: formatLabel(key),
    dataKey: key,
  }));

  return (
    <>
      <Typography variant='h6' sx={{ paddingTop: '1em', paddingBottom: '1em' }}>
        Blinds
      </Typography>
      <TableContainer>
        <Table className='proposal_table' aria-labelledby='tableTitle' size='small'>
          <Header columns={columns} />
          <TableBody>
            {rows.map((row) => (
              <Row key={row.id} columns={columns} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export function SoftShades({ windows, state, disabled }) {
  const rows = windows.map((window) => {
    const { proposal_measurement_type, sku_details } = window;
    const measurementType =
      proposal_measurement_type === 'Quick'
        ? '(Q)'
        : proposal_measurement_type === 'Shutters'
        ? '(SH)'
        : proposal_measurement_type === 'Blinds'
        ? '(BL)'
        : '(' +
          proposal_measurement_type
            .split(' ')
            .map((word) => word[0])
            .join('') +
          ')';
    const baseWindow = {
      id: window.window_id,
      name: (
        <>
          <span style={{ paddingRight: +window.item_sequence > 9 ? '20px' : '30px' }}>{+window.item_sequence}</span>
          {!disabled ? (
            <Link
              className={window.covering_status === 'applied' ? 'link3' : 'red-link-for-covering_not_applied ellipsis2'}
              to='/tbm/ps/editingwindowproposal'
              state={{
                ...state,
                covering_status: window.covering_status,
                row_id: window.window_id,
                window_name: window.window_name,
              }}
            >
              <LongText>{window.window_name}</LongText>
            </Link>
          ) : (
            <LongText>{window.window_name}</LongText>
          )}
        </>
      ),
      size: `${fracty(window.proposal_window_width)}" X ${fracty(window.proposal_window_height)}" ${measurementType}`,
    };

    const materialData = sku_details.filter((sku) => sku.sku_type === 'M')[0];
    const materials = {
      brand: materialData.brand,
      label: materialData.label,
      product: materialData.product,
      type: materialData.type,
      fabric: materialData.fabric,
      opacity: materialData.opacity,
    };

    const liftSystemData = sku_details.filter((sku) => sku.sku_type === 'L')[0];
    const liftSystem = {
      lift: liftSystemData.sku_name,
      direction: liftSystemData.type,
    };

    const optionsData = sku_details.filter((sku) => sku.sku_type === 'P');
    return optionsData
      .map(({ type, sku_name }) => ({ [type]: sku_name }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {
        ...baseWindow,
        ...materials,
        ...liftSystem,
      });
  });

  const columns = [...new Set(rows.flatMap((obj) => Object.keys(obj)))].map((key) => ({
    // width: "auto",
    label: formatLabel(key),
    dataKey: key,
  }));

  return (
    <>
      <Typography variant='h6' sx={{ paddingTop: '1em', paddingBottom: '1em' }}>
        Soft Shades
      </Typography>
      <TableContainer>
        <Table className='proposal_table' aria-labelledby='tableTitle' size='small'>
          <Header columns={columns} />

          <TableBody>
            {rows.map((row) => (
              <Row key={row.id} columns={columns} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

const ProductTables = {
  Blinds,
  Shutters,
  RollerShades,
  SoftShades,
};

export default ProductTables;
