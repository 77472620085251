/** @format */

import React, { forwardRef, useState, useEffect, useContext } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material';
import CONFIG from '../../config/config.js';
import Paper from '@mui/material/Paper';
import CancelIcon from '../../assets/icons/secondary/2nd-cancel.svg';
import { UserContext } from '../Shared/Layout';
import ShowProposalName from './ShowProposalName';
import MuiAlert from '@mui/material/Alert';
import { SnackbarContext } from '../../App';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CoveringDrawer } from './CoveringsDrawer';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { CoveringDrawer2 } from './CoveringDrawer2';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ApplyCoveringIcon from '../../assets/icons/primary/window.svg';
import ApplyCoveringIcon2 from '../../assets/icons/primary/window-edit.svg';
import ApplyCoveringHovIcon from '../../assets/icons/primary/WithHovIcon/window-hov.svg';
import fracty from 'fracty';
import NotesDrawer from './NotesDrawer';
import ProposalIcon from '../../assets/icons/primary/proposal.svg';
import Checkbox from '@mui/material/Checkbox';
import FilterIcon from '../../assets/icons/secondary/2nd-filter.svg';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../assets/icons/drawer-alert/drw-alert.svg';
import DrawerContinueIcon from '../../assets/icons/drawer-alert/drw-continue.svg';
import DrawerConfirmIcon from '../../assets/icons/drawer-alert/drw-confirm.svg';
import NoteMiscIcon from '../../assets/icons/misc/misc-note.svg';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import { WindowContext, WindowDispatchContext } from '../Shared/WindowProvider';
import WindowDetails from '../Shared/WindowDetails';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
  },
});

const notesDrawerTheme = createTheme({
  palette: {
    border: {
      main: '1px solid #000',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#FAC41E',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          // backgroundColor: "green", // date picker textfield style
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#008593 !important',
          },
          '&.Mui-disabled': {
            backgroundColor: 'white', // disable date
            color: '#E9E9E9',
          },
        },
        dayWithMargin: {
          backgroundColor: 'white', // not selected date
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          // backgroundColor: "blue" //calender icon & month change arrow icon style
        },
      },
    },
  },
});

const mainTheme = createTheme({
  palette: {
    primary: {
      main: '#333333',
    },
  },
});

const useStyles = makeStyles({
  paper: {
    width: '90%',
    background: 'rgba(69, 157, 166, 70%) !important',
  },
  backDrop: {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  paddingRight: theme.spacing(0.5),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

function findCommonObjects([reference, ...rest]) {
  return reference && rest.length ? reference.filter((item1) => rest.every((array) => array.some((item2) => item1.window_id === item2.window_id))) : [];
}

export default function BatchEditWindowCovering() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const contextvalue = useContext(SnackbarContext);
  const { windowDetails } = useContext(WindowContext);
  const windowDispatch = useContext(WindowDispatchContext);
  const coveringdetail = useContext(UserContext);

  const vertical = 'top';
  const horizontal = 'center';

  if (!location.state) {
    navigate('/pagenotfound');
  }

  const selectedwindowsIDs = location.state.selectedwindows;
  const checkedrowdata = location.state.selectedwindows;
  const windowsetdata = location.state.proposaldetails.customerdata;
  let coveringdetails = location.state.coveringdetails ? location.state.coveringdetails : coveringdetail;
  let windowsetnull = [];
  let windowname = [];
  let appliedcoveringswindowname = [];
  const token = sessionStorage.getItem('token');

  const [windowData, setWindowData] = useState([]);
  const [successmessage, setSuccessMessage] = useState('');
  const [openupdatewindowname, setUpdateWindownameOpen] = useState(false);
  const [outgoingwindow_width, setOutgoingSizeW] = useState('');
  const [outgoingwindow_height, setOutgoingSizeH] = useState('');
  const [mountSurface, setMountSurface] = useState('');
  const [ladder, setLadder] = useState('');
  const [value, setValue] = useState([]);
  const [selectedOptionJSON, setSelectedOptionJSON] = useState({});
  const [confirmSalesOrderStatus, setConfirmSalesOrderStatus] = useState(false);
  const [confirmable, setConfirmable] = useState(false);
  const [markasconfirmedSalesOrderStatus, setMarkAsConfirmSalesOrderStatus] = useState(false);
  const [clearfilters, setClearFilters] = useState(false);

  const [brand, setBrand] = useState('');
  const [label, setLabel] = useState('');
  const [type, setType] = useState('');
  const [product, setProduct] = useState('');
  const [opacity, setOpacity] = useState('');
  const [fabric, setFabric] = useState('');
  const [lift, setLift] = useState('');
  const [direction, setDirection] = useState('');
  const [headrail, setHeadrail] = useState('');
  const [windowdepth, setWindowDepth] = useState('');
  const [features, setFeatures] = useState('');
  const [track, setTrack] = useState('');
  const [design, setDesigner] = useState('0');
  const [install, setInstall] = useState('Yes');
  const [option, setOption] = useState([]);
  const [open, setOpen] = useState(false);
  const [chooseFilters, setChooseFilters] = useState([]);
  const [windowCoveringCategory, setWindowCoveringCategory] = useState('');
  const [brands, setBrands] = useState([]);
  const [brandLabel, setBrandLabel] = useState([]);
  const [totalWidth, setTotalWidth] = useState('');
  const [quickapply, setQuickApply] = useState(false);

  const [sucessmsg, setSuccessMsg] = useState(false);
  const [errormsg, setErrorMsg] = useState(false);

  const [showInTable, setShowInTable] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [selectedSKU, setSelectedSKU] = useState('');
  const [classtype, setClassType] = useState('');
  const [proposalName, setProposalName] = useState('');

  const [AllreadyCoveringAppliedWindows, setAllreadyCoveringAppliedWindows] = useState([]);
  const [CoveringnotAppliedtoWindows, setCoveringnotAppliedtoWindows] = useState([]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [sendmsg, setSendmsg] = useState();
  const [showProductDescription, setShowProductDescription] = useState(false);
  const [showSKUCombination, setShowSKUCombination] = useState(false);
  const [coveringCategory, setCoveringCategory] = useState('');
  const [size, setSize] = useState('');

  const [existingCovering, setExistingCovering] = useState('');
  const [measurementType, setMeasurementType] = useState('');
  const [buildType, setBuildType] = useState('');

  const [OptionName, setOptionName] = useState([]);
  const [LiftSystem, setLiftSystem] = useState([]);
  const [LiftSytemDetails, setLiftSytemDetails] = useState([]);
  const [SelectedLiftSytemDetails, setSelectedLiftSytemDetails] = useState([]);
  const [selectedLiftSytemSKU, setSelectedLiftSytemSKU] = useState();
  const [SelectedOptionDetails, setSelectedOptionDetails] = useState([]);
  const [SelectedOptionSKU, setSelectedOptionSKU] = useState();
  const [selectedFabricSKU, setSelectedFabricSKU] = useState();
  const [SKUCombinations, setSKUCombinations] = useState([]);
  const [ProductDescription, setProductDescriptions] = useState([]);

  const [disablebutton, setDisableButton] = useState(false);
  const [leftshade_size, setLeftshade_size] = useState('');
  const [rightshade_size, setRightshade_size] = useState('');
  const [centershade_size, setCentershade_size] = useState('');
  const [Panel, setPanel] = useState('2');
  const [Windowsill, setWindowsill] = useState('Yes');
  const [DeadMold, setDeadMold] = useState('');
  const [Buildout, setBuildout] = useState('');
  const [FillerStrip, setFillerStrip] = useState('');
  const [SplitTilt, setSplitTilt] = useState('');
  const [SplitRail, setSplitRail] = useState('');
  const [TPost, setTPost] = useState('');
  const [obstructions, setObstructions] = useState('');
  const [multiple, setMultiple] = useState('');
  const [rolldirection, setRollDirection] = useState('');
  const [AllOptionSelectedStatus, setAllOptionSelectedStatus] = useState(false);

  const [brandLabelProduct, setbrandLabelProduct] = useState([]);
  const [brandLabelProductType, setbrandLabelProductType] = useState([]);
  const [brandLabelProductTypeOpacity, setbrandLabelProductTypeOpacity] = useState([]);
  const [brandLabelProductTypeOpacityFabric, setbrandLabelProductTypeOpacityFabric] = useState([]);
  const [brandLabelProductTypeOpacityFabricLift, setBrandLabelProductTypeOpacityFabricLift] = useState([]);
  const [brandLabelProductTypeOpacityFabricLiftDirection, setbrandLabelProductTypeOpacityFabricLiftDirection] = useState([]);
  const [brandLabelProductTypeOpacityFabricLiftDirectionHeadrail, setBrandLabelProductTypeOpacityFabricLiftDirectionHeadrail] = useState([]);
  const [brandLabelProductTypeOpacityFabricLiftDirectionHeadrailValance, setBrandLabelProductTypeOpacityFabricLiftDirectionHeadrailValance] = useState([]);
  const [brandLabelProductTypeOpacityFabricLiftDirectionHeadrailValanceFeatures, setBrandLabelProductTypeOpacityFabricLiftDirectionHeadrailValanceFeatures] = useState([]);
  const [brandLabelProductTypeOpacityFabricLiftDirectionHeadrailValanceFeaturesTrack, setBrandLabelProductTypeOpacityFabricLiftDirectionHeadrailValanceFeaturesTrack] = useState([]);
  const [categorynames, setCategoryNames] = useState([]);
  const [livaluechangeflag, setLIValueChanged] = useState(false);
  const [OptionalFinalizationFields, setOptionalFinalizationFields] = useState({});
  const [checkedItems, setCheckedItems] = useState();
  const [CoveringDetails, setCoveringDetails] = useState([]);
  const [CoveringSearchResults, setCoveringSearchResults] = useState([]);
  const [labelnames, setLabelNames] = useState([]);
  const [searchArr, setSearchArr] = useState([]);
  const [keywordsbyFilter, setKeywordsbyFilter] = useState([]);
  const [keywordsbySearch, setKeywordsbySearch] = useState([]);
  const [isEnabled, setIsEnabled] = useState(true);
  const [saveStatus, setSaveStatus] = useState(false);
  const [psf, setPSF] = useState('');
  const [updatedpsf, setUpdatedPSF] = useState('');
  const [proposalAdditionalSKU, setProposalAdditionalSKU] = useState([]);
  const [defaultProposalAdditionalSKU, setDefaultProposalAdditionalSKU] = useState([]);
  const [showWindowDepth, setShowWindowDepth] = useState('');
  const [showObstructions, setShowObstructions] = useState('');

  const [othermeasurement, setOtherMeasurement] = useState('');
  const [description, setDescription] = useState('');
  const [windowsComparison, setWindowsComparison] = useState('');
  const [HeightForVerticalConfiguration, setHeightForVerticalConfiguration] = useState('');
  const [SectionOneTopHeigth, setSectionOneTopHeigth] = useState('');
  const [SectionTwoBottomWidth, setSectionTwoBottomWidth] = useState('');
  const [VerticalConfiguration, setVerticalConfiguration] = useState('1 Section');

  const [AllOptionNPSSelectedStatus, setAllOptionNPSSelectedStatus] = useState(false);

  // error handling states
  const [ApplyCoveringsErrorAlert, setApplyCoveringsErrorAlert] = useState(false);
  const [ProposalByProposalIdErrorAlert, setProposalByProposalIdErrorAlert] = useState(false);
  const [ApplyCoveringsErrorMsg, setApplyCoveringsErrorMsg] = useState('');
  const [ProposalByProposalIdErrorMsg, setProposalByProposalIdErrorMsg] = useState('');
  //  hover states

  const [isHovered, setIsHovered] = useState(false);

  const handleHoverFun = () => setIsHovered(true);

  const handleMouseLeaveFun = () => setIsHovered(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getVerified();
    init();
  }, []);

  const setData = (
    drawerStatus,
    windowCoveringCategory,
    brand,
    label,
    product,
    type,
    opacity,
    fabric,
    lift,
    direction,
    option,
    showTable,
    newProductDescription,
    ProductDescription,
    newSKUCombinations,
    newSelectedFabricSKU,
    newSelectedLiftSytemSKU,
    selectedOptionSKU,
    newShowInTable,
    clearFilters,
    showPD,
    showSKU,
    windowData,
    measurementType,
    outgoingWindowWidth,
    outgoingWindowHeight,
    existingCovering,
    mountSurface,
    buildType,
    ladder,
    optionalFinalizationFields,
    selectedOptionJSON,
    checkedItems,
    saveStatus,
    size,
    psf,
    proposalAdditionalSKU,
    allOptionSelectedStatus,
    allOptionNpsSelectedStatus
  ) => {
    setSelectedOptionJSON({ ...selectedOptionJSON });
    setSaveStatus(saveStatus);
    setProposalAdditionalSKU(proposalAdditionalSKU);

    if (windowCoveringCategory && brand && label && product && type && opacity && fabric) {
      setShowTable(true);
    }

    setWindowCoveringCategory(windowCoveringCategory);
    setBrand(brand);
    setLabel(label);
    setProduct(product);
    setType(type);
    setOpacity(opacity);
    setFabric(fabric);
    setLift(lift);
    setDirection(direction);
    setCheckedItems(checkedItems);
    setOption([...option]);
    setClearFilters(false);

    setProductDescriptions(ProductDescription);
    setSKUCombinations(newSKUCombinations.filter(unique));
    setSelectedFabricSKU(newSelectedFabricSKU);
    setShowInTable(newShowInTable);
    setSelectedLiftSytemSKU(newSelectedLiftSytemSKU);
    setSelectedOptionSKU(selectedOptionSKU);
    setOptionalFinalizationFields(optionalFinalizationFields);
    setExistingCovering(existingCovering);

    setBuildType(measurementType !== 'Roller Shades' && buildType === 'Fabric' ? '' : buildType);

    setMountSurface(mountSurface);
    setLadder(ladder);
    setMeasurementType(measurementType);

    setSize(outgoingWindowWidth && outgoingWindowHeight ? `${fracty(outgoingWindowWidth)} x ${fracty(outgoingWindowHeight)}` : 'Multiple');
    setOutgoingSizeW(outgoingWindowWidth);
    setOutgoingSizeH(outgoingWindowHeight);

    setUpdatedPSF(psf);

    setShowProductDescription(showPD);
    setShowSKUCombination(showSKU);
    setAllOptionSelectedStatus(allOptionSelectedStatus);
    setAllOptionNPSSelectedStatus(allOptionNpsSelectedStatus);
    setIsDrawerOpen(!drawerStatus);
  };

  useEffect(() => {
    // Is this creating infinite loops?
    setSaveStatus(saveStatus);
  }, [saveStatus]);

  useEffect(() => {
    // Is this creating infinite loops?
    setPSF(psf);
  }, [psf]);

  useEffect(() => {
    if (OptionalFinalizationFields) {
      setOptionalFinalizationFields(OptionalFinalizationFields);

      const containsEmptyValues = (arr) => arr.some((value) => (typeof value === 'string' && value.trim() === '') || !value);

      if (containsEmptyValues(Object.values(OptionalFinalizationFields))) {
        setConfirmSalesOrderStatus(false);
        setMarkAsConfirmSalesOrderStatus(false);
      } else {
        setConfirmSalesOrderStatus(true);
      }
    } else {
      setConfirmSalesOrderStatus(false);
      setMarkAsConfirmSalesOrderStatus(false);
    }
  }, [OptionalFinalizationFields]);

  useEffect(() => {
    setConfirmable(confirmSalesOrderStatus && AllOptionNPSSelectedStatus);
  }, [confirmSalesOrderStatus, AllOptionNPSSelectedStatus]);

  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };

  const init = async () => {
    await getProposalByProposalId();
    await getNotes();
    await getWindowDetails();
    // await getWindowCoveringProposalDetails();
  };

  const getProposalByProposalId = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    };
    // Created Proposal will be added to the database through CreateProposal API
    const response = await fetch(`${CONFIG.API_URL}/pm/ProposalByProposalId/${location.state.proposaldetails.proposal_id}`, requestOptions);
    const data = await response.json();
    if (!response.ok || data.error) {
      setProposalByProposalIdErrorMsg(data.error_message);
      setProposalByProposalIdErrorAlert(true);
      return;
    }

    setProposalName(data.proposal.proposal_name);

    const selectedWindows = findCommonObjects([data.proposal_details, location.state.selectedwindows]);
    windowsetnull = selectedWindows;

    let lengthofSelectedWindows = location.state.selectedwindows.length;
    let lengthofProposalWindows = data.proposal_details.length;

    setWindowsComparison(`${lengthofSelectedWindows} of ${lengthofProposalWindows}`);
  };

  const getNotes = async () => {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    };
    const response = await fetch(`${CONFIG.API_URL}/cm/Notes/${location.state.proposaldetails.proposal_id}`, options);
    const data = await response.json();
    if (!response.ok || data.error) {
      return;
    }
    setnotesContent(data.Notes);
  };

  const getWindowDetails = async () => {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    };

    const response = await fetch(
      `${CONFIG.API_URL}/pm/window-covering-proposal-details/window?${new URLSearchParams({
        proposal_id: location.state.proposaldetails.proposal_id,
        windows: location.state.selectedwindows.map(({ window_id }) => window_id),
      })}`,
      options
    );
    const { error, details, ...data } = await response.json();

    if (!response.ok || error) {
      return console.error(response.status, error.message);
    }

    const { proposal_measurement_type, proposal_width, proposal_height, proposal_additional_sku, proposal_measurement_type_specific_factor } = details[0];

    const existingCoveringSku = proposal_additional_sku.filter((skuDetails) => skuDetails.type === 'Existing Covering')[0];
    const ladderSku = proposal_additional_sku.filter((skuDetails) => skuDetails.type === 'Ladder')[0];
    const mountSurfaceSku = proposal_additional_sku.filter((skuDetails) => skuDetails.type === 'Mount Surface')[0];
    const windowConfigurationSku = proposal_additional_sku.filter((skuDetails) => skuDetails.type === 'Window Configuration')[0] || {};
    const windowDepthSku = proposal_additional_sku.filter((skuDetails) => skuDetails.type === 'Window Depth')[0];
    const obstructionsSku = proposal_additional_sku.filter((skuDetails) => skuDetails.type === 'Obstructions')[0];
    const buildTypeSku = proposal_additional_sku.filter((skuDetails) => skuDetails.type === 'Build Type')[0];
    const verticalConfigurationSku = proposal_additional_sku.filter((skuDetails) => skuDetails.type === 'Vertical Configuration')[0];

    windowDispatch({
      action: 'SET_ALL',
      payload: {
        ids: location.state.selectedwindows.map((window) => window.window_id),
        measurementType: proposal_measurement_type,
        width: proposal_width,
        height: proposal_height,
        size: proposal_width !== 'Multiple' || proposal_height !== 'Multiple' ? `${fracty(proposal_width)}" X ${fracty(proposal_height)}"` : 'Multiple',
        windowDepth: { sku: windowDepthSku?.additional_charge_sku, service_name: windowDepthSku?.sku_name },
        windowDepthMeasurement: windowDepthSku?.sku_name === 'Multiple' ? 'Multple' : parseFloat(windowDepthSku?.additional_detail),
        windowConfiguration: { sku: windowConfigurationSku?.additional_charge_sku, service_name: windowConfigurationSku?.sku_name },
        sections: windowConfigurationSku ? JSON.parse(windowConfigurationSku.additional_detail) : null,
        existingCoverings: { sku: existingCoveringSku.additional_charge_sku, service_name: existingCoveringSku.sku_name },
        ladder: { sku: ladderSku.additional_charge_sku, service_name: ladderSku.sku_name },
        mountSurface: { sku: mountSurfaceSku.additional_charge_sku, service_name: mountSurfaceSku.sku_name },
        obstructions: { sku: obstructionsSku?.additional_charge_sku, service_name: obstructionsSku?.sku_name },
        obstructionsDetails: obstructionsSku?.additional_detail ? JSON.parse(obstructionsSku?.additional_detail).join(';') : null,
        buildType: { sku: buildTypeSku?.additional_charge_sku, service_name: buildTypeSku?.sku_name },
        windowSill: proposal_measurement_type_specific_factor.Windowsill ? { sku: proposal_measurement_type_specific_factor.Windowsill === 'No' ? 0 : 1, service_name: proposal_measurement_type_specific_factor.Windowsill } : { sku: '', service_name: '' },
        verticalConfigurations: { sku: verticalConfigurationSku?.additional_charge_sku, service_name: verticalConfigurationSku?.sku_name },
        verticalSections: verticalConfigurationSku ? JSON.parse(verticalConfigurationSku.additional_detail) : null,
      },
    });
  };

  // To navigate proposalDetails page after fill the all fields
  const applyCoveringOptions = async () => {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        proposal_id: location.state.proposaldetails.proposal_id,
        window_set_id: location.state.proposaldetails.customerdata.window_set_id,
        windows: location.state.selectedwindows,
        sku: SKUCombinations,
        description: ProductDescription,
        direction: direction,
        option: option,
        installation: install,
        windowCoveringCategory: windowCoveringCategory,
        confirm_to_sales_order: markasconfirmedSalesOrderStatus,
        OptionalFinalizationFields: OptionalFinalizationFields,
        proposal_window_width: windowDetails.width !== 'Multiple' ? windowDetails.width : '',
        proposal_window_height: windowDetails.height !== 'Multiple' ? windowDetails.height : '',
        measurement_type: windowDetails.measurementType !== 'Multiple' ? windowDetails.measurementType : '',
        measurement_type_specific_factor: {},
        proposal_measurement_type_specific_factor: updatedpsf,
        build_type: windowDetails.buildType.service_name !== 'Multiple' ? windowDetails.buildType.service_name : '',
        existing_covering: windowDetails.existingCoverings.service_name !== 'Multiple' ? windowDetails.existingCoverings.service_name : '',
        mount_surface: windowDetails.mountSurface.service_name !== 'Multiple' ? windowDetails.mountSurface.service_name : '',
        ladder: windowDetails.ladderservice_name !== 'Multiple' ? windowDetails.ladder.service_name : '',
        proposal_additional_sku: proposalAdditionalSKU,
      }),
    };

    const response = await fetch(`${CONFIG.API_URL}/pm/ApplyCoverings`, requestOptions);
    const data = await response.json();
    if (!response.ok || data.error) {
      setApplyCoveringsErrorMsg(data.error_message);
      setApplyCoveringsErrorAlert(true);
      return;
    }
    let windowsselectedforcovering = [];
    let coverings_applied_windows = [];

    data.proposalDetails.forEach((element) => {
      if (element.window_selected) {
        windowsselectedforcovering.push(element);
      }
      if (element.covering_selected) {
        coverings_applied_windows.push(element);
      }
      if (element.covering_selected && element.covering_status === 'applied') {
        appliedcoveringswindowname.push(element.window_name);

        setAllreadyCoveringAppliedWindows(appliedcoveringswindowname.toString());

        setSuccessMsg(true);
      } else if (element.covering_selected && element.covering_status === 'not_applied') {
        windowname.push(element.window_name);
        setCoveringnotAppliedtoWindows(windowname.toString());
        setErrorMsg(true);
      }
    });
    if (windowsselectedforcovering.length === coverings_applied_windows.length) {
      contextvalue.setSuccesswindows(true);
      contextvalue.setFailurewindows(true);
      navigate(`/tbm/ps/proposal/${location.state.proposaldetails.proposal_id}/details`, {
        state: {
          selectedwindows: selectedwindowsIDs,
          customerdata: windowsetdata,
          totalselectedwindowsforcoverings: data.covering_proposal_details,
          proposalID: location.state.proposaldetails.proposal_id,
          proposalName: proposalName,
          totalwindows: location.state.totalwindows,
          windowsselectednotnull: windowsetnull,
          jsonData: jsonData,
          coveringdetails: coveringdetails,
          successwindownames: appliedcoveringswindowname,
          failurewindownames: windowname,
          alerts: data.proposalCoverings,
        },
      });
    } else {
      navigate(`/tbm/ps/proposalselectwindow/${params.id}`, {
        state: {
          coveringdetails: coveringdetails,
          selectedwindows: checkedrowdata,
          customerdata: windowsetdata,
          totalwindows: location.state.totalwindows,
          totalselectedwindowsforcoverings: data.covering_proposal_details,
          windowsselectednotnull: windowsetnull,
          proposalID: location.state.proposalID,
          proposalName: proposalName,
          jsonData: jsonData,
        },
      });
    }
  };

  const unique = (value, index, self) => self.indexOf(value) === index;

  const resetFilters = () => {
    setClearFilters(true);
    setValue([]);
    setSearchArr([]);
    setCoveringSearchResults([]);
    setWindowCoveringCategory('');
    setDisableButton(true);
    setBrand('');
    setProduct('');
    setLabel('');
    setType('');
    setOpacity('');
    setFabric('');
    setLift('');
    setDirection('');
    setOption([]);
    setFeatures('');
    setTrack('');
    setDesigner(0);
    setSelectedSKU('');
    setProductDescriptions('');
    setSKUCombinations([]);
    setSelectedFabricSKU();
    setSelectedLiftSytemSKU();
    setConfirmSalesOrderStatus(false);
    setSelectedOptionSKU([]);
    setCheckedItems();
    setSelectedOptionJSON({});
    setOptionalFinalizationFields({});
    setMarkAsConfirmSalesOrderStatus(false);
    setBrandLabel([]);
    setbrandLabelProduct([]);
    setbrandLabelProductType([]);
    setbrandLabelProductTypeOpacity([]);
    setbrandLabelProductTypeOpacityFabric([]);
    setBrandLabelProductTypeOpacityFabricLift([]);
    setbrandLabelProductTypeOpacityFabricLiftDirection([]);
    setBrandLabelProductTypeOpacityFabricLiftDirectionHeadrail([]);
    setBrandLabelProductTypeOpacityFabricLiftDirectionHeadrailValance([]);
    setBrandLabelProductTypeOpacityFabricLiftDirectionHeadrailValanceFeatures([]);
    setBrandLabelProductTypeOpacityFabricLiftDirectionHeadrailValanceFeaturesTrack([]);
    setShowTable(false);
    setShowInTable([]);
    setSKUCombinations([]);
    setProductDescriptions([]);
  };

  const send = () => {
    setSendmsg(true);
    setTimeout(() => {
      setSendmsg(false);
      navigate(-1);
    }, 500);
  };

  const handleClose = () => setSaveStatus(false);

  const handleContinue = () => {
    setSaveStatus(false);
    setIsDrawerOpen(false);
  };

  let searchedArr = [];
  let checkSubset = (parentArray, subsetArray) => subsetArray.every((el) => parentArray.includes(el));

  useEffect(() => {
    if (coveringdetail) {
      coveringdetails = coveringdetail;
      setCoveringDetails(coveringdetails);
    }

    setCoveringCategory(windowCoveringCategory);
  }, [brand, windowCoveringCategory]);

  useEffect(() => {
    if (coveringdetail !== undefined) {
      coveringdetails = coveringdetail;
      setCoveringDetails(coveringdetails);
    }
  }, [coveringdetail]);

  useEffect(() => {
    if (value.length > 0) {
      setKeywordsbySearch(value);
    }
    if (value.length === 0) {
      setWindowCoveringCategory('');
      setBrand('');
      setLabel('');
      setProduct('');
      setType('');
      setOpacity('');
      setFabric('');
      setShowSKUCombination(false);
      setProductDescriptions([]);
      setShowProductDescription(false);
      setIsEnabled(true);
      setKeywordsbySearch([]);
    }
  }, [value]);

  useEffect(() => {
    var filteredKeywords = [];
    if (keywordsbyFilter.length > 0 && keywordsbySearch.length > 0) {
      let filteredVal = keywordsbyFilter.filter((word) => keywordsbySearch.includes(word));
      if (filteredVal.length > 0) {
        setKeywordsbyFilter([]);
        setKeywordsbySearch([]);
        filteredKeywords = filteredVal;
      } else {
        filteredKeywords = keywordsbySearch;
      }
    } else if (keywordsbyFilter.length === 0 && keywordsbySearch.length > 0) {
      filteredKeywords = keywordsbySearch;
    } else if (keywordsbyFilter.length > 0 && keywordsbySearch.length === 0) {
      filteredKeywords = keywordsbyFilter;
    }

    if (filteredKeywords.length > 0) {
      handleSearch(filteredKeywords);
    }
  }, [keywordsbySearch, keywordsbyFilter]);

  const handleSearch = (searchedItems) => {
    if (searchedItems.length === 0) {
      setProductDescriptions([]);
      setSKUCombinations([]);
    }

    setSearchArr(searchedItems);
    setCoveringDetails(coveringdetail);
    searchedArr = coveringdetail;

    let materialfinalarr = [];
    let optionfinalarr = [];
    let labelsforspecificliftSystem = [];
    let materialarr = [];
    let listsystemarr = [];
    let liftsystems = [];
    let directions = [];
    let optionsarr = [];
    let labelsforselectedListsystem = [];
    let foundLabel;
    searchedArr.forEach((element, index) => {
      if (element.sku_type === 'M') {
        materialarr.push(element);
      } else if (element.sku_type === 'L') {
        listsystemarr.push(element);
        liftsystems.push(element.value_1);
        directions.push(element.value_2);
      } else if (element.sku_type === 'P') {
        optionsarr.push(element);
      }
      let status = checkSubset(searchedArr[index].keywords, searchedItems);

      if (status === true && searchedArr[index].sku_type === 'M') {
        materialfinalarr.push(searchedArr[index]);
      }
    });

    materialfinalarr = materialfinalarr.filter(unique);
    setCoveringSearchResults(materialfinalarr);
    setShowInTable(materialfinalarr);

    let mskus = [];
    if (materialfinalarr.length === 1) {
      setProductDescriptions(materialfinalarr[0].description);
      setSKUCombinations([materialfinalarr[0].sku]);
      mskus = [materialfinalarr[0].sku];

      if (materialfinalarr.length === 1 && lift && direction) {
        let liftSystemPD = [lift, direction];

        setProductDescriptions([materialfinalarr[0].description, ...liftSystemPD]);
        setSKUCombinations([materialfinalarr[0].sku, selectedLiftSytemSKU]);
        setShowProductDescription(true);
      }
      if (materialfinalarr.length === 1 && option.length > 0) {
        let optionPD = [...option];

        setProductDescriptions([materialfinalarr[0].description, ...optionPD]);
        setSKUCombinations([materialfinalarr[0].sku, ...SelectedOptionSKU]);
        setShowProductDescription(true);
      }
      if (materialfinalarr.length === 1 && lift && direction && option.length > 0) {
        let liftSystemPD = [lift, direction];
        let optionPD = [...option];

        setProductDescriptions([materialfinalarr[0].description, ...liftSystemPD, ...optionPD]);
        setSKUCombinations([materialfinalarr[0].sku, selectedLiftSytemSKU, ...SelectedOptionSKU]);
        setShowProductDescription(true);
      }
    }
    liftsystems = liftsystems.filter(unique);

    setLiftSystem(liftsystems);
    directions = directions.filter(unique);

    setbrandLabelProductTypeOpacityFabricLiftDirection(directions);
    if (materialfinalarr.length === 1) {
      let arrsku = [];
      setShowSKUCombination(true);

      setSelectedFabricSKU(materialfinalarr[0].sku);
      setShowProductDescription(true);

      if (materialfinalarr[0].sku) {
        setIsEnabled(false);
      } else {
        setIsEnabled(true);
      }
    }
  };

  let FilteredCovering_Category = [];
  let FilteredBrand = [];
  let FilteredLabel = [];
  let FilteredProduct = [];
  let FilteredType = [];
  let FilteredOpacity = [];
  let FilteredFabric = [];

  useEffect(() => {
    let keywordsArr = [];
    if (CoveringDetails !== undefined && CoveringDetails.length > 0) {
      CoveringDetails.forEach((element) => {
        if (element.keywords) {
          keywordsArr.push(...element.keywords);
        }
      });

      if (CoveringSearchResults.length > 0) {
        CoveringSearchResults.forEach((element, i) => {
          FilteredCovering_Category.push(element.covering_category);
          FilteredBrand.push(element.brand);
          FilteredLabel.push(element.label);
          FilteredProduct.push(element.product);
          FilteredType.push(element.type);
          FilteredOpacity.push(element.opacity);
          FilteredFabric.push(element.material);
        });
        FilteredCovering_Category = FilteredCovering_Category.filter(unique);
        FilteredBrand = FilteredBrand.filter(unique);
        FilteredProduct = FilteredProduct.filter(unique);
        FilteredLabel = FilteredLabel.filter(unique);
        FilteredType = FilteredType.filter(unique);
        FilteredOpacity = FilteredOpacity.filter(unique);
        FilteredFabric = FilteredFabric.filter(unique);

        if (FilteredCovering_Category.length === 1) {
          setWindowCoveringCategory(FilteredCovering_Category[0]);
          setCategoryNames([FilteredCovering_Category[0]]);
        }

        if (FilteredBrand.length === 1) {
          setBrand(FilteredBrand[0]);
          setBrands([FilteredBrand[0]]);
        }
        if (FilteredBrand.length > 1) {
          setBrand('');
          setBrands(FilteredBrand);
        }

        if (FilteredCovering_Category.length > 0) {
          setCategoryNames(FilteredCovering_Category);
        }

        if (FilteredProduct.length === 1) {
          setProduct(FilteredProduct[0]);
          setbrandLabelProduct([FilteredProduct[0]]);
        }
        if (FilteredProduct.length > 1) {
          setProduct('');
          setbrandLabelProduct(FilteredProduct);
        }
        if (FilteredLabel.length === 1) {
          setLabel(FilteredLabel[0]);
          setBrandLabel([FilteredLabel[0]]);
        }
        if (FilteredLabel.length > 1) {
          setLabel('');
          setBrandLabel(FilteredLabel);
        }
        if (FilteredType.length === 1) {
          setType(FilteredType[0]);
          setbrandLabelProductType([FilteredType[0]]);
        }
        if (FilteredType.length > 1) {
          setType('');
          setbrandLabelProductType(FilteredType);
        }
        if (FilteredOpacity.length === 1) {
          setOpacity(FilteredOpacity[0]);
          setbrandLabelProductTypeOpacity([FilteredOpacity[0]]);
        }
        if (FilteredOpacity.length > 1) {
          setOpacity('');
          setbrandLabelProductTypeOpacity(FilteredOpacity);
        }
        if (FilteredFabric.length === 1) {
          setFabric(FilteredFabric[0]);
          setbrandLabelProductTypeOpacityFabric(FilteredFabric);
        }
        if (FilteredFabric.length > 1) {
          setFabric('');
          setSelectedFabricSKU();
          setShowSKUCombination(false);
          setShowProductDescription(false);
          setbrandLabelProductTypeOpacityFabric(FilteredFabric);
        }
      }
    }
    if (CoveringSearchResults.length === 0) {
      if (CoveringDetails !== undefined && CoveringDetails.length > 0) {
        setChooseFilters(CoveringDetails);

        const covering_category = [];
        const Brand = [];
        const Label = [];
        const Product = [];
        const Type = [];
        const Opacity = [];
        const Fabric = [];
        const LiftSystems = [];
        const Directions = [];
        const LiftSystemDetails = [];
        const OptionsAvailable = [];
        const OptionsAvailableDetails = [];
        CoveringDetails.forEach((element, i) => {
          covering_category.push(element.covering_category);
        });
        CoveringDetails.forEach((element, i) => {
          Brand.push(element.brand);
        });
        CoveringDetails.forEach((element, i) => {
          Label.push(element.label);
        });
        CoveringDetails.forEach((element, i) => {
          Product.push(element.product);
        });
        CoveringDetails.forEach((element, i) => {
          if (element.type !== null) {
            Type.push(element.type);
          }
        });
        CoveringDetails.forEach((element, i) => {
          if (element.sku_type === 'M') {
            Opacity.push(element.value_2);
          }
        });
        CoveringDetails.forEach((element, i) => {
          if (element.sku_type === 'M') {
            Fabric.push(element.material);
          }
        });
        CoveringDetails.forEach((element, i) => {
          if (element.sku_type === 'L') {
            if (lift) {
              if (element.value_1 === lift) {
                Directions.push(element.value_2);
              }
            } else {
              Directions.push(element.value_2);
            }
            if (direction) {
              if (element.value_2 === direction) {
                LiftSystems.push(element.value_1);
              }
            } else {
              LiftSystems.push(element.value_1);
            }
            LiftSystemDetails.push(element);
          }
        });
        CoveringDetails.forEach((element, i) => {
          if (element.sku_type === 'P') {
            OptionsAvailable.push(element.value_1);
            OptionsAvailableDetails.push(element);
          }
        });

        setCategoryNames(covering_category.filter(unique));
        setLabelNames(Label.filter(unique));
        setBrandLabel(Label.filter(unique));
        setBrands(Brand.filter(unique));

        setbrandLabelProduct(Product.filter(unique));
        setbrandLabelProductType(Type.filter(unique));

        setLiftSystem(LiftSystems.filter(unique));
        setLiftSytemDetails(LiftSystemDetails.filter(unique));
        setSelectedLiftSytemDetails(LiftSystemDetails.filter(unique));
        setbrandLabelProductTypeOpacityFabricLiftDirection(Directions.filter(unique));
        setbrandLabelProductTypeOpacity(Opacity.filter(unique));
        setbrandLabelProductTypeOpacityFabric(Fabric.filter(unique));
        setOptionName(OptionsAvailable.filter(unique));
        setSelectedOptionDetails(OptionsAvailableDetails.filter(unique));
        if (brand && product && label && type) {
        }
      }
    }
  }, [CoveringSearchResults, CoveringDetails]);

  const jsonData = { brand, label, product, type };

  const [notesContent, setnotesContent] = useState([]);
  const [notesSuccessMsg, setNotesSuccessMsg] = useState(false);
  const [notesAlertMsg, setNotesAlertMsg] = useState('');

  const handleNotesClose = () => setNotesSuccessMsg(false);

  useEffect(() => {
    let proposal_specific_factor = {};
    switch (measurementType) {
      case 'Blinds':
        proposal_specific_factor = {
          WindowDepth: windowdepth,
          HeadrailType: headrail,
          LeftShade: leftshade_size,
          CenterShade: centershade_size,
          RightShade: rightshade_size,
          Obstructions: obstructions,
          Description: description,
          Othermeasurement: othermeasurement,
        };
        break;
      case 'Quick':
        proposal_specific_factor = {
          WindowDepth: windowdepth,
          Obstructions: obstructions,
          Othermeasurement: othermeasurement,
        };
        break;
      case 'Soft Shades':
        proposal_specific_factor = {
          HeadrailType: headrail,
          LeftShade: leftshade_size,
          CenterShade: centershade_size,
          RightShade: rightshade_size,
          WindowDepth: windowdepth,
          Obstructions: obstructions,
          Description: description,
          Othermeasurement: othermeasurement,
        };
        break;
      case 'Roller Shades':
        proposal_specific_factor = {
          Multiple: multiple,
          Rolldirection: rolldirection,
          WindowDepth: windowdepth,
          Obstructions: obstructions,
          Description: description,
          Othermeasurement: othermeasurement,
        };
        break;
      case 'Shutters':
        proposal_specific_factor = {
          Panel: Panel,
          Windowsill: Windowsill,
          DeadMold: DeadMold,
          Buildout: Buildout,
          FillerStrip: FillerStrip,
          SplitTilt: SplitTilt,
          SplitRail: SplitRail,
          TPost: TPost,
          WindowDepth: windowdepth,
          Obstructions: obstructions,
          Description: description,
          Othermeasurement: othermeasurement,
        };
        break;
      default:
        proposal_specific_factor = {};
        break;
    }
  }, [measurementType, windowdepth, headrail, leftshade_size, centershade_size, rightshade_size, obstructions, Panel, Windowsill, DeadMold, Buildout, FillerStrip, SplitTilt, SplitRail, TPost, othermeasurement, description]);

  const filteredCoveringsDetails = {
    wcc: windowCoveringCategory,
    brand: brand,
    label: label,
    product: product,
    type: type,
    opacity: opacity,
    fabric: fabric,
    lift: lift,
    direction: direction,
    option: option,
    ProductDescription: ProductDescription,
    SKUCombinations: SKUCombinations,
    showInTable: showInTable,
    SelectedFabricSKU: selectedFabricSKU,
    SelectedOptionSKU: SelectedOptionSKU,
    SelectedLiftSytemSKU: selectedLiftSytemSKU,
    sendmsg: sendmsg,
    windowData: [windowData],
    OptionalFinalizationFields: OptionalFinalizationFields || {},
    selectedOptionJSON: selectedOptionJSON,
    checkedItems: checkedItems,
    clearfilters: clearfilters,
    saveStatus: saveStatus,
    existingCovering: existingCovering,
    buildType: buildType,
    measurementType: measurementType,
    mountsurface: mountSurface,
    ladder: ladder,
    outgoingwindow_width: outgoingwindow_width,
    outgoingwindow_height: outgoingwindow_height,
    size: size ? size : 'Multiple',
    psf: psf,
    proposalAdditionalSKU: proposalAdditionalSKU,
    SelectedWindow: location.state.selectedwindows,
    WindowConfiguration: Panel,
    Windowsill: Windowsill,
    VerticalConfiguration: VerticalConfiguration,
    HeightForVerticalConfiguration: HeightForVerticalConfiguration,
    SectionOneTopHeigth: SectionOneTopHeigth,
    SectionTwoBottomWidth: SectionTwoBottomWidth,
    RSWindowConfiguration: multiple,
    totalWidth: totalWidth,
    PDnew: [],
    quickapply: quickapply,
    windowdepth: windowdepth,
    obstructions: obstructions,
  };

  const [isImageHovered, setIsImageHovered] = useState(false);
  const handleHover = () => setIsImageHovered(!isImageHovered);

  const [isImageHovered2, setIsImageHovered2] = useState(false);
  const handleHover2 = () => setIsImageHovered2(!isImageHovered2);

  const [editingNote, setEditingNote] = useState(false);
  const [noteDrawerDetails, setNoteDrawerDetails] = useState({});
  const [notesDrawerOpen, setNotesDrawerOpen] = useState(false);

  const openNotesDrawer = (note) => {
    setEditingNote(!!note);
    setNoteDrawerDetails({
      firstName: location.state.proposaldetails.customerdata.first_name,
      lastname: location.state.proposaldetails.customerdata.last_name,
      referenceId: location.state.proposaldetails.proposal_id,
      noteId: note ? note.id : null,
    });
    setNotesDrawerOpen(true);
  };

  const closeNotesDrawer = (alert) => {
    setNotesDrawerOpen(false);
    setEditingNote(false);
    setNoteDrawerDetails({});

    if (alert.message === 'Note added successfully!' || alert.message === 'Note updated successfully!') {
      getNotes();
    }
  };

  const isDisabled = !(selectedFabricSKU && selectedLiftSytemSKU && AllOptionSelectedStatus);

  return (
    <ThemeProvider theme={mainTheme}>
      <Box sx={{ flexGrow: 1, marginBottom: '30px' }} p={0}>
        <div align='center'>
          <Stack spacing={2}>
            <Stack spacing={2} sx={{ paddingLeft: '20%' }}>
              {/* ApplyCoveringsErrorAlert */}
              <Snackbar className='snackbar_bar_postition' open={ApplyCoveringsErrorAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setApplyCoveringsErrorAlert(false)}>
                <Alert className='Error1 mainAlert' variant='filled' icon={false}>
                  <div className='alertCancel_2'>
                    <img
                      onClick={() => {
                        setApplyCoveringsErrorAlert(false);
                      }}
                      src={RedDrawerCancelIcon}
                      alt='CANCELICONACA'
                      className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                    />
                  </div>
                  <div className='alertMain_2_4 confirmIconStyle1'>
                    <img src={RedExclamationIcon} alt='CONFIRMICONACA' className='primary_icons_dimension alertImageMargin' />
                    <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{ApplyCoveringsErrorMsg}</div>
                  </div>
                </Alert>
              </Snackbar>

              {/* ProposalByProposalIdErrorAlert */}
              <Snackbar className='snackbar_bar_postition' open={ProposalByProposalIdErrorAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setProposalByProposalIdErrorAlert(false)}>
                <Alert className='Error1 mainAlert' variant='filled' icon={false}>
                  <div className='alertCancel_2'>
                    <img
                      onClick={() => {
                        setProposalByProposalIdErrorAlert(false);
                      }}
                      src={RedDrawerCancelIcon}
                      alt='CANCELICONREDPBPA'
                      className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                    />
                  </div>
                  <div className='alertMain_2_4 confirmIconStyle1'>
                    <img src={RedExclamationIcon} alt='CONFIRMICONREDPBPA' className='primary_icons_dimension alertImageMargin' />
                    <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{ProposalByProposalIdErrorMsg}</div>
                  </div>
                </Alert>
              </Snackbar>

              <Snackbar className='snackbar_bar_postition' open={openupdatewindowname} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setUpdateWindownameOpen(false)}>
                {classtype === 'Success2 mainAlert' ? (
                  <Alert className={classtype} variant='filled' icon={false}>
                    <>
                      <div className='alertCancel_2'>
                        <img
                          alt='CANCELICONUWNA'
                          onClick={() => {
                            setUpdateWindownameOpen(false);
                          }}
                          src={DrawerCancelIcon}
                          className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                        />
                      </div>
                      <div className='alertMain_2_4 confirmIconStyle1'>
                        <img alt='CONFIRMICONUWNA' src={DrawerConfirmIcon} className='primary_icons_dimension alertImageMargin' />
                        <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{successmessage}</div>
                      </div>
                    </>
                  </Alert>
                ) : (
                  <Alert className={classtype} variant='filled' icon={false}>
                    <>
                      <div className='alertCancel_2'>
                        <img
                          alt='CANCELICONREDUWNA'
                          onClick={() => {
                            setUpdateWindownameOpen(false);
                          }}
                          src={RedDrawerCancelIcon}
                          className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                        />
                      </div>
                      <div className='alertMain_2_4 confirmIconStyle1'>
                        <img alt='CONFIRMICONREDUWNA' src={DrawerConfirmIcon} className='primary_icons_dimension alertImageMargin' />
                        <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{RedExclamationIcon}</div>
                      </div>
                    </>
                  </Alert>
                )}
              </Snackbar>
            </Stack>

            <Snackbar className='snackbar_bar_postition' open={notesSuccessMsg} anchorOrigin={{ vertical, horizontal }} autoHideDuration={5000} onClose={() => setNotesSuccessMsg(false)}>
              <Alert className='Success2 mainAlert' variant='filled' icon={false}>
                <>
                  <div className='alertCancel_2'>
                    <img alt='CANCELICONNOTES' onClick={handleNotesClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                  </div>
                  <div className='alertMain_2_4 confirmIconStyle1'>
                    <img alt='CONFIRMICONNOTES' src={DrawerConfirmIcon} className='primary_icons_dimension alertImageMargin' />

                    <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{notesAlertMsg}</div>
                  </div>
                </>
              </Alert>
            </Snackbar>
          </Stack>
          <Snackbar className='snackbar_bar_postition' open={saveStatus} anchorOrigin={{ vertical, horizontal }} onClose={() => setSaveStatus(false)} sx={{ zIndex: '100000000' }}>
            <Alert className='Success mainAlert' variant='filled' icon={false} sx={{ zIndex: '100000000' }}>
              <div className='alertCancel' align='right'>
                <img alt='CANCELICON' onClick={handleClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
              </div>

              <div className='alertMainNew'>
                <div align='left' style={{ paddingTop: 37 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'Center',
                    }}
                  >
                    <Box className='alertCantinueButton'>
                      <img alt='CONFIRMICON' src={DrawerAlertIcon} className='primary_icons_dimension' />
                    </Box>
                  </Box>

                  <div
                    style={{
                      paddingTop: 20,
                      paddingLeft: '26px',
                      fontSize: '20px',
                      fontFamily: 'RobotoRegular',
                    }}
                  >
                    Any required fields left empty will revert to the previous selection. Okay to continue?
                  </div>
                </div>

                <div
                  align='left'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '20px 10px 0px 24px',
                    justifyContent: 'center',
                    alignItems: 'start',
                    lineHeight: '24px',
                  }}
                >
                  <Box
                    onClick={() => handleContinue()}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'Center',
                      cursor: 'pointer',
                      marginBottom: '20px',
                    }}
                  >
                    <Box className='alertCantinueButton'>
                      <img alt='CONTINUE' src={DrawerContinueIcon} className='primary_icons_dimension' />
                    </Box>
                    <Typography className='globalTextForDrawer' sx={{ marginLeft: '12px' }}>
                      CONTINUE
                    </Typography>
                  </Box>

                  <Box
                    onClick={handleClose}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'Center',
                      cursor: 'pointer',
                      paddingBottom: '16px',
                      paddingLeft: '15px',
                    }}
                  >
                    <Box className='globalIconMainBox'>
                      <Box className='iconSecondaryButtonGlobalForDrawer'>
                        <img alt='CANCEL' src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                      </Box>
                      <Typography className='globalTextForDrawer'>CANCEL</Typography>
                    </Box>
                  </Box>
                </div>
              </div>
            </Alert>
          </Snackbar>

          <ShowProposalName proposaldetails={location.state.proposaldetails} />

          <img
            style={{
              width: '20px',
              color: '#008593',
              cursor: 'pointer',
              position: 'relative',
              float: 'right',
              top: '-55px',
            }}
            src={NoteMiscIcon}
            className='misc_icons_dimensions'
            onClick={() => openNotesDrawer()}
          />

          {notesContent.map((note, index) => (
            <Box sx={{ display: 'flex' }}>
              {note.visible ? <VisibilityIcon sx={{ color: '#008593', paddingRight: '10px' }} /> : null}{' '}
              <Box>
                <Typography onClick={() => openNotesDrawer(note)}>
                  {' '}
                  <span
                    style={{
                      color: '#008593',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                    }}
                  >
                    Note #{index + 1} :
                  </span>{' '}
                  {note.content}
                </Typography>
              </Box>
            </Box>
          ))}

          <Box className='button_top_space' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '30px' }}>
            <Stack direction='row'>
              <Box>
                <Stack direction='row'>
                  <img alt='APPLYCOVERING' className='primary_icons_dimension_1 stepperImageMargins' src={ApplyCoveringIcon2} />
                </Stack>
              </Box>
              <Box sx={{ borderLeft: '1px solid #D6D6D6', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography className='stepperMainTextSize stepperSubTextStyle'>Edit Window Coverings {windowsComparison}</Typography>
              </Box>
            </Stack>
          </Box>
        </div>

        <Grid container rowSpacing={2} p={0} direction='row'>
          <Grid item xs={0} sm={5} md={4} lg={3} display={{ xs: 'none', lg: 'block', md: 'block', sm: 'block' }}>
            {console.log(filteredCoveringsDetails)}
            <CoveringDrawer2 func2={setData} data={filteredCoveringsDetails} />
          </Grid>
          <Grid item xs={0} sm={0.5} md={0.5} lg={0.25} display={{ xs: 'none', lg: 'block', md: 'block', sm: 'block' }}>
            &nbsp;
          </Grid>
          <Grid item xs={12} sm={6.5} md={7.5} lg={8.75}>
            <Box component='main' className='apply_covering_box'>
              <Drawer
                classes={{ paper: classes.paper }}
                PaperProps={{
                  sx: { width: '90%' },
                }}
                BackdropProps={{
                  classes: {
                    root: classes.backDrop,
                  },
                }}
                anchor='right'
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(true)}
                className='drawer_Header_page'
              >
                <CoveringDrawer key={saveStatus} func={setData} data={filteredCoveringsDetails} />
              </Drawer>

              <ThemeProvider theme={notesDrawerTheme}>
                <Drawer
                  classes={{ paper: classes.paper }}
                  PaperProps={{
                    sx: { width: '90%' },
                  }}
                  BackdropProps={{
                    classes: {
                      root: classes.backDrop,
                    },
                  }}
                  anchor='right'
                  open={notesDrawerOpen}
                  onClose={() => setNotesDrawerOpen(false)}
                  className='drawer_Header_page'
                >
                  <NotesDrawer closeDrawer={closeNotesDrawer} editing={editingNote} type='proposalLineItem' details={noteDrawerDetails} />
                </Drawer>
              </ThemeProvider>

              <Grid
                item
                xs={12}
                sm={0}
                md={0}
                lg={0}
                display={{
                  xs: 'block',
                  lg: 'none',
                  md: 'none',
                  sm: 'none',
                }}
              >
                <Box>
                  <span className='gray_button_span button_top_space'>
                    <Box className='globalIconMainBox' onClick={resetFilters}>
                      <img alt='CLEARFILTERS' className={`secondary_icons_dimension ${isImageHovered ? 'hovered' : ''}`} src={FilterIcon} />
                      <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover} onMouseLeave={handleHover}>
                        {' '}
                        CLEAR FILTERS
                      </Typography>
                    </Box>
                  </span>

                  <hr className='button_top_space button_bottom_space' />

                  <Box className='container small'>
                    <span className='button_span' onClick={() => setIsDrawerOpen(true)}>
                      <Box className='globalIconMainBox'>
                        <img alt='SELECT/EDITPRODUCTOPTIONS' src={ProposalIcon} className='primary_icons_dimension' />
                        <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                          {' '}
                          SELECT/EDIT PRODUCT OPTIONS
                        </Typography>
                      </Box>
                    </span>
                  </Box>
                </Box>
              </Grid>

              {/* Window Details */}
              <WindowDetails windowDetails={windowDetails} />

              <Box className='show_Sku_Box_Proposal_Apply_Covering_Page'>
                <Box className='show_Sku_Sub_Box_Proposal_Apply_Covering_Page'>
                  <hr className='button_top_space button_bottom_space' />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    display={{
                      xs: 'block',
                      lg: 'block',
                      md: 'block',
                      sm: 'block',
                    }}
                  >
                    <h3 className='proposal_result_header'>Covering System :</h3>

                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={0} columns={2}>
                        <Grid item xs={2} sm={1}>
                          <Item>
                            {windowCoveringCategory === '' ? (
                              ''
                            ) : (
                              <>
                                Category :{' '}
                                <Typography variant='h7' className='proposal_result_header'>
                                  {windowCoveringCategory}
                                </Typography>
                                <br />
                              </>
                            )}
                            {brand === '' ? (
                              ''
                            ) : (
                              <>
                                Brand :{' '}
                                <Typography variant='h7' className='proposal_result_header'>
                                  {brand}
                                </Typography>
                                <br />
                              </>
                            )}
                            {label === '' ? (
                              ''
                            ) : (
                              <>
                                Label :{' '}
                                <Typography variant='h7' className='proposal_result_header'>
                                  {label}
                                </Typography>
                                <br />
                              </>
                            )}
                            {product === '' ? (
                              ''
                            ) : (
                              <>
                                Product :{' '}
                                <Typography variant='h7' className='proposal_result_header'>
                                  {product}
                                </Typography>
                                <br />
                              </>
                            )}
                            {type === '' ? (
                              ''
                            ) : (
                              <>
                                Type :{' '}
                                <Typography variant='h7' className='proposal_result_header'>
                                  {type}
                                </Typography>
                                <br />
                              </>
                            )}
                          </Item>
                        </Grid>
                        <Grid item xs={2} sm={1}>
                          <Item>
                            {fabric === '' ? (
                              ''
                            ) : (
                              <>
                                Material :{' '}
                                <Typography variant='h7' className='proposal_result_header'>
                                  {fabric}
                                </Typography>
                                <br />
                              </>
                            )}
                            {opacity === '' ? (
                              ''
                            ) : (
                              <>
                                {windowCoveringCategory === 'Blinds' || windowCoveringCategory === 'Shutters' ? <>Louver Size : </> : <>Opacity : </>}

                                <Typography variant='h7' className='proposal_result_header'>
                                  {opacity}
                                </Typography>
                                <br />
                              </>
                            )}
                            {lift === '' ? (
                              ''
                            ) : (
                              <>
                                Lift System :{' '}
                                <Typography variant='h7' className='proposal_result_header'>
                                  {lift}
                                </Typography>
                                <br />
                              </>
                            )}
                            {direction === '' ? (
                              ''
                            ) : (
                              <>
                                Direction :{' '}
                                <Typography variant='h7' className='proposal_result_header'>
                                  {direction}
                                </Typography>
                                <br />
                              </>
                            )}
                            {Object.keys(selectedOptionJSON).length > 0 ? (
                              <>
                                {Object.entries(selectedOptionJSON).map(([key, value]) =>
                                  value.name !== ' ' ? (
                                    <div>
                                      <span>{key} : </span>
                                      <Typography variant='h7' className='proposal_result_header'>
                                        {value.name}
                                      </Typography>
                                    </div>
                                  ) : (
                                    ''
                                  )
                                )}
                              </>
                            ) : (
                              ''
                            )}
                          </Item>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Box>
              </Box>
              <hr className='button_top_space button_bottom_space' />

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '16px',
                }}
              >
                <span className={`button_span ${!confirmable ? 'button_span_notes_disabled' : ''} padding_Continue_Button_Proposal_Apply_Covering_Page${!confirmable ? '_2' : ''}`}>
                  <FormControlLabel
                    className='confirm-checkbox'
                    label={<span style={{ fontSize: '18px', fontFamily: 'RobotoLight', marginLeft: '8px' }}>&nbsp;CONFIRM FOR SALES ORDER</span>}
                    control={
                      <Checkbox disabled={!confirmable} sx={{ padding: '0px !important' }} style={{ transform: 'scale(1.2)', padding: '0px !important' }} checked={markasconfirmedSalesOrderStatus} onChange={confirmable ? (e) => setMarkAsConfirmSalesOrderStatus(e.target.checked) : undefined} />
                    }
                  />
                </span>
              </Box>
              <hr className='button_top_space button_bottom_space' />

              <Box className='continue_Button_Box_Proposal_Apply_Covering_Page '>
                <Button
                  className={`${isDisabled ? 'button_span_disabled' : 'button_span'} padding_Continue_Button_Proposal_Apply_Covering_Page_1`}
                  onClick={applyCoveringOptions}
                  onMouseEnter={handleHoverFun}
                  onMouseLeave={handleMouseLeaveFun}
                  sx={{ justifyContent: 'left', paddingLeft: 0, width: 'fit-content' }}
                  disabled={isDisabled}
                >
                  <Box className='globalIconMainBox globalMarginLeftAddWindow'>
                    <img alt='Apply Covering' className={isDisabled ? 'primary_icons_dimension_disable' : 'primary_icons_dimension_1'} src={isHovered && isDisabled ? ApplyCoveringHovIcon : ApplyCoveringIcon} />
                    <Typography sx={{ fontFamily: 'RobotoLight' }} className={isDisabled ? 'globalTextDisabled' : 'globalText'}>
                      APPLY OPTIONS
                    </Typography>
                  </Box>
                </Button>

                <span className='gray_button_span' style={{ paddingBottom: '30px', paddingLeft: '23px' }}>
                  <Box onClick={send} className='globalIconMainBox'>
                    <img alt='CANCEL' src={CancelIcon} className={`secondary_icons_dimension ${isImageHovered2 ? 'hovered' : ''}`} />
                    <Typography onMouseEnter={handleHover2} onMouseLeave={handleHover2} x sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                      CANCEL
                    </Typography>
                  </Box>
                </span>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
