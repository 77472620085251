import { useContext, useState, useEffect } from 'react';
import { Button, Container, Typography } from '@mui/material';
import DropDown from './DropDown';

import CONFIG from '../../config/config.js';
import { ProductContext, ProductDispatchContext } from './ProductProvider';
import FilterIcon from '../../assets/icons/secondary/2nd-filter.svg';

export function ProductOptions() {
  const product = useContext(ProductContext);
  const dispatch = useContext(ProductDispatchContext);
  const [productOptions, setProductOptions] = useState({});
  const [isButtonHovered, setIsButtonHovered] = useState(false);

  useEffect(() => {
    getProductOptions();
  }, []);

  const hoverButton = () => setIsButtonHovered(!isButtonHovered);

  const getProductOptions = async () => {
    const token = sessionStorage.getItem('token');
    const options = {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      token: token,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    };

    const response = await fetch(`${CONFIG.API_URL}/pm/csp-list/material`, options);
    const result = await response.json();
    setProductOptions(result);
  };

  const reset = () => dispatch({ type: 'RESET' });

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '0',
        '&.MuiContainer-root': {
          padding: '0',
        },
      }}
    >
      <Button
        className='gray_button_span'
        onMouseEnter={hoverButton}
        onMouseLeave={hoverButton}
        onClick={reset}
        sx={{
          margin: '1em 0',
          justifyContent: 'left',
        }}
      >
        <img alt='Reset Filters' className={`secondary_icons_dimension ${isButtonHovered ? 'hovered' : ''}`} src={FilterIcon} />

        <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
          CLEAR FILTERS
        </Typography>
      </Button>
      <hr />
      <DropDown options={productOptions.category} value={product.category} onChange={dispatch} startAdornment={<span style={{ color: '#DB3131' }}>&nbsp;*</span>} label='Category' />
      <DropDown options={productOptions.brand} value={product.brand} onChange={dispatch} startAdornment={<span style={{ color: '#DB3131' }}>&nbsp;*</span>} label='Brand' />
      <DropDown options={productOptions.label} value={product.label} onChange={dispatch} startAdornment={<span style={{ color: '#DB3131' }}>&nbsp;*</span>} label='Label' />
      <DropDown options={productOptions.product} value={product.product} onChange={dispatch} startAdornment={<span style={{ color: '#DB3131' }}>&nbsp;*</span>} label='Product' />
      <DropDown options={productOptions.type} value={product.type} onChange={dispatch} startAdornment={<span style={{ color: '#DB3131' }}>&nbsp;*</span>} label='Type' />
      <hr />
      <DropDown options={productOptions.material} value={product.material} onChange={dispatch} startAdornment={<span style={{ color: '#DB3131' }}>&nbsp;*</span>} label='Material' />
      <DropDown options={productOptions.opacity} value={product.opacity} onChange={dispatch} startAdornment={<span style={{ color: '#DB3131' }}>&nbsp;*</span>} label='Opacity' />
      <DropDown options={productOptions.liftsystem} value={product.liftsystem} onChange={dispatch} startAdornment={<span style={{ color: '#DB3131' }}>&nbsp;*</span>} label='Lift System' />
      <DropDown options={productOptions.direction} value={product.direction} onChange={dispatch} startAdornment={<span style={{ color: '#DB3131' }}>&nbsp;*</span>} label='Direction' />
    </Container>
  );
}

export default ProductOptions;
