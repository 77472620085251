import React from 'react';
import TextField from '@mui/material/TextField';

export const Measurements = ({ label, value, disabled, onClick, sx }) => {
  return (
    <TextField
      id={label}
      label={label}
      variant='outlined'
      required
      disabled={disabled}
      value={value}
      onClick={!disabled ? onClick : null}
      InputProps={{
        style: {
          fontWeight: 'bold',
          color: window.size === 'Multiple' ? '#B53030' : 'inherit',
          fontFamily: 'Roboto',
        },
        readOnly: true,
      }}
      sx={{
        margin: '10px 0',
        width: '100%',
        ...(sx ? sx : {}),
        '.MuiInputBase-input': {
          WebkitTextFillColor: 'inherit',
          cursor: 'pointer',
        },
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: value === 'Multiple' ? '#B53030' : 'inherit',
          cursor: 'not-allowed',
        },
      }}
    />
  );
};

export default Measurements;
