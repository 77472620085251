import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Container, Drawer, Grid, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import fracty from 'fracty';

import Header from './Header';
import Footer from './Footer';
import Backspace from '../../../img/backspace.png';
import X from '../../../img/delete-key-symbol.png';
import DrawerMeasurementIcon from '../../../assets/icons/drawer-alert/drw-measurement.svg';

const useStyles = makeStyles({
  paper: {
    width: '90%',
  },
});

export function MeasurementDrawer({ window, isOpen, onClose, save, label = '' }) {
  const classes = useStyles();
  const [measurement, setMeasurement] = useState('');
  const [lastInput, setLastInput] = useState('');
  const [includesFraction, setIncludesFraction] = useState(false);

  const updateMeasurement = ({ target }) => {
    const { name: input } = target;
    let current = measurement[measurement.length - 1] === '"' ? measurement.slice(0, -1) : measurement;
    let [width, height] = current.split('" X ').map((x) => x.trim());

    if (input === 'back' && !current) {
      return;
    }

    if (/^[0-9]$/.test(input) && !includesFraction) {
      if (height !== undefined) {
        height += input;
        setMeasurement(`${width}" X ${height}"`);
      } else {
        width += input;
        setMeasurement(`${width}"`);
      }
      setLastInput(input);
      return;
    }

    if (input.includes('.') && !includesFraction) {
      if (height !== undefined) {
        height += ` ${fracty(input)}`;
        setMeasurement(`${width}" X ${height}"`);
        setIncludesFraction(height.includes('/'));
        setLastInput(` ${fracty(input)}`);
      } else {
        width += ` ${fracty(input)}`;
        setMeasurement(`${width}"`);
        setIncludesFraction(width.includes('/'));
        setLastInput(` ${fracty(input)}`);
      }
      return;
    }

    if (input === 'X') {
      if (current === '') {
        return;
      }
      if (current.includes('X')) {
        return;
      }
      setMeasurement(`${width}" X `);
      setLastInput('" X ');
      setIncludesFraction(false);
      return;
    }

    if (input === 'back') {
      if (lastInput === '" X ') {
        setMeasurement(width);
        const [whole, fraction] = width.split(' ');
        setIncludesFraction(width.includes('/'));
        setLastInput(fraction ? ` ${fraction}` : whole.split('').findLast((digit) => /\d/.test(digit)));
        return;
      }
      if (height) {
        height = height.slice(0, -lastInput.length);
        if (height === '') {
          setMeasurement(`${width}" X `);
          setLastInput('" X ');
          setIncludesFraction(false);
          return;
        }
        setMeasurement(`${width}" X ${height}"`);
        setIncludesFraction(height.includes('/'));
        setLastInput(height.split('').findLast((digit) => /\d/.test(digit)));
        return;
      } else {
        width = width.slice(0, -lastInput.length);
        setMeasurement(width);
        setLastInput(width.split('').findLast((digit) => /\d/.test(digit)));
        setIncludesFraction(width.includes('/'));
        return;
      }
    }
  };

  const close = () => {
    setMeasurement('');
    setLastInput('');
    setIncludesFraction(false);
    onClose();
  };

  const getPreviousMeasurement = () => {
    if (label === 'Width X Height') {
      return window.size;
    }
    if (label === 'DEPTH') {
      return window.window_depth;
    }
    if (label.includes('SECTION')) {
      return window.sections[label.toLowerCase().split(' ').join('_')];
    }
    if (label === 'TOP') {
      return window.verticalSections.top;
    }
    if (label === 'BOTTOM') {
      return window.verticalSections.bottom;
    }
  };

  const saveMeasurement = () => {
    if (label === 'Width X Height') {
      if (!measurement.includes('X')) {
        return;
      }

      let [width, height] = measurement.slice(0, -1).split('" X ');
      width = fractionToDecimal(width);
      height = fractionToDecimal(height);
      save({
        action: `SET_SIZE`,
        payload: {
          width,
          height,
          size: measurement,
        },
      });
    }
    if (label === 'Depth') {
      save({
        action: `SET_DEPTH_MEASUREMENT`,
        payload: parseFloat(measurement.slice(0, -1)),
      });
    }
    if (label.includes('Section')) {
      if (!isValidSectionWidth(measurement, window)) {
        return;
      }

      save({
        action: `SET_SECTION_MEASUREMENT`,
        payload: {
          [label.toLowerCase().split(' ').join('')]: parseFloat(measurement.slice(0, -1)),
        },
      });
    }

    if (label === 'Top') {
      save({
        action: 'SET_TOP',
        payload: parseFloat(measurement.slice(0, -1)),
      });
    }
    close();
  };

  const fractionToDecimal = (measurement) => {
    if (measurement.includes('/')) {
      const [whole, fraction] = measurement.split(' ');
      const [numerator, denominator] = fraction.split('/');
      return +whole + +numerator / +denominator;
    }
    return +measurement;
  };

  return (
    <Drawer
      PaperProps={{
        sx: {
          backdropFilter: 'blur(10px)',
          background: 'rgba(0, 133, 147, 0.73)',
          width: { md: '50vw', xs: '90vw' },
        },
      }}
      slotProps={{
        backdrop: {
          classes: {
            root: classes.backDrop,
          },
        },
      }}
      anchor='right'
      open={isOpen}
      onClose={close}
      sx={{ zIndex: '1000000' }}
    >
      <Header closeDrawer={close} />
      <Container maxWidth='md'>
        <MeasurementInputViewer placeholder={label} value={measurement} isFinal={window.measurementType !== 'quick' || window.measurementType !== ''} previousMeasurement={getPreviousMeasurement()} />
        <InputGrid update={updateMeasurement} label={label} />
        {!(window.measurementType === 'Quick' || window.measurementType === '') ? <FractionalInputs update={updateMeasurement} /> : null}
      </Container>
      <Footer cancel={close}>
        <Button onClick={saveMeasurement} sx={{ justifyContent: 'left' }}>
          <img alt='Apply Measurement' src={DrawerMeasurementIcon} className='primary_icons_dimension' />
          <Typography
            sx={{
              width: 'content-fit',
              marginLeft: '30px',
              fontFamily: 'RobotoLight',
              textAlign: 'start',
            }}
            className='drawerGlobalText'
          >
            APPLY MEASUREMENT
          </Typography>
        </Button>
      </Footer>
    </Drawer>
  );
}

export default MeasurementDrawer;

function MeasurementInputViewer({ value, placeholder, onChange, previousMeasurement = '', isFinal }) {
  return (
    <Container sx={{ textAlign: 'center', marginBottom: '.75em' }}>
      {isFinal ? (
        <h2 className='add_specific_fontcolor button_bottom_space'>{`${placeholder === 'Width X Height' ? 'Final Measurement' : 'Measurement'}`}</h2>
      ) : (
        <h2 className='add_specific_fontcolor button_bottom_space'>{`${placeholder === 'Width X Height' ? 'Quick Measurement' : 'Measurement'}`}</h2>
      )}
      <TextField
        id='filled-basic'
        readOnly
        label={placeholder}
        inputProps={{
          sx: {
            textAlign: 'center',
            fontSize: 'x-large',
            backgroundColor: '#fff',
            borderRadius: '5px',
          },
          readOnly: true,
        }}
        sx={{
          width: '100%',
          margin: '1em 0',
        }}
        value={value}
        onChange={onChange}
        variant='outlined'
      />
      {previousMeasurement !== '' ? (
        <Typography
          sx={{
            color: '#fff',
          }}
        >
          Previous Measurement: {previousMeasurement}
        </Typography>
      ) : null}
    </Container>
  );
}

function InputGrid({ update, label }) {
  const numberButtons = [7, 8, 9, 4, 5, 6, 1, 2, 3, 0];

  return (
    <Container maxWidth='sx' sx={{ maxWidth: '310px' }}>
      <Grid container spacing={2} padding={2} columns={3}>
        {numberButtons.map((number) => (
          <Grid
            item
            xs={1}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <InputButton update={update} value={number}>
              {number}
            </InputButton>
          </Grid>
        ))}
        {label === 'Width X Height' ? (
          <Grid
            item
            xs={1}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <InputButton update={update} value='X'>
              <img
                src={X}
                name='X'
                className='buttonIcon'
                style={{
                  width: '45px',
                  height: '40px',
                  position: 'absolute',
                }}
                alt='delete'
              />
            </InputButton>
          </Grid>
        ) : null}
        <Grid
          item
          xs={label === 'Width X Height' ? 1 : 2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <InputButton update={update} large={label !== 'Width X Height'} value='back'>
            <img
              src={Backspace}
              name='back'
              className='buttonIcon'
              style={{
                width: '45px',
                height: '40px',
                position: 'absolute',
              }}
              alt='back'
            />
          </InputButton>
        </Grid>
      </Grid>
    </Container>
  );
}

function InputButton({ update, fraction, large, value, children }) {
  return (
    <Button
      className={!fraction ? 'drawer_input_buttons_style_1' : 'drawer_input_buttons_style2'}
      sx={{
        minWidth: large ? '100%' : '50px',
        whiteSpace: 'nowrap',
      }}
      onClick={update}
      name={value}
    >
      {children}
    </Button>
  );
}

function FractionalInputs({ update }) {
  const eigthFractions = fractions(1 / 8, 1 / 8, 7);
  const sixteenthFractions = fractions(1 / 16, 1 / 16, 15).filter((n) => !eigthFractions.includes(n));

  return (
    <>
      <Container
        spacing={2}
        sx={{
          marginTop: '10px',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            marginTop: '1em',
            gap: '.5em',
            width: '87%',
          }}
        >
          {eigthFractions.slice(0, 4).map((fraction, index) => (
            <InputButton key={fraction} update={update} value={fraction} fraction>
              {fracty(fraction)}
            </InputButton>
          ))}

          {eigthFractions.slice(4).map((fraction, index) => (
            <InputButton key={fraction} update={update} value={fraction} fraction>
              {fracty(fraction)}
            </InputButton>
          ))}
        </Container>
      </Container>
      <Accordion
        sx={{
          marginTop: '2em',
          width: '100%',
          border: '1px solid #fff',
          backgroundColor: 'transparent',
        }}
      >
        <AccordionSummary
          classes={{
            content: 'drawer-accordion-summary',
          }}
          sx={{
            color: '#fff',
          }}
          expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
        >
          1 / 16
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            flexWrap: 'wrap',
            width: '79%',
            gap: '.5em',
            margin: 'auto',
          }}
        >
          {sixteenthFractions.map((fraction) => (
            <InputButton key={fraction} update={update} value={fraction} fraction>
              {formatFraction(fraction)}
            </InputButton>
          ))}
        </AccordionDetails>
      </Accordion>
    </>
  );
}

const fractions = (start, increment, count) => Array.from({ length: count }, (_, i) => start + i * increment);

const formatFraction = (num) => {
  const gcd = (a, b) => (b ? gcd(b, a % b) : a);
  const denominator = 16;
  const numerator = num * denominator;
  const commonDivisor = gcd(numerator, denominator);
  return `${numerator / commonDivisor}/${denominator / commonDivisor}`;
};

function isValidSectionWidth(newSectionWidth, { sections, width }) {
  if (newSectionWidth > width) {
    return false;
  }

  const totalWidth = Object.values(sections).reduce((acc, width) => acc + width, 0) + newSectionWidth;
  if (totalWidth > width) {
    return false;
  }

  return true;
}
