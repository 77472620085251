import React, { useState, useEffect, forwardRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { makeStyles } from '@mui/styles';
import { useLocation, useNavigate, matchRoutes } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import CONFIG from '../../config/config.js';
import Cookies from 'universal-cookie';
import HeaderUserIon from '../../assets/icons/secondary/hdft-account.svg';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Logo from '../../assets/icons/secondary/hdft-TBM-logo.svg';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../assets/icons/drawer-alert/drw-alert.svg';
import DrawerContinueIcon from '../../assets/icons/drawer-alert/drw-continue.svg';
import BackIcon from '../../assets/icons/secondary/2nd-back.svg';
import moment from 'moment/moment';
import DrawerLogoutIcon from '../../assets/icons/drawer-alert/drw-logout.svg';
import DrawerConinueIcon from '../../assets/icons/drawer-alert/drw-continue.svg';
import SettingsIcon from '../../assets/icons/primary/settings.svg';
import DBIcon from '../../assets/icons/primary/DB_Tools.svg';
import { Container } from '@mui/material';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const vertical = 'top';
const horizontal = 'center';
const useStyles = makeStyles({
  paper: {
    background: 'linear-gradient(to right, rgb(65,176,193), rgb(47,133,146))',
  },
  backDrop: {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
});

export default function Header() {
  const cookies = new Cookies();
  const location = useLocation();
  const navigate = useNavigate();
  const USER_NAME = sessionStorage.getItem('user');

  const [pageTitle, setPageTitle] = useState('The BlindMan');
  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [screenName, setScreenName] = useState('The BlindMan');
  const [show, setShow] = useState(true);
  const [showArrow, setShowArrow] = useState(true);
  const [deleteItem, setDeleteItem] = useState(false);

  const today = new Date();
  const todayDate = moment(today).local().format('MMMM DD, YYYY');

  const titleMap = [
    { path: '/', title: 'The BlindMan' },
    { path: '/login', title: 'The BlindMan' },
    { path: '/tbm/ps', title: 'The BlindMan' },
    {
      path: '/pagenotfound',
      title: 'Page Not Found',
      Name_of_Screen: 'Page Not Found',
    },
    {
      path: '*',
      title: 'Page Not Found',
      Name_of_Screen: 'Page Not Found',
    },
    {
      path: '/tbm/ps/addwindow',
      title: 'Window',
      Name_of_Screen: 'Creating a Window',
    },
    {
      path: '/tbm/ps/window',
      title: 'Window',
      Name_of_Screen: 'Window Details',
    },
    {
      path: '/tbm/ps/windowsets',
      title: 'Window Set',
      Name_of_Screen: 'Customer Details',
    },
    {
      path: '/tbm/ps/windowset/:id',
      title: 'Window Set',
      Name_of_Screen: 'Window Set Details',
    },
    {
      path: '/tbm/ps/createproposal',
      title: 'Proposal',
      Name_of_Screen: 'Creating Proposal',
    },
    {
      path: '/tbm/ps/editwindowset/:id',
      title: 'Window Set',
      Name_of_Screen: 'Editing Window Set ',
    },
    {
      path: '/tbm/ps/reorderwindowset/:id',
      title: 'Window Set',
      Name_of_Screen: 'Editing Window Set ',
    },
    {
      path: '/tbm/ps/deletewindow/:id',
      title: 'Window Set',
      Name_of_Screen: 'Editing Window Set ',
    },
    {
      path: '/tbm/ps/addwindow',
      title: 'Window',
      Name_of_Screen: 'Customer Details',
    },
    {
      path: '/tbm/ps/addwindowset',
      title: 'Window Set',
      Name_of_Screen: 'Customer Details',
    },
    {
      path: '/tbm/ps/createnewwindowset',
      title: 'Window Set',
      Name_of_Screen: 'Creating a Window Set',
    },
    {
      path: '/tbm/ps/proposal/:id/details',
      title: 'Proposal',
      Name_of_Screen: 'Customer Details',
    },
    {
      path: '/tbm/ps/editcustomeraddress',
      title: 'Customer',
      Name_of_Screen: 'Edit Customer Details',
    },

    {
      path: '/tbm/ps/editcustomeremail',
      title: 'Customer',
      Name_of_Screen: 'Edit Customer Details',
    },
    {
      path: '/tbm/ps/editcustomerphoneemail',
      title: 'Customer',
      Name_of_Screen: 'Edit Customer Details',
    },
    {
      path: '/tbm/ps/editcustomerphonenumber',
      title: 'Customer',
      Name_of_Screen: 'Edit Customer Details',
    },
    {
      path: '/tbm/ps/addnewphonenumber',
      title: 'Customer',
      Name_of_Screen: 'Edit Customer Details',
    },
    {
      path: '/tbm/ps/editcustomerfirstlastname',
      title: 'Customer',
      Name_of_Screen: 'Edit Customer Details',
    },
    {
      path: '/tbm/ps/customerdetails',
      title: 'Customer',
      Name_of_Screen: 'Customer Details',
    },
    {
      path: '/tbm/ps/existingcustomer',
      title: 'Customer',
      Name_of_Screen: 'Top 5 Customers',
    },
    {
      path: '/tbm/ps/proposalapplycovering/:id',
      title: 'Proposal',
      Name_of_Screen: 'Selecting Coverings',
    },
    {
      path: '/tbm/ps/proposalselectwindow/:id',
      title: 'Proposal',
      Name_of_Screen: 'Creating Proposal',
    },
    {
      path: '/tbm/ps/proposal/:id/details',
      title: 'Proposal',
      Name_of_Screen: 'Proposal Details',
    },
    {
      path: '/tbm/ps/duplicateproposaldetails/:id',
      title: 'Proposal',
      Name_of_Screen: 'Duplicate Proposal Details',
    },
    {
      path: '/tbm/ps/editmode/:id',
      title: 'Proposal',
      Name_of_Screen: 'Editing Proposal ',
    },
    {
      path: '/tbm/ps/editingwsproposal/:id',
      title: 'Proposal',
      Name_of_Screen: 'Editing Proposal',
    },
    {
      path: '/tbm/ps/editingswproposal/:id',
      title: 'Proposal',
      Name_of_Screen: 'Editing Proposal',
    },
    {
      path: '/tbm/ps/deletemode/:id',
      title: 'Proposal',
      Name_of_Screen: 'Editing Proposal',
    },
    {
      path: '/tbm/ps/proposal-batchedit/:id',
      title: 'Proposal',
      Name_of_Screen: 'Editing Proposal',
    },
    {
      path: '/tbm/ps/reordermode/:id',
      title: 'Proposal',
      Name_of_Screen: 'Editing Proposal',
    },
    {
      path: '/tbm/ps/editingwindowproposal',
      title: 'Proposal',
      Name_of_Screen: 'Editing Proposal',
    },
    {
      path: '/tbm/ps/batcheditingwindowcovering',
      title: 'Proposal',
      Name_of_Screen: 'Editing Proposal Details',
    },
    { path: '/dbtools/skulookup', title: 'SKU', Name_of_Screen: 'SKU Lookup' },
    {
      path: '/dbtools/pricinglookup',
      title: 'Pricing',
      Name_of_Screen: 'Pricing Lookup',
    },
    {
      path: '/tbm/ps/home',
      title: 'Home',
      Name_of_Screen: 'Home',
    },
    {
      path: '/tbm/ps/newcustomer',
      title: 'Customer',
      Name_of_Screen: 'New Customers',
    },
    {
      path: '/tbm/ps/proposal_id/:id',
      title: 'Proposal',
      Name_of_Screen: 'Preview Proposal',
    },
    {
      path: '/tbm/ps/systeminformation',
      title: 'System Information',
      Name_of_Screen: 'System Information',
    },
    {
      path: '/tbm/ps/selecteddateappointments',
      title: 'Appointments',
      Name_of_Screen: `${todayDate}`,
    },
    {
      path: '/tbm/ps/viewallappointments',
      title: 'Appointments',
      Name_of_Screen: 'View All Appointments',
    },
    {
      path: '/tbm/ps/dashboard',
      title: '',
      Name_of_Screen: `Hello ${USER_NAME} !`,
    },
    {
      path: '/tbm/ps/settings',
      title: 'Settings',
      Name_of_Screen: 'Settings',
    },
    {
      path: '/tbm/ps/searchCustomer/Customer',
      title: 'Customer',
      Name_of_Screen: 'Customer Search',
    },
    {
      path: '/tbm/ps/searchCustomer/searchresult',
      title: 'Customer',
      Name_of_Screen: 'Customer Search',
    },
    {
      path: '/tbm/ps/createnewcustomer',
      title: 'Customer',
      Name_of_Screen: 'New Customer',
    },
    {
      path: '/tbm/ps/salesorder',
      title: 'Sales Order',
      Name_of_Screen: 'Finalizing Line Items',
    },
    {
      path: '/tbm/ps/finalizesalesorder',
      title: 'Sales Order',
      Name_of_Screen: 'Sales Order Details',
    },
    {
      path: '/tbm/ps/salesorderaddingwindows',
      title: 'Sales Order',
      Name_of_Screen: 'Converting to Sales Order',
    },
    {
      path: '/tbm/ps/salesorderaddingandconfirmwindows',
      title: 'Sales Order',
      Name_of_Screen: 'ADDING WINDOWS',
    },
    {
      path: '/tbm/ps/editwindowdetailsinsalesorder',
      title: 'Proposal',
      Name_of_Screen: 'PROPOSAL DETAILS',
    },
    {
      path: '/tbm/ps/salesorderconfirm',
      title: 'Sales Order',
      Name_of_Screen: 'CONFIRM DETAILS',
    },
    {
      path: '/tbm/ps/ctspaymentdetails',
      title: 'Sales Order',
      Name_of_Screen: ' DETAILS',
    },
    {
      path: '/tbm/ps/generate-salesorder-pdf',
      title: 'Sales Order',
      Name_of_Screen: 'Preview Sales Order',
    },
    {
      path: '/tbm/ps/AddNewAddress',
      title: 'Customer',
      Name_of_Screen: 'EDIT CUSTOMER DETAILS',
    },
    {
      path: '/tbm/ps/AddNewEmail',
      title: 'Customer',
      Name_of_Screen: 'EDIT CUSTOMER DETAILS',
    },

    {
      path: '/tbm/ps/CustomerAddress',
      title: 'Customer',
      Name_of_Screen: 'EDIT CUSTOMER DETAILS',
    },
    {
      path: '/tbm/ps/previewproposal/:id',
      title: 'Proposal',
      Name_of_Screen: 'Preview Proposal',
    },
    {
      path: '/tbm/ps/dbtoolslist',
      title: '',
      Name_of_Screen: 'DB-Tools-List',
    },
    {
      path: '/tbm/ps/salesordermasterrecord',
      title: '',
      Name_of_Screen: 'Sales Order Master Record',
    },
    {
      path: '/tbm/ps/salesorderdetails',
      title: '',
      Name_of_Screen: 'Work Order Details',
    },
  ];

  const filteredPath = [
    {
      path: '/tbm/ps/searchCustomer/Customer',
      title: 'Customer',
      Name_of_Screen: 'Customer Search',
    },

    {
      path: '/tbm/ps/createnewcustomer',
      title: 'Customer',
      Name_of_Screen: 'New Customer',
    },
    {
      path: '/tbm/ps/editcustomerfirstlastname',
      title: 'Customer',
      Name_of_Screen: 'Edit Customer Details',
    },
    {
      path: '/tbm/ps/createnewwindowset',
      title: 'Window Set',
      Name_of_Screen: 'Creating a Window Set',
    },
    {
      path: '/tbm/ps/windowset/:id',
      title: 'Window Set',
      Name_of_Screen: 'Window Set Details',
    },

    {
      path: '/tbm/ps/window',
      title: 'Window',
      Name_of_Screen: 'Window Details',
    },
    {
      path: '/tbm/ps/proposalselectwindow/:id',
      title: 'Proposal',
      Name_of_Screen: 'Creating Proposal',
    },
    {
      path: '/tbm/ps/proposalapplycovering/:id',
      title: 'Proposal',
      Name_of_Screen: 'Selecting Coverings',
    },
    {
      path: '/tbm/ps/editingwindowproposal',
      title: 'Proposal',
      Name_of_Screen: 'Editing Proposal',
    },
    {
      path: '/tbm/ps/batcheditingwindowcovering',
      title: 'Proposal',
      Name_of_Screen: 'Editing Proposal Details',
    },
    {
      path: '/tbm/ps/salesorderaddingwindows',
      title: 'Sales Order',
      Name_of_Screen: 'ADDING WINDOWS',
    },
    {
      path: '/tbm/ps/salesorder',
      title: 'Sales Order',
      Name_of_Screen: 'Finalizing Line Items',
    },

    {
      path: '/tbm/ps/selecteddateappointments',
      title: 'Appointments',
      Name_of_Screen: `${todayDate}`,
    },
    {
      path: '/tbm/ps/editcustomeraddress',
      title: 'Customer',
      Name_of_Screen: 'Edit Customer Details',
    },
    {
      path: '/tbm/ps/editcustomerphonenumber',
      title: 'Customer',
      Name_of_Screen: 'Edit Customer Details',
    },
    {
      path: '/tbm/ps/editcustomeremail',
      title: 'Customer',
      Name_of_Screen: 'Edit Customer Details',
    },
    {
      path: '/tbm/ps/addnewphonenumber',
      title: 'Customer',
      Name_of_Screen: 'Edit Customer Details',
    },
    {
      path: '/tbm/ps/AddNewAddress',
      title: 'Customer',
      Name_of_Screen: 'EDIT CUSTOMER DETAILS',
    },
    {
      path: '/tbm/ps/AddNewEmail',
      title: 'Customer',
      Name_of_Screen: 'EDIT CUSTOMER DETAILS',
    },
  ];

  const NavigateBack = [
    { path: '/', title: 'The BlindMan' },
    { path: '/login', title: 'The BlindMan' },
    { path: '/tbm/ps', title: 'The BlindMan' },
    {
      path: '/pagenotfound',
      title: 'Page Not Found',
      Name_of_Screen: 'Page Not Found',
    },
    {
      path: '*',
      title: 'Page Not Found',
      Name_of_Screen: 'Page Not Found',
    },
    {
      path: '/tbm/ps/addwindow',
      title: 'Window',
      Name_of_Screen: 'Creating a Window',
    },

    {
      path: '/tbm/ps/windowsets',
      title: 'Window Set',
      Name_of_Screen: 'Customer Details',
    },

    {
      path: '/tbm/ps/addwindow',
      title: 'Window',
      Name_of_Screen: 'Customer Details',
    },
    {
      path: '/tbm/ps/addwindowset',
      title: 'Window Set',
      Name_of_Screen: 'Customer Details',
    },
    {
      path: '/tbm/ps/createproposal',
      title: 'Proposal',
      Name_of_Screen: 'Creating Proposal',
    },
    {
      path: '/tbm/ps/proposal/:id/details',
      title: 'Proposal',
      Name_of_Screen: 'Customer Details',
    },
    {
      path: '/tbm/ps/customerdetails',
      title: 'Customer',
      Name_of_Screen: 'Customer Details',
    },

    {
      path: '/tbm/ps/editcustomerphoneemail',
      title: 'Customer',
      Name_of_Screen: 'Edit Customer Details',
    },

    {
      path: '/tbm/ps/editwindowset/:id',
      title: 'Window Set',
      Name_of_Screen: 'Editing Window Set ',
    },
    {
      path: '/tbm/ps/reorderwindowset/:id',
      title: 'Window Set',
      Name_of_Screen: 'Editing Window Set ',
    },
    {
      path: '/tbm/ps/deletewindow/:id',
      title: 'Window Set',
      Name_of_Screen: 'Editing Window Set ',
    },

    {
      path: '/tbm/ps/existingcustomer',
      title: 'Customer',
      Name_of_Screen: 'Top 5 Customers',
    },

    {
      path: '/tbm/ps/duplicateproposaldetails/:id',
      title: 'Proposal',
      Name_of_Screen: 'Duplicate Proposal Details',
    },
    {
      path: '/tbm/ps/editmode/:id',
      title: 'Proposal',
      Name_of_Screen: 'Editing Proposal ',
    },
    {
      path: '/tbm/ps/editingwsproposal/:id',
      title: 'Proposal',
      Name_of_Screen: 'Editing Proposal',
    },
    {
      path: '/tbm/ps/editingswproposal/:id',
      title: 'Proposal',
      Name_of_Screen: 'Editing Proposal',
    },
    {
      path: '/tbm/ps/deletemode/:id',
      title: 'Proposal',
      Name_of_Screen: 'Editing Proposal',
    },
    {
      path: '/tbm/ps/proposal-batchedit/:id',
      title: 'Proposal',
      Name_of_Screen: 'Editing Proposal',
    },
    {
      path: '/tbm/ps/reordermode/:id',
      title: 'Proposal',
      Name_of_Screen: 'Editing Proposal',
    },

    { path: '/dbtools/skulookup', title: 'SKU', Name_of_Screen: 'SKU Lookup' },
    {
      path: '/dbtools/pricinglookup',
      title: 'Pricing',
      Name_of_Screen: 'Pricing Lookup',
    },
    {
      path: '/tbm/ps/home',
      title: 'Home',
      Name_of_Screen: 'Home',
    },
    {
      path: '/tbm/ps/newcustomer',
      title: 'Customer',
      Name_of_Screen: 'New Customers',
    },
    {
      path: '/tbm/ps/proposal_id/:id',
      title: 'Proposal',
      Name_of_Screen: 'Preview Proposal',
    },
    {
      path: '/tbm/ps/systeminformation',
      title: 'System Information',
      Name_of_Screen: 'System Information',
    },

    {
      path: '/tbm/ps/viewallappointments',
      title: 'Appointments',
      Name_of_Screen: 'View All Appointments',
    },
    {
      path: '/tbm/ps/dashboard',
      title: '',
      Name_of_Screen: `Hello ${USER_NAME} !`,
    },

    {
      path: '/tbm/ps/salesorderaddingandconfirmwindows',
      title: 'Sales Order',
      Name_of_Screen: 'ADDING WINDOWS',
    },
    {
      path: '/tbm/ps/editwindowdetailsinsalesorder',
      title: 'Proposal',
      Name_of_Screen: 'PROPOSAL DETAILS',
    },
    {
      path: '/tbm/ps/salesorderconfirm',
      title: 'Sales Order',
      Name_of_Screen: 'CONFIRM DETAILS',
    },
    {
      path: '/tbm/ps/ctspaymentdetails',
      title: 'Sales Order',
      Name_of_Screen: ' DETAILS',
    },
    {
      path: '/tbm/ps/generate-salesorder-pdf',
      title: 'Sales Order',
      Name_of_Screen: 'Preview Sales Order',
    },

    {
      path: '/tbm/ps/CustomerAddress',
      title: 'Customer',
      Name_of_Screen: 'EDIT CUSTOMER DETAILS',
    },
    {
      path: '/tbm/ps/previewproposal/:id',
      title: 'Proposal',
      Name_of_Screen: 'Preview Proposal',
    },
    {
      path: '/tbm/ps/searchCustomer/searchresult',
      title: 'Customer',
      Name_of_Screen: 'Customer Search',
    },
    {
      path: '/tbm/ps/dbtoolslist',
      title: 'Db-tools',
      Name_of_Screen: 'DB TOOLS',
    },
    {
      path: '/tbm/ps/salesordermasterrecord',
      title: '',
      Name_of_Screen: 'Sales Order Master Record',
    },
    {
      path: '/tbm/ps/salesorderdetails',
      title: '',
      Name_of_Screen: 'Sales Order Details',
    },
  ];

  const useCurrentPath = () => {
    const location = useLocation();
    const [{ route }] = matchRoutes(titleMap, location);
    return route.path;
  };

  sessionStorage.setItem('pathname', useCurrentPath());
  const actualPath = useCurrentPath();

  useEffect(() => {
    const curTitle = titleMap.find((item) => item.path === actualPath);

    if (actualPath === '/tbm/ps/home') {
      setShow(false);
      setShowArrow(false);
    } else {
      setShow(true);
      setShowArrow(true);
    }

    if (actualPath === '/tbm/ps/dashboard' || actualPath === '/pagenotfound') {
      setShow(true);
      setShowArrow(false);
    } else {
      setShow(true);
      setShowArrow(true);
    }

    if (curTitle && curTitle.title) {
      setPageTitle(curTitle.title);
      document.title = curTitle.title;
    } else {
      setPageTitle('');
    }
    if (curTitle && curTitle.Name_of_Screen) {
      setScreenName(curTitle.Name_of_Screen);
      document.title = curTitle.Name_of_Screen;
    }
  }, [location]);

  const hours = 24; // to clear the sessionStorage after 1 hour

  const now = new Date().getTime();
  const setupTime = sessionStorage.getItem('setupTime');

  if (!setupTime) {
    sessionStorage.setItem('setupTime', now);
  } else {
    if (now - setupTime > hours * 60 * 60 * 1000) {
      sessionStorage.setItem('setupTime', now);
      sessionStorage.clear();
    }
  }

  // Logout function
  const logout = () => {
    fetch(`${CONFIG.API_URL}/am/Logout`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((data) => data.json())
      .then((result) => {
        sessionStorage.clear(); // Destroy the session
        sessionStorage.clear(); //Destroy local session
        cookies.remove('token'); // Remove token from cookies
        cookies.remove('access_token'); // Remove access_token from cookies
        cookies.remove('refresh_token'); // Remove refresh_token from cookies
        cookies.remove('connect.sid'); // Remove connect.sid from cookies
        window.sessionStorage.clear(); // Clear the local storage
        window.location.href = '/'; // Navigate to Login page
        if (!result.error) {
        }
      });
  };

  //----------------------------------------------------------------------
  const handleClose = () => setDeleteItem(false);
  const handleBackButton = () => {
    if (actualPath === '/tbm/ps/finalizesalesorder') {
      navigate('/tbm/ps/customerdetails', {
        state: {
          Custdata: {
            custid: location.state.customerDetails.customerdata.customer_id,
            Addressid: location.state.customerDetails.customerdata.address_id,
          },
        },
      });
    }
    if (actualPath === '/tbm/ps/proposal/:id/details') {
      navigate('/tbm/ps/customerdetails', {
        state: {
          Custdata: {
            custid: location.state.customerdata.customer_id,
            Addressid: location.state.customerdata.address_id,
          },
        },
      });
    }

    let warnStatus;
    filteredPath.map((element) => {
      if (element.path === actualPath) {
        warnStatus = true;
      }
    });
    NavigateBack.map((element) => {
      if (element.path === actualPath) {
        navigate(-1);
      }
    });
    setDeleteItem(warnStatus);
  };

  const handlenavigateclose = () => {
    setDeleteItem(false);
    navigate(-1);
  };

  return (
    <>
      <Snackbar className='snackbar_bar_postition' open={deleteItem} anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
        <Alert className='Success mainAlert' variant='filled' icon={false}>
          <div className='alertCancel' align='right'>
            <img onClick={handleClose} src={DrawerCancelIcon} alt='DrawerCancelIcon' className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
          </div>

          <div className='alertMainNew'>
            <div align='left' style={{ paddingTop: 37 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'Center',
                }}
              >
                <Box className='alertCantinueButton'>
                  <img src={DrawerAlertIcon} alt='DrawerAlertIcon' className='primary_icons_dimension' />
                </Box>
              </Box>

              <div
                style={{
                  paddingTop: 20,
                  paddingLeft: '26px',
                  fontSize: '20px',
                  fontFamily: 'RobotoRegular',
                }}
              >
                Clicking BACK will lose any unsaved work. Okay to continue?
              </div>
            </div>

            <div
              align='left'
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '20px 10px 0px 24px',
                justifyContent: 'center',
                alignItems: 'start',
                lineHeight: '24px',
              }}
            >
              <Box
                onClick={() => {
                  handlenavigateclose();
                }}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'Center',
                  cursor: 'pointer',
                  marginBottom: '20px',
                }}
              >
                <Box onClick={() => {}} className='alertCantinueButton'>
                  <img src={DrawerConinueIcon} className='primary_icons_dimension' alt='DrawerConinueIcon' />
                </Box>
                <Typography className='globalTextForDrawer' sx={{ marginLeft: '12px' }}>
                  CONTINUE
                </Typography>
              </Box>

              <Box
                onClick={handleClose}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'Center',
                  cursor: 'pointer',
                  paddingBottom: '16px',
                  paddingLeft: '15px',
                }}
              >
                <Box className='globalIconMainBox'>
                  <Box className='iconSecondaryButtonGlobalForDrawer'>
                    <img src={DrawerCancelIcon} alt='DrawerCancelIcon' className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                  </Box>
                  <Typography className='globalTextForDrawer'>CANCEL</Typography>
                </Box>
              </Box>
            </div>
          </div>
        </Alert>
      </Snackbar>

      <Snackbar className='snackbar_bar_postition' anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
        <Alert className='Success1 mainAlert' variant='filled' icon={false} severity='warning'>
          <div className='alertCancel'>
            <img onClick={handleClose} alt='DrawerCancelIcon' src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
          </div>

          <div align='center'>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'Center',
                paddingBottom: '10px',
                color: 'white',
              }}
            >
              <Box className='alertCantinueButton'>
                <img src={DrawerAlertIcon} className='primary_icons_dimension' alt='DrawerAlertIcon' />
              </Box>
            </Box>
            <div align='center' style={{ paddingLeft: '1%', paddingRight: '1%' }}>
              <span style={{ fontSize: '20px' }}> Clicking BACK will lose any unsaved work. Okay to continue?</span>

              <div
                align='left'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Box>
                  <Button className='' sx={{ marginLeft: '15px' }} type='submit' onClick={handlenavigateclose}>
                    <img src={DrawerContinueIcon} className='primary_icons_dimension' alt='DrawerContinueIcon' />
                    &nbsp;
                    <Typography
                      sx={{
                        color: 'white',
                        paddingLeft: '10px',
                        fontWeight: 'bold',
                      }}
                    >
                      CONTINUE
                    </Typography>
                  </Button>
                </Box>
                <Box className='gray_button_span button_top_space' style={{ paddingBottom: '30px', paddingLeft: '38px' }} onClick={handleClose}>
                  <Box className='Save_changes_cancel_button'>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img src={DrawerCancelIcon} alt='DrawerCancelIcon' className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                    </div>
                  </Box>
                  <span
                    style={{
                      color: 'white',
                      paddingLeft: '15px',
                      paddingTop: '3px',
                    }}
                  >
                    CANCEL
                  </span>
                </Box>
              </div>
            </div>
          </div>
        </Alert>
      </Snackbar>

      <Box sx={{ flexGrow: 1 }}>
        <AppBar position='fixed' sx={{ height: '64px !important' }} className='app_Bar_Header_page'>
          <Toolbar>
            <IconButton onClick={() => navigate('/tbm/ps/dashboard')}>
              <img src={Logo} alt='Logo' className='logout_icon_dimension' />
            </IconButton>

            <Typography
              variant='h6'
              component='div'
              align='center'
              sx={{
                flexGrow: 1,
              }}
            >
              <span
                style={{
                  flexGrow: 1,
                  fontSize: '120%',
                  fontWeight: 400,
                  fontFamily: 'RobotoLight',
                  color: 'white !important',
                }}
              >
                {pageTitle}
              </span>{' '}
            </Typography>

            {/* User Icon will open drawer */}
            <Button onClick={() => setIsDrawerOpen(true)} color='inherit'>
              <img src={HeaderUserIon} className='header_user_icon' alt='HeaderUserIon' />
            </Button>

            {/* Drawer */}
            <Drawer
              PaperProps={{
                sx: {
                  width: '90%',
                  background: 'rgba(69, 157, 166, 70%)  !important',
                },
              }}
              className='drawer_Header_page'
              role='presentation'
              classes={{ paper: classes.paper }}
              BackdropProps={{
                classes: {
                  root: classes.backDrop,
                },
              }}
              anchor='right'
              open={isDrawerOpen}
              onClick={() => setIsDrawerOpen(false)}
            >
              <Box p={4} pt={0}>
                <div align='right' className='drawer_backbutton'>
                  <IconButton>
                    <ArrowCircleRightIcon className='Arrow_Circle_Right_Icon_Button' fontSize='large' onClick={() => setIsDrawerOpen(false)} />
                  </IconButton>
                </div>
                <br />
                <Stack direction='column' spacing={1}>
                  <Box sx={{ cursor: 'pointer' }} onClick={() => navigate('/tbm/ps/settings')} className='globalIconMainBox globalMarginLeftAddWindow'>
                    <img className='primary_icons_dimension_1' alt='DBTools' src={SettingsIcon} />
                    <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalText'>
                      Settings
                    </Typography>
                  </Box>
                  {USER_NAME === 'Tiffani Howell' || USER_NAME === 'Josh Dix' || USER_NAME === 'Brian Wolsey' ? (
                    <Box sx={{ cursor: 'pointer' }} onClick={() => navigate('/tbm/ps/dbtoolslist')} className='globalIconMainBox globalMarginLeftAddWindow'>
                      <img className='primary_icons_dimension_1' alt='DBTools' src={DBIcon} />
                      <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalText'>
                        DB Tools
                      </Typography>
                    </Box>
                  ) : null}
                  <Box sx={{ cursor: 'pointer' }} onClick={logout} className='globalIconMainBox globalMarginLeftAddWindow'>
                    <img className='primary_icons_dimension' src={DrawerLogoutIcon} alt='DrawerLogoutIcon' />
                    <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalText'>
                      Sign Out
                    </Typography>
                  </Box>
                </Stack>
                <hr className='button_top_space' />
              </Box>
            </Drawer>
            {/* Drawer Ends */}
          </Toolbar>
        </AppBar>
      </Box>
      {showArrow ? (
        <Container
          disableGutters
          sx={{
            maxWidth: { xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' },
          }}
          className='header_back_button_typography'
        >
          <Stack direction='row' spacing={2} sx={{ margin: 0, alignItems: 'center' }}>
            <Box
              onClick={handleBackButton}
              sx={{
                zIndex: '1000000000 !important',
                marginLeft: '8% !important',
                marginTop: '15px !important',
              }}
            >
              <img src={BackIcon} style={{ cursor: 'pointer' }} className='secondary_icons_dimension' alt='BackIcon' />
            </Box>

            <Typography className='header_page_name_of_screen_typography'>{show ? (actualPath === '/tbm/ps/dbtoolslist' ? screenName : screenName.toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase())) : null}</Typography>
          </Stack>
          <hr className='header_page_hr' />
        </Container>
      ) : (
        <Container
          disableGutters
          sx={{
            maxWidth: { xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' },
          }}
          className='header_back_button_typography'
        >
          <Stack direction='row' spacing={0} sx={{ margin: 0 }}>
            <Typography>
              <div className='back-button'>&nbsp;</div>
              <br />
            </Typography>
            <Typography className='header_page_name_of_screen_typography'>{show ? screenName.toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase()) : null}</Typography>
          </Stack>
          <hr className='heade_page_disable_arrowicon' />
        </Container>
      )}
    </>
  );
}
