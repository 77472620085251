import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Table from '../Shared/Table';
import CONFIG from '../../config/config.js';
import LongText from '../LongText';
import { WindowOutlined } from '@mui/icons-material';

const titleAbbreviations = {
  'ROLLER SHADES': 'RS',
  BLINDS: 'BL',
  SHUTTERS: 'SH',
  'SOFT SHADES': 'SS',
};

const ProductionStatus = ({ title, windows = [] }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(CONFIG.WindowsManagement.RowperPageLimit);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  function formatLabel(key) {
    if (key === 'coveringType') {
      return (
        <>
          <span style={{ paddingRight: '30px' }}>#</span>
          <span>Covering Type</span>
        </>
      );
    }
    if (key === 'name') {
      return (
        <>
          <span style={{ paddingRight: '30px' }}>#</span>
          <span>Name</span>
        </>
      );
    }
    const words = key.replace(/([a-z])([A-Z])/g, '$1 $2').split(/(?=[A-Z])/);
    return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  const columns = [...new Set(windows.flatMap((obj) => Object.keys(obj)))].map((key) => ({
    label: formatLabel(key),
    align: 'left',
    dataKey: key,
  }));

  return (
    <>
      <Typography className='sales_order_Details_Text_Style mt all_pages_h2_header button_bottom_space'>{title}</Typography>
      <Box className='proposal_table_main_box button_top_space'>
        <Box className='proposal_table_sub_box'>
          <Box className='table-box-1'>
            <TableContainer>
              <Table.Head columns={columns} />
              {(rowsPerPage > 0 ? windows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : windows).map((window, index) => (
                <Table.Row key={`${window.invoice}-${window.number}`} columns={columns} window={window} />
              ))}
            </TableContainer>
            <TablePagination className='search_table_dimension_for_pagination' rowsPerPageOptions={[5, 10, 25, { value: -1, label: 'All' }]} component='div' count={windows.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
          </Box>
        </Box>
      </Box>
      <hr className='button_top_space button_bottom_space' />
    </>
  );
};

export default ProductionStatus;
