import { TableHead, TableRow, TableCell } from '@mui/material';

export function Head({ columns }) {
  return (
    <TableHead>
      <TableRow sx={{ margin: 0, fontWeight: 'bold' }}>
        {columns.map((column) =>
          column.dataKey !== 'id' ? (
            <TableCell
              key={column.dataKey}
              className={`${column.dataKey === 'name' || column.dataKey === 'coveringType' ? 'table-headerps sticky-columnws-2' : 'table-headerpd_size'} font_weight_bold`}
              variant='head'
              style={{ width: column.width }}
              sx={{
                textAlign: 'left',
                whiteSpace: 'nowrap',
              }}
            >
              {column.label}
            </TableCell>
          ) : null
        )}
      </TableRow>
    </TableHead>
  );
}

export function Row({ columns, window }) {
  return (
    <TableRow hover role='checkbox' tabIndex={-1} key={window.code}>
      {columns.map((column) =>
        column.dataKey !== 'id' ? (
          <TableCell key={column.id} sx={{ whiteSpace: 'nowrap' }} className={column.dataKey === 'name' || column.dataKey === 'coveringType' ? 'table-contentid sticky-columnws-2' : 'table-content-after-material'} align={'left'}>
            {window[column.dataKey]}
          </TableCell>
        ) : null
      )}
    </TableRow>
  );
}

const Table = {
  Head,
  Row,
};

export default Table;
