import React from 'react';
import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import fracty from 'fracty';

import light from '../../../font/Roboto-Light.woff';
import regular from '../../../font/Roboto-Regular.woff';

Font.register({
  family: 'RobotoLight',
  src: light,
});

Font.register({
  family: 'Roboto',
  src: regular,
});

const styles = StyleSheet.create({
  section_col_key: {
    paddingTop: 5,
    paddingLeft: 10,
    flexDirection: 'col',
  },
  table_section_col_key: {
    maxWidth: 180,
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 5,
    flexDirection: 'col',
  },
  table_section_col1_key: {
    padding: 5,
    flexDirection: 'col',
    flexGrow: 1,
  },
  table_section_col2_key: {
    paddingTop: 5,
    paddingLeft: 5,
    flexDirection: 'col',
    flexGrow: 1,
  },
  table_section_col3_key: {
    width: 90,
    paddingTop: 5,
    flexDirection: 'col',
  },
  section_row: {
    flexDirection: 'row',
    paddingTop: 5,
    paddingBottom: 5,
  },
  tableRowText: {
    paddingLeft: 1,
    fontFamily: 'Roboto',
    fontSize: '10px',
    lineHeight: '1.5px',
    color: '#000',
    textAlign: 'left',
  },
  tableRowTextRight: {
    paddingRight: 10,
    fontFamily: 'Roboto',
    fontSize: '10px',
    lineHeight: '1.5px',
    color: '#000',
    textAlign: 'right',
  },
  headingText: {
    fontFamily: 'RobotoLight',
    fontSize: '10px',
    lineHeight: '1.5px',
    color: '#000',
    textAlign: 'left',
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    flexGrow: 1,
  },
  left: {
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: 495,
  },
  verticalLine: {
    borderLeft: '1px solid #d6d6d6',
    marginLeft: 0,
  },
  verticalLine_1: {
    borderLeft: '1px solid #d6d6d6',
    marginLeft: 10,
  },
});

function simplifyFraction(fraction) {
  const fractionString = fraction.toString();
  const decimalPlaces = fractionString.length - 2;
  const denominator = Math.pow(10, decimalPlaces);
  const numerator = Math.round(fraction * denominator);
  const gcd = greatestCommonDivisor(numerator, denominator);

  return [numerator / gcd, denominator / gcd];
}

function greatestCommonDivisor(a, b) {
  if (b === 0) {
    return a;
  } else {
    return greatestCommonDivisor(b, a % b);
  }
}

const Window = ({ row, window }) => {
  const materialSkuDetails = window.sku_details.filter((sku_detail) => sku_detail.sku_type === 'M')[0];
  const skuDetails = window.sku_details.filter((sku_detail) => sku_detail.sku_type !== 'M');
  const salesOrderSpecificFactors = window.sales_order_specific_factor;

  return (
    <View style={styles.section_row}>
      <View style={styles.section_col_key}>
        <Text style={styles.headingText}>#{row}</Text>
      </View>

      {/* Window Details */}
      <View style={styles.table_section_col_key}>
        <View wrap={true} style={styles.section_table_row}>
          <Text style={styles.headingText}>
            Window Name :{' '}
            <Text wrap={true} style={styles.tableRowText}>
              {window.window_name.length > 15 ? window.window_name.replace(/(.{ 10 })/g, '$1\n') : window.window_name}
            </Text>
          </Text>
        </View>
        <View wrap={true} style={styles.section_table_row}>
          <Text style={styles.headingText}>
            Measurement Type : <Text style={styles.tableRowText}>{window.sales_order_measurement_type}</Text>
          </Text>
        </View>
        <View style={styles.section_table_row}>
          <Text style={styles.headingText}>
            Window Size :{' '}
            <Text wrap={true} style={styles.tableRowText}>
              {fracty(window.sales_order_window_width)}” X {fracty(window.sales_order_window_height)}”
            </Text>
          </Text>
        </View>
        <View style={styles.section_table_row}>
          <Text style={styles.headingText}>
            Existing Covering :{' '}
            <Text wrap={true} style={styles.tableRowText}>
              {window.existing_covering}
            </Text>
          </Text>
        </View>
        <View style={styles.section_table_row}>
          <Text style={styles.headingText}>
            Depth :{' '}
            <Text wrap={true} style={styles.tableRowText}>
              {window.sales_order_measurement_type_specific_factor.WindowDepth}
            </Text>
          </Text>
        </View>
        {Object.keys(window.sales_order_measurement_type_specific_factor).map((key, i) =>
          key !== 'AddNote' && window.sales_order_measurement_type_specific_factor[key] ? (
            <View key={i} style={styles.section_table_row}>
              <Text style={styles.headingText}>
                {key} :{' '}
                <Text wrap={true} style={styles.tableRowText}>
                  {window.sales_order_measurement_type_specific_factor[key]}
                </Text>
              </Text>
            </View>
          ) : null
        )}
      </View>

      <View style={styles.verticalLine} />

      {/* Covering Details */}
      <View style={styles.table_section_col1_key}>
        <View style={styles.section_table_row}>
          <Text style={styles.headingText}>
            Category :{' '}
            <Text wrap={true} style={styles.tableRowText}>
              {materialSkuDetails.covering_category}
            </Text>
          </Text>
        </View>
        <View style={styles.section_table_row}>
          <Text style={styles.headingText}>
            Brand :{' '}
            <Text wrap={true} style={styles.tableRowText}>
              {materialSkuDetails.brand}
            </Text>
          </Text>
        </View>
        <View style={styles.section_table_row}>
          <Text style={styles.headingText}>
            Product :{' '}
            <Text wrap={true} style={styles.tableRowText}>
              {materialSkuDetails.product}
            </Text>
          </Text>
        </View>
        <View style={styles.section_table_row}>
          <Text style={styles.headingText}>
            Label :{' '}
            <Text wrap={true} style={styles.tableRowText}>
              {materialSkuDetails.label}
            </Text>
          </Text>
        </View>
        <View style={styles.section_table_row}>
          <Text style={styles.headingText}>
            Type :{' '}
            <Text wrap={true} style={styles.tableRowText}>
              {materialSkuDetails.type}
            </Text>
          </Text>
        </View>
      </View>

      {/* <View style={styles.verticalLine} /> */}

      <View style={styles.table_section_col2_key}>
        <View style={styles.section_table_row}>
          <Text style={styles.headingText}>
            Material :{' '}
            <Text wrap={true} style={styles.tableRowText}>
              {materialSkuDetails.fabric}
            </Text>
          </Text>
        </View>
        <View style={styles.section_table_row}>
          <Text style={styles.headingText}>
            {salesOrderSpecificFactors['Material Color'].key} :{' '}
            <Text wrap={true} style={styles.tableRowText}>
              {salesOrderSpecificFactors['Material Color'].name}
            </Text>
          </Text>
        </View>
        <View style={styles.section_table_row}>
          <Text style={styles.headingText}>
            Opacity :{' '}
            <Text wrap={true} style={styles.tableRowText}>
              {materialSkuDetails.opacity}
            </Text>
          </Text>
        </View>

        {skuDetails.map((sku_detail, i) => (
          <View key={i} style={styles.section_table_row}>
            <Text style={styles.headingText}>
              {sku_detail.value_2} :{' '}
              <Text wrap={true} style={styles.tableRowText}>
                {sku_detail.value_1}
              </Text>
            </Text>
          </View>
        ))}
      </View>

      <View style={styles.verticalLine} />

      {/* Charges */}
      <View style={styles.table_section_col3_key}>
        <View style={styles.section_table_row}>
          <Text style={styles.tableRowTextRight}>${parseFloat(window.retail_price).toFixed(2)}</Text>
        </View>
      </View>
    </View>
  );
};

export default Window;
